import {
  SAVE_MIA_BESTFITS_LIST,
  SAVE_MIA_BESTFIT_BY_ID,
} from "../types/action-type";

const initialState = {
  list: [],
  bestfitData: {},
};

export default function miaBestfitsReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_MIA_BESTFITS_LIST:
      return {
        ...state,
        list: action.data,
      };
    case SAVE_MIA_BESTFIT_BY_ID:
      return {
        ...state,
        bestfitData: action.data,
      };
    default:
      return state;
  }
}
