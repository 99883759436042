import {
  GET_USER_REQUEST,
  SAVE_LOGIN_USER_DATA,
  LOGOUT_REQUEST,
  LOGOUT_REQUEST_SUCCESS,
  GET_VALIDATE_USER,
  CREATE_ADMIN_USER_REQUEST,
  GET_ADMIN_LIST,
  SAVE_ADMIN_LIST,
  GET_ADMIN_USER_BY_ID,
  SAVE_ADMIN_USER_BY_ID,
  CLEAR_ADMIN_DATA_REQUEST,
  CLEAR_ADMIN_DATA_SUCCESS,
  UPDATE_ADMIN_USER,
  GET_ADMIN_USER_FORGOT_PASSWORD_REQUEST,
  GET_ADMIN_USER_RESET_PASSWORD_REQUEST,
  GET_APP_USER_RESET_PASSWORD_REQUEST,
} from "../types/action-type";

export const loginUser = (credential, navigate) => ({
  type: GET_USER_REQUEST,
  credential,
  navigate,
});

export const saveLoginUserData = (data) => ({
  type: SAVE_LOGIN_USER_DATA,
  data,
});

export const logout = () => ({
  type: LOGOUT_REQUEST,
});

export const successLogout = () => ({
  type: LOGOUT_REQUEST_SUCCESS,
});

export const getUser = () => ({
  type: GET_VALIDATE_USER,
});

export const createAdmin = (data, navigate) => ({
  type: CREATE_ADMIN_USER_REQUEST,
  data,
  navigate,
});

export const getAdminList = (pageIndex, pageSize, searchKey) => ({
  type: GET_ADMIN_LIST,
  pageIndex, pageSize, searchKey
});

export const saveAdminList = (data) => ({
  type: SAVE_ADMIN_LIST,
  data,
});

export const getAdminUserById = (id) => ({
  type: GET_ADMIN_USER_BY_ID,
  id,
});

export const saveAdminUserById = (data) => ({
  type: SAVE_ADMIN_USER_BY_ID,
  data,
});

export const clearAdminData = () => ({
  type: CLEAR_ADMIN_DATA_REQUEST,
});

export const clearAdminDataSuccess = () => ({
  type: CLEAR_ADMIN_DATA_SUCCESS,
});

export const updateAdminUser = (data, navigate) => ({
  type: UPDATE_ADMIN_USER,
  data,
  navigate,
});
export const forgotPasswordAdmin = (data, navigate) => ({
  type:GET_ADMIN_USER_FORGOT_PASSWORD_REQUEST,
  data,
  navigate,
});
export const resetPasswordAdmin = (data, navigate) => ({
  type:GET_ADMIN_USER_RESET_PASSWORD_REQUEST,
  data,
  navigate,
});
export const resetPasswordApp = (data, navigate) => ({
  type:GET_APP_USER_RESET_PASSWORD_REQUEST,
  data,
  navigate,
});