import { call, put, takeLatest } from "redux-saga/effects";
import {
  addMiaInterestSuccess,
  deleteMiaInterestSuccess,
  saveMiaInterestById,
  saveMiaInterestList,
  updateStatusSuccess,
  updateInterestReqSuccess
} from "../actions/miaInterest";
import mias from "../api";
import {
  GET_ADD_MIA_INTEREST_REQUEST,
  GET_MIA_INTEREST_BY_ID,
  GET_MIA_INTEREST_DELETE_REQUEST,
  GET_MIA_INTEREST_LIST,
  GET_MIA_INTEREST_STATUS_UPDATE_REQUEST,
  SAVE_ADD_MIA_INTEREST_RESPONSE,
  SAVE_MIA_INTEREST_BY_ID,
  SAVE_MIA_INTEREST_DELETE_SUCCESS,
  SAVE_MIA_INTEREST_LIST,
  SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE,
  GET_MIA_INTEREST_UPDATE_REQUEST,
  SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE_REQUEST
} from "../types/action-type";
import { get as _get } from "lodash";
import toast from "react-hot-toast";

function* getMiaInterestList() {
  try {
    const response = yield call(mias.apis.miaInterest.getMiaInterestList);
    yield put(saveMiaInterestList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("getMiaInterestList error saga ::", err);
  }
}

function* updateMiaInterestStatus(data) {
  try {
    const apiResp = yield call(mias.apis.miaInterest.updateMiaInterestStatus, data.data);
    yield put(updateStatusSuccess());
    const response = yield call(mias.apis.miaInterest.getMiaInterestList);
    yield put(saveMiaInterestList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(_get(error, "response.data.message", _get(error, "message", "")));
    console.log("updateMiaInterestStatus saga error ::", error);
  }
}

function* addMiaInterest(data){
  try{
    const apiResp = yield call(mias.apis.miaInterest.addMiaInterest, data.data)
    yield put(addMiaInterestSuccess())
    const response = yield call(mias.apis.miaInterest.getMiaInterestList)
    yield put(saveMiaInterestList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(_get(error, "response.data.message", _get(error, "message", "")));
    console.log("addMiaInterest saga error ::", error);
  }
}

function* updateMiaInterest(data){
  try{
    const apiResp = yield call(mias.apis.miaInterest.updateMiaInterest, data.data)
    yield put(updateInterestReqSuccess())
    const response = yield call(mias.apis.miaInterest.getMiaInterestList)
    yield put(saveMiaInterestList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(_get(error, "response.data.message", _get(error, "message", "")));
    console.log("updateMiaInterest saga error ::", error);
  }
}

function* deleteMiaInterest(data){
  try{
    const apiResp = yield call(mias.apis.miaInterest.deleteMiaInterest, data.data)
    yield put(deleteMiaInterestSuccess())
    const response = yield call(mias.apis.miaInterest.getMiaInterestList);
    yield put(saveMiaInterestList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("deleteMiaInterest saga error ::", error)
  }
}

function* getMiaInterestById({id}) {
  try {
    let response = yield call(mias.apis.miaInterest.getMiaInterestById, id);
    yield put(saveMiaInterestById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getMiaInterestById saga error ::", error);
  }
}

export default function* miaInterest() {
  yield takeLatest(GET_MIA_INTEREST_LIST, getMiaInterestList);
  yield takeLatest(SAVE_MIA_INTEREST_LIST, saveMiaInterestList);
  yield takeLatest(GET_MIA_INTEREST_STATUS_UPDATE_REQUEST, updateMiaInterestStatus);
  yield takeLatest(SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE, updateStatusSuccess);
  yield takeLatest(GET_ADD_MIA_INTEREST_REQUEST, addMiaInterest);
  yield takeLatest(SAVE_ADD_MIA_INTEREST_RESPONSE, addMiaInterestSuccess);
  yield takeLatest(GET_MIA_INTEREST_DELETE_REQUEST, deleteMiaInterest);
  yield takeLatest(SAVE_MIA_INTEREST_DELETE_SUCCESS, deleteMiaInterestSuccess);
  yield takeLatest(GET_MIA_INTEREST_BY_ID, getMiaInterestById);
  yield takeLatest(SAVE_MIA_INTEREST_BY_ID, saveMiaInterestById);
  yield takeLatest(GET_MIA_INTEREST_UPDATE_REQUEST, updateMiaInterest);
  yield takeLatest(SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE_REQUEST, updateInterestReqSuccess);
}