// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable/serverTable";

// Data
import placesTableData from "./data/placesTableData";
import { useSelector,useDispatch } from "react-redux";
import { get as _get } from "lodash";
import { useEffect, useState } from "react";
import {getPlaceCategoryListForDropdown,getPlacesTableList} from "../../actions/places";
import { Autocomplete, Chip, TextField } from "@mui/material";
const PlacesList = () => {
  const storeProps=useSelector((state)=>{
    return{
      placeCategoryListForDropdown:_get(state,"places.placeCategoryListForDropdown",[]),
    }
  })
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(getPlaceCategoryListForDropdown())
  },[])
  const { columns, rows, totalRecords, pageIndex, pageSize } = placesTableData();
  const [categoryCode, setCategoryCode] = useState("")
  const handleChange = (event,values) => {
    const data=values.map((item)=>item._id);
    const primaryCode=data.toString();
    setCategoryCode(primaryCode);
    const searchKey="";
    dispatch(getPlacesTableList(pageIndex,pageSize,searchKey,primaryCode));
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                 mx={0}
                mt={0}
                py={2}
                 px={2}
                variant="contained"
                bgColor="transperent"
                 borderRadius="lg"
                 // coloredShadow="info"
                 display="flex"
                 justifyContent="space-between"
                 alignItems="center"
                 zIndex="1"
                width="calc(100% - 260px)"
              >
                <MDTypography variant="h5" color="black" >
                  Places List
                </MDTypography>
          {/*       <MDBox sx={{mt:-0.25, paddingRight:'17.25rem'}}> */}
                <Autocomplete
                  multiple
                  name="categoryCode"
                  options={storeProps?.placeCategoryListForDropdown}
                  sx={{ width: 200 }}
                  key={storeProps?.placeCategoryListForDropdown._id}
                  getOptionLabel={(option) => option.label}
                  onChange={handleChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return(
                        <Chip variant="outlined" name="categoryCode" key={index}  value={option._id} label={option.label} {...getTagProps({ index })} />
                      )
                    })
                  } 
                  renderInput={(params) => <TextField name="categoryCode" {...params}   id={params._id} value={params._id} label="Filter By Category" />}
                />
{/*                 </MDBox> */}
              </MDBox>
              <MDBox pt={3} mt={-12.5} display = "flex" alignItem="center" >
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch
                  categoryCode={categoryCode}
                  totalRecords={totalRecords}
                  pageIndex1={pageIndex}
                  pageSize1={pageSize}
                  tableFor="placesList"
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default PlacesList;
