import { call, put, takeLatest } from "redux-saga/effects";
import mias from "../api";
import { get as _get } from "lodash";
import { GET_ROLES_LIST, SAVE_ROLES_LIST } from "../types/action-type";
import { saveRolesList } from "../actions/roles";

function* getRolesList() {
  try {
    const response = yield call(mias.apis.roles.getRolesList);
    yield put(saveRolesList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get Roles list saga error ::", err);
  }
}

export default function* roles() {
  yield takeLatest(GET_ROLES_LIST, getRolesList);
  yield takeLatest(SAVE_ROLES_LIST, saveRolesList);
}
