
// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "../../../context";
import { padding } from "@mui/system";

function DataTableHeadCell({ width, children, sorted, align, disableSortBy, isSortedDesc, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <MDBox
      component="th"
      width={width}
      py={1}
      px={2}
      sx={({ palette: { grey }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${grey[300]}`,
        backgroundColor: grey[300],
        variant: "contained"
      })}
    >
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        color={darkMode ? "white" : "black"}
        opacity={0.6}
        sx={({ typography: { size, fontWeightMedium }, palette: { grey, orange } }) => ({
          fontSize: "12px",
          fontWeight: fontWeightMedium,
          textTransform: "capitalize",
          cursor: sorted && !disableSortBy && "pointer",
          userSelect: sorted && !disableSortBy && "none",
          display: "flex",
          alignItems: "center"
        })}
      >
        {children}
        {
          sorted && !disableSortBy &&
            <MDBox 
              style={{
                display: 'inline-flex',
                flexDirection: 'column',
                color: "#f05a22",
              }}
            >
              <Icon fontSize="medium"
                style={{
                  width: "30px",
                  height: "16px",
                  width: "30px",
                  height: "22px",
                  marginTop:"-4px"
                }}
              >arrow_drop_down</Icon>
            </MDBox>
        }
        
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
