import {
  GET_USER_REQUEST,
  SAVE_LOGIN_USER_DATA,
  LOGOUT_REQUEST,
  LOGOUT_REQUEST_SUCCESS,
  GET_VALIDATE_USER,
  CREATE_ADMIN_USER_REQUEST,
  SAVE_ADMIN_LIST,
  GET_ADMIN_LIST,
  SAVE_ADMIN_USER_BY_ID,
  GET_ADMIN_USER_BY_ID,
  CLEAR_ADMIN_DATA_REQUEST,
  CLEAR_ADMIN_DATA_SUCCESS,
  UPDATE_ADMIN_USER,
  GET_ADMIN_USER_FORGOT_PASSWORD_REQUEST,
  GET_ADMIN_USER_RESET_PASSWORD_REQUEST,
  GET_APP_USER_RESET_PASSWORD_REQUEST
} from "../types/action-type";

import _get from "lodash/get";
import { clearAdminDataSuccess, saveAdminList, saveAdminUserById, saveLoginUserData, successLogout } from "../actions/adminUser";
import { takeLatest, call, put } from "redux-saga/effects";
import mias from "../api/index";
import toast from "react-hot-toast";

function* loginUser(loginUserAction) {
  try {
    const credential = _get(loginUserAction, "credential", {});
    const response = yield call(mias.apis.auth.login, credential);
    localStorage.setItem("token", _get(response, "data.data.token"));
    loginUserAction.navigate("/dashboard");
    toast.success(`Welcome ${_get(response, "data.data.firstName")}!`)
    yield put(saveLoginUserData(_get(response, "data.data", {})));
  } catch (error) {
    console.error("error in login user ::", error);
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
  }
}

function* getUser() {
  try {
    const response = yield call(mias.apis.auth.getUser);
    yield put(saveLoginUserData(_get(response, "data.getUser", {})));
  } catch (error) {
    console.log("error in getUser", error);
  }
}

function* logoutUser() {
  try {
    yield localStorage.removeItem("token");
    yield put(successLogout());
    toast.success("Bye!")
  } catch (err) {
    console.log("error in logout action::", err);
    toast.error(_get(err,"response.data.message",_get(err,"message","")))
  }
}
function * forgotPasswordAdmin(data){
  try{
    const dataResponse= _get(data, "data", {});
    const response = yield call(mias.apis.auth.forgotPassword, dataResponse);
    data.navigate("/admin/signin");
    toast.success(_get(response, "data.message"),5000)
    console.log("response in forgotPasswordAdmin", response);
  }catch(err){
    toast.error(_get(err,"response.data.message",_get(err,"message","")))
    console.log("error in forgotPasswordAdmin", err);
  }
}
function* resetPasswordAdmin(data){
  try{
    const dataResponse= _get(data, "data", {});
    yield call(mias.apis.auth.resetPassword, dataResponse);
    data.navigate("/admin/signin");
    toast.success('Your password has been successfully reset.')
  }catch(err){
    toast.error(_get(err,"response.data.message",_get(err,"message","")))
  }
}
function* resetPasswordApp(data){
  try{
    const dataResponse= _get(data, "data", {});
    yield call(mias.apis.auth.resetPasswordApp, dataResponse);
    toast.success('Your password has been successfully reset.',{duration:60000})
  }catch(err){
    toast.dismiss()
    toast.error(_get(err,"response.data.message",_get(err,"message","")),{duration:30000})
  }
}


function* createAdmin(data) {
  try {
    const createUserData = _get(data, "data", {});
    const apiResp = yield call(mias.apis.admin.createAdmin, createUserData);
    data.navigate("/admins");
    toast.success(_get(apiResp, "data.message"))
  } catch (err) {
    toast.error(_get(err,"response.data.message",_get(err,"message","")))
  }
}

function* getAdminList({pageIndex, pageSize, searchKey}) {
  try {
    const response = yield call(mias.apis.admin.getAdminList, pageIndex, pageSize, searchKey);
    response.data.data.pageIndex = pageIndex;
    response.data.data.pageSize = pageSize;
    yield put(saveAdminList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get admin list saga error ::", err);
  }
}

function* getAdminUserById({ id }) {
  try {
    const response = yield call(mias.apis.admin.getAdminUserById, id);
    const roleData = yield call(mias.apis.roles.getRoleById, _get(response, "data.data.role_id"));
    response.data.data.roleById = _get(roleData, "data.data");
    yield put(saveAdminUserById(_get(response, "data.data", [])));
  } catch (err) {
    console.log("getAdminUserById saga error ::", err);
  }
}

function* clearAdminData() {
  try {
    yield put(clearAdminDataSuccess());
  } catch (error) {
    console.log("clearAdminData error :", error);
  }
}

function* updateAdminUser(data) {
  try {
    const response = yield call(mias.apis.admin.updateAdminUser, data.data);
    data.navigate("/admins");
    toast.success(_get(response, "data.message"));
  } catch (err) {
    console.log("updateAdminUser saga error ::", err);
    toast.error(_get(err,"response.data.message",_get(err,"message","")))
  }
}

export default function* adminUserSaga() {
  yield takeLatest(GET_USER_REQUEST, loginUser);
  yield takeLatest(SAVE_LOGIN_USER_DATA, saveLoginUserData);
  yield takeLatest(LOGOUT_REQUEST, logoutUser);
  yield takeLatest(LOGOUT_REQUEST_SUCCESS, successLogout);
  yield takeLatest(GET_VALIDATE_USER, getUser);
  yield takeLatest(CREATE_ADMIN_USER_REQUEST, createAdmin);
  yield takeLatest(GET_ADMIN_LIST, getAdminList);
  yield takeLatest(SAVE_ADMIN_LIST, saveAdminList);
  yield takeLatest(GET_ADMIN_USER_BY_ID, getAdminUserById);
  yield takeLatest(SAVE_ADMIN_USER_BY_ID, saveAdminUserById);
  yield takeLatest(CLEAR_ADMIN_DATA_REQUEST, clearAdminData);
  yield takeLatest(CLEAR_ADMIN_DATA_SUCCESS, clearAdminDataSuccess);
  yield takeLatest(UPDATE_ADMIN_USER, updateAdminUser);
  yield takeLatest(GET_ADMIN_USER_FORGOT_PASSWORD_REQUEST, forgotPasswordAdmin);
  yield takeLatest(GET_ADMIN_USER_RESET_PASSWORD_REQUEST, resetPasswordAdmin);
  yield takeLatest(GET_APP_USER_RESET_PASSWORD_REQUEST, resetPasswordApp);
}
