import React from 'react'
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable/serverTable";
// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Data from './data/deletedUserTableData';
const DeleteUserList = () => {
    const { columns, rows, totalRecords, pageIndex, pageSize } = Data();
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={0} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={0}
                                mt={0}
                                mb={-8}
                                py={2}
                                px={2}
                                variant="contained"
                                bgColor='rgba(0, 0, 0, 0.0)'
                                borderRadius="lg"
                                zIndex="1"
                                width="calc(100% - 250px)"
                            // coloredShadow="info"
                            >
                                <MDTypography variant="h5" color="black" pt={1.1}>
                                    Deleted Users List
                                </MDTypography>

                            </MDBox>
                            <MDBox pt={0}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    // entriesPerPage={true}
                                    showTotalEntries={true}
                                    noEndBorder
                                    canSearch={true}
                                    totalRecords={totalRecords}
                                    pageIndex1={pageIndex}
                                    pageSize1={pageSize}
                                    tableFor="deletedUserList"
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default DeleteUserList