import {SAVE_DELETED_USERS_LIST} from '../types/action-type';
import { reverse as _reverse } from "lodash";
const initialState = {
    loading: false,
    error: null,
    deletedUserData: {},
};
export default function deletedUserReducer(state = initialState, action) {

    switch (action.type) {
        case SAVE_DELETED_USERS_LIST:
            return {
                ...state,
                loading: true,
                deletedUserData: _reverse(action.data),
            };
        default:
            return state;
    }
}