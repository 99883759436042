import {
  GET_MIA_INTEREST_LIST,
  SAVE_MIA_INTEREST_LIST,
  GET_MIA_INTEREST_STATUS_UPDATE_REQUEST,
  SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE,
  GET_ADD_MIA_INTEREST_REQUEST,
  SAVE_ADD_MIA_INTEREST_RESPONSE,
  GET_MIA_INTEREST_DELETE_REQUEST,
  SAVE_MIA_INTEREST_DELETE_SUCCESS,
  GET_MIA_INTEREST_BY_ID,
  SAVE_MIA_INTEREST_BY_ID,
  GET_MIA_INTEREST_UPDATE_REQUEST,
  SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE_REQUEST
} from "../types/action-type";

export const getMiaInterestList = () => ({
  type: GET_MIA_INTEREST_LIST,
});

export const saveMiaInterestList = (data) => ({
  type: SAVE_MIA_INTEREST_LIST,
  data,
});

export const updateStatusReq = (data) => ({
  type: GET_MIA_INTEREST_STATUS_UPDATE_REQUEST,
  data,
});

export const updateInterestReq = (data) => ({
  type: GET_MIA_INTEREST_UPDATE_REQUEST,
  data,
});
export const updateStatusSuccess = () => ({
  type: SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE,
});
export const updateInterestReqSuccess = () => ({
  type: SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE_REQUEST,
});
export const addMiaInterestReq = (data) => ({
  type: GET_ADD_MIA_INTEREST_REQUEST,
  data,
});

export const addMiaInterestSuccess = () => ({
  type: SAVE_ADD_MIA_INTEREST_RESPONSE,
});

export const deleteMiaInterestReq = (data) => ({
  type: GET_MIA_INTEREST_DELETE_REQUEST,
  data,
});

export const deleteMiaInterestSuccess = () => ({
  type: SAVE_MIA_INTEREST_DELETE_SUCCESS,
});

export const getMiaInterestById = (id) => ({
  type: GET_MIA_INTEREST_BY_ID,
  id,
});

export const saveMiaInterestById = (data) => ({
  type: SAVE_MIA_INTEREST_BY_ID,
  data,
});
