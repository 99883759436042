
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";


// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import colors from "../../../assets/theme/base/colors";
const { white, gradients, grey, transparent } = colors;
function ComplexStatisticsCard({ color, title, count, percentage, icon }) {
  console.log("check props ::", color)
  return (
    <Card>
      <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={3} py={3} >
        <MDBox
          variant="contained"
          bgColor="#fde4db"
          color={color === "light" ? "dark" : "white"}
          // color='dark'
          coloredShadow={color}
          borderRadius="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          
        >
          <Icon fontSize="large" style={{color:"#F05A22"}}>
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25} pl={2} width="calc(100% - 4rem)">
          <MDTypography variant="h5" fontWeight="medium" color="black" textAlign="left">
            {title}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "purple",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
