import { useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../../layouts/authentication/components/BasicLayout";

// Images
import bgImage from "../../../assets/images/bg-image.jpg";
import { loginUser } from "../../../actions/adminUser";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { renderTextField } from "../../../inputFields/formFields";


const LoginValidateSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(6, "Min 6 Characters Required!")
    .required("Required"),
});

const SignIn = () => {
  // const [rememberMe, setRememberMe] = useState(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const login = async (value) => {
    dispatch(loginUser(value, navigate));
  };

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{boxShadow:"none !important"}}>
        
          <MDTypography variant="h4" fontWeight="semibold" color="black" textAlign="center" p={3} >
            Sign in
          </MDTypography>
        

        <Formik
          initialValues={initialValues}
          validationSchema={LoginValidateSchema}
          onSubmit={async (values) => login(values)}
        >
          {({ errors, handleSubmit, isSubmitting, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <MDBox px={3} pb={3}>
                <Field
                  name="email"
                  component={renderTextField}
                  fullWidth
                  autoFocus
                  label="Email"
                />
                <Field
                  name="password"
                  component={renderTextField}
                  fullWidth
                  label="Password"
                  type="password"
                />
                <MDBox mt={4}>
                  <MDButton
                    variant="contained"
                    color="btnTheme"
                    fullWidth
                    disabled={isSubmitting}
                    type="submit"
                    sx={{fontSize:"16px", letterSpacing: "0.3px", fontWeight: "600"}}
                  >
                    Sign in
                  </MDButton>
                </MDBox>
             <MDBox mt={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    <MDTypography
                      component={Link}
                      to="/admin/forgotpassword"
                      variant="button"
                      color="btnTheme"
                      fontWeight="medium"
                      textGradient
                    >
                      Forgot Password?
                    </MDTypography>
                  </MDTypography>
                </MDBox>  
              </MDBox>
            </Form>
          )}
        </Formik>
      </Card>
    </BasicLayout>
  );
};

export default SignIn;
