import { SAVE_COUNTS_FOR_DASHBOARD } from "../types/action-type";

const initialState = {
  counts: {},
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_COUNTS_FOR_DASHBOARD:
      return {
        ...state,
        counts: action.data,
      };
    default:
      return state;
  }
}