import React from 'react';
import MDTypography from "../../../components/MDTypography";
import { get as _get } from "lodash";
import { useSelector } from "react-redux";

export default function Data() {
    const storeProps = useSelector((state) => {
        return {
            data: _get(state, "deletedUser.deletedUserData.records", []),
            totalRecords: _get(state, "deletedUser.deletedUserData.totalRecord", 0),
            pageIndex: _get(state, "deletedUser.deletedUserData.pageIndex", 0),
            pageSize: _get(state, "deletedUser.deletedUserData.pageSize", 30),
        };
    });

        return {
            columns: [
                {
                    Header: "email",
                    accessor: "email",
                    Cell: (row) => (
                        <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
                            {_get(row, "row.original.email")}
                        </MDTypography>
                    ),
                    align: "left",
                    width:"30%"
                },
                {
                    Header: "reasoneToDelete",
                    accessor: "reasoneToDelete",
                    Cell: (row) => (
                        <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
                            {_get(row, "row.original.reasoneToDelete")}
                        </MDTypography>
                    ),
                    align: "left",
                },
            ],
            rows: _get(storeProps, "data", []),
            totalRecords: _get(storeProps, "totalRecords", 0),
            pageIndex: _get(storeProps, "pageIndex", 0),
            pageSize: _get(storeProps, "pageSize", 30),
        };

}