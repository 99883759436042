import { all } from "redux-saga/effects";

import adminUser from "../saga/adminUser";
import cmsList from "../saga/cmsList";
import country from "../saga/country";
import dashboard from "../saga/dashboard";
import hoods from "../saga/hoods";
import language from "../saga/language";
import miaBestfits from "../saga/miaBestfits";
import miaInterest from "../saga/miaInterest";
import roles from "../saga/roles";
import user from "../saga/user";
import places from "../saga/places";
import labelLanguage from "../saga/labelLanguage";
import deletedUsers from "../saga/deletedUser";
import posts from "../saga/posts";

export default function* rootSaga() {
  yield all([
    adminUser(),
    user(),
    cmsList(),
    language(),
    country(),
    hoods(),
    places(),
    labelLanguage(),
    roles(),
    dashboard(),
    miaInterest(),
    miaBestfits(),
    deletedUsers(),
    posts()
  ]);
}
