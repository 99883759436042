import {
  CLEAR_COUNTRY_SUCCESS,
  SAVE_COUNTRY_BY_ID,
  SAVE_COUNTRY_LIST,
} from "../types/action-type";

const initialState = {
  list: [],
  loading: false,
  error: null,
  countryData: {},
};

export default function countryReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_COUNTRY_LIST:
      return {
        ...state,
        loading: true,
        list: action.data,
      };
    case SAVE_COUNTRY_BY_ID:
      return {
        ...state,
        loading: true,
        countryData: action.data,
        label: action.data.label,
      };
    case CLEAR_COUNTRY_SUCCESS:
      return {
        ...state,
        countryData: {},
      };

    default:
      return state;
  }
}
