import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";

import {  Avatar, Menu, MenuItem, Select} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {
  get as _get,
  map as _map,
  omit as _omit,
} from "lodash";
import { clearHoodData, getHoodById, updateHoodDetails,getHoodCategoryList, getHoodCategoryListForDropdown } from "../../actions/hoods";
import { Field, Form, Formik } from "formik";
import {
  renderTextField,
  renderCheckbox,
  renderSelectField
} from "../../inputFields/formFields";

const Hood = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const storeProps = useSelector((state) => {
    return {
      data: _get(state,'hoods.hoodData',{}),
      categoryData: _get(state,'hoods.hoodCategoryListForDropdown.records',[])
    }
  });

  useEffect(() => {
    if(params.id) {
      dispatch(getHoodById(params.id))
      dispatch(getHoodCategoryListForDropdown())
    }
    return () => {
      dispatch(clearHoodData());
    };
  }, [dispatch]);

  const initialValues = storeProps.data
  const categoryList = storeProps.categoryData.map((item)=>{
   return (
    item._id
   ) 
  }
  );
  const [overViewImages,setOverViewImages] = useState()
  const [imageUpdate, setImageUpdate] = useState([]);
  const handleInputChange = (e) => {
    setOverViewImages(URL.createObjectURL(e.target.files[0]));
    setImageUpdate(e.target.files[0])
  }
  const updateDetails = (values) => {

    const formData = new FormData();
    formData.append("images", imageUpdate);
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("categoryCode", values.categoryCode);
    formData.append("features", values.features);
    formData.append("isActive", values.isActive);
    formData.append("isPrivate", values.isPrivate);
    formData.append("id", params.id);
    console.log("formData", formData)
    dispatch(updateHoodDetails(formData, navigate));
  };
 // const categoryListlabel=storeProps.categoryData.map((item)=>item.label.map((item)=>item.name)).toString()
 // const data=[...new Set(categoryList.split(",")),...storeProps.categoryData.map((item)=>item._id)]
const data=storeProps.categoryData

  return (
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox pt={0} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={0}
              mt={0}
              py={3}
              px={2}
              mb={0}
              variant="contained"
              bgColor='rgba(0, 0, 0, 0.0)'
              borderRadius="lg"
              // coloredShadow="info"
            >
              <MDTypography variant="h5" color="black">
                Edit Hood Details
              </MDTypography>
            </MDBox>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={async (values) =>updateDetails(values)}
              >
              {({
                handleSubmit,
              }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <MDBox px={2}>
                      <MDBox width="100%" textAlign="center">
                        {overViewImages!==undefined?
                          <Avatar
                            alt="images"
                            name="images"
                            variant="rounded"
                            src={overViewImages}
                            sx={{ height: "200px", width: "200px", maxWidth: "100%", m: "auto", objectFit: "cover", borderRadius: "6px", marginTop: "7px" }}
                          />
                        : <Avatar
                            alt="images"
                            name="images"
                            variant="rounded"
                            src={initialValues.images !== "" ? initialValues.images : "http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"}
                            sx={{ height: "200px", width: "200px", maxWidth: "100%", m: "auto", objectFit: "cover", borderRadius: "6px", marginTop: "7px" }}
                          />}
                        <input type="file" onChange={handleInputChange} accept="image/*" />
                        </MDBox>
                        <MDBox width="100%" mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="name"
                                component={renderTextField}
                                label="Hood Name"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="description"
                                component={renderTextField}
                                label="Hood Description"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                           <InputLabel id="label">Category</InputLabel>
                               <Field
                                name="categoryCode"
                                label="Category"
                                component={renderSelectField}
                                handleChange={(value) => console.log('got the values::', value)}
                                menuItemArray={data}
                                sx={{width:'100%'}}
                              />   
                      {/*            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    //id={initialValues.categoryCode}
                                    value={initialValues?.categoryCode}
                                    label="Category"
                                    fullWidth
                                    onChange={handleChange}
                                  >{console.warn("initialValues.categoryCode",initialValues.categoryCode)}
                                    {data.map((item,idx)=>(
                                      <MenuItem value={item._id} key={idx}>{item.unwindLabel}</MenuItem>
                                    ))}
                                  </Select>  */} 
                              </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                                {/* <MDBox display="flex" alignItems="center"> */}
                                <Field
                                  name="totalPost"
                                  component={renderTextField}
                                  label="Number of Hood Posts"
                                  type="number"
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                   }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                                <Field
                                  name="totalUser"
                                  component={renderTextField}
                                  label="Number of Hood Memebers"
                                  type="number"
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                   }}
                                />
                                {/* </MDBox> */}
                            </Grid> 
                            <Grid item xs={12} sm={6} xl={3}>
                              <Field
                                name="adminCount"
                                component={renderTextField}
                                label="Number of Hood Admins"
                                type="number"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                              <Field
                                name="blockCount"
                                component={renderTextField}
                                label="Number of Hood Blocks"
                                type="number"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                              {/* <MDBox display='flex' alignItems='left'> */}
                                <MDTypography color="black" variant="text" fontSize="16px">
                                  Features Hood: 
                                  <Field
                                    name="features"
                                    component={renderCheckbox}
                                    //sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                                    size="medium"
                                    color="orange"
                                    fullWidth
                                    />
                                    </MDTypography>
                            {/* </MDBox> */}
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>{/* 
                            <MDBox display='flex' alignItems='right'> */}
                                <MDTypography color="black" variant="text" fontSize="16px">
                                Is Active: 
                                  <Field
                                    name="isActive"
                                    label="Is Active"
                                    component={renderCheckbox}
                                    //sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                                    size="medium"
                                    color="orange"
                                    fullWidth
                                    />
                                    </MDTypography> 
                            {/*</MDBox> */}
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                                <MDTypography color="black" variant="text" fontSize="16px">
                                Is Private: 
                                  <Field
                                    name="isPrivate"
                                    component={renderCheckbox}
                                    //sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                                    size="medium"
                                    color="orange"
                                    fullWidth
                                    />
                                    </MDTypography>
                            </Grid>
                     {/*        <Grid item xs={12} sm={6} xl={3}>
                                <MDTypography color="black" variant="text" fontSize="16px">
                                Is Admin Need Approval: 
                                  <Field
                                    name="isNeedAdminApproval"
                                    component={renderCheckbox}
                                    //sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                                    size="medium"
                                    color="orange"
                                    fullWidth
                                    />
                                    </MDTypography> 
                            </Grid> */} 
                                         
                        </Grid>            
                        </MDBox>
                    </MDBox>
                     <MDBox  p={2} textAlign="right" >
                        <MDButton
                          variant="outlined"
                          color="success"
                          type="submit"
                          sx={{ marginRight: "15px" }}
                        >
                          Save
                        </MDButton>
                      <MDButton
                        variant="outlined"
                        color="error"
                        onClick={() => navigate("/hoods")}
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
  );
};

export default Hood;
