import {
  GET_LABEL_LANGUAGE_ADD_REQUEST,
  SAVE_LABEL_LANGUAGE_ADD_SUCCESS,
  GET_LABEL_LANGUAGE_BY_ID,
  SAVE_LABEL_LANGUAGE_BY_ID,
  GET_LABEL_LANGUAGE_DELETE_REQUEST,
  SAVE_LABEL_LANGUAGE_DELETE_SUCCESS,
  GET_LABEL_LANGUAGE_UPDATE_REQUEST,
  SAVE_LABEL_LANGUAGE_UPDATE_SUCCESS,
  GET_LABEL_LANGUAGE_LIST,
  SAVE_LABEL_LANGUAGE_LIST,
  GET_LABEL_LANGUAGE_STATUS_UPDATE_REQUEST,
  SAVE_LABEL_LANGUAGE_STATUS_UPDATE_SUCCESS
  } from "../types/action-type";
  
  export const getLabelLanguageList = (pageIndex, pageSize, searchKey) => ({
    type:  GET_LABEL_LANGUAGE_LIST,
    pageIndex, 
    pageSize, 
    searchKey
  });

  export const saveLabelLanguageList = (data) => ({
    type:  SAVE_LABEL_LANGUAGE_LIST,
    data,
  });


  export const getLabelLanguageById = (key) => ({
    type:  GET_LABEL_LANGUAGE_BY_ID,
    key,
  });

  
  export const saveLabelLanguageById = (data) => ({
    type: SAVE_LABEL_LANGUAGE_BY_ID,
    data,
  });

  export const getLabelLanguageUpdateRequest = (data) => ({
    type: GET_LABEL_LANGUAGE_UPDATE_REQUEST,
    data,
  });

  export const saveLabelLanguageUpdateSuccess = () => ({
    type: SAVE_LABEL_LANGUAGE_UPDATE_SUCCESS,
  });

  export const getLabelLanguageStatusUpdateRequest = (data) => ({
    type: GET_LABEL_LANGUAGE_STATUS_UPDATE_REQUEST,
    data,
  });

  export const saveLabelLanguageStatusUpdateSuccess = () => ({
    type: SAVE_LABEL_LANGUAGE_STATUS_UPDATE_SUCCESS,
  });

  export const getLabelLanguageAddRequest = (data) => ({
    type: GET_LABEL_LANGUAGE_ADD_REQUEST,
    data,
  });

  export const saveLabelLanguageAddSuccess = () => ({
    type: SAVE_LABEL_LANGUAGE_ADD_SUCCESS,
  });
  
  export const getLabelLanguageDeleteRequest = (data) => ({
    type: GET_LABEL_LANGUAGE_DELETE_REQUEST,
    data,
  });

  export const saveLabelLanguageDeleteSuccess = () => ({
    type:  SAVE_LABEL_LANGUAGE_DELETE_SUCCESS,
  });
  