import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const getRoutes = (Outlet, pathname) => {
  if (pathname === "/" && localStorage.getItem("token") !== null) {
    return <Navigate to="/dashboard" />;
  } else {
    return <Outlet />;
  }
};
const AuthenticatedRoutes = () => {
  const { pathname } = useLocation();
  return localStorage.getItem("token") !== null ? (
    getRoutes(Outlet, pathname)
  ) : (
    <Navigate to="/admin/signin" />
  );
};

export default AuthenticatedRoutes;
