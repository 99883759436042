import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get as _get,
  find as _find,
  map as _map,
  omit as _omit,
  isEmpty as _isEmpty,
} from "lodash";
import MDTypography from "../../../components/MDTypography";
import {
  getHoodCategoryAddRequest,
  getHoodCategoryById,
  getHoodCategoryDeleteRequest,
  getHoodCategoryList,
  getHoodCategoryStatusUpdateRequest,
  getHoodCategoryUpdateRequest,
} from "../../../actions/hoods";
import { Field, FieldArray, Form, Formik } from "formik";
import MDButton from "../../../components/MDButton";
import { getLanguageList } from "../../../actions/language";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { showError } from "../../../utils";

import IconButton from '@mui/material/IconButton';
import pxToRem from "../../../../src/assets/theme/functions/pxToRem";

import CloseIcon from '@mui/icons-material/Close';


const HoodCategories = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [newLabel, setNewLabel] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "hoods.hoodCategoryList.records", []),
      code: _get(state, "hoods.hoodCategoryData.code", ""),
      label: _get(state, "hoods.hoodCategoryData.label", []),
      categoryId: _get(state, "hoods.hoodCategoryData._id", ""),
      status: _get(state, "hoods.hoodCategoryData.status", false),
      languageList: _get(state, "language.list", []),
    };
  });

  useEffect(() => {
    dispatch(getHoodCategoryList());
    if(storeProps.languageList.length > 0) {
        labelledData()
    }
  }, [dispatch, storeProps.languageList]);


  const initialValues = {
    code: "",
    label: [
      {
        local: "",
        name: "",
        languageName: "",
      },
    ],
  };

  const getTitleName = (label) =>
    _map(label, (lab) => ({ name: lab.name }))[0].name;

  const handleChangeStatus = (value, id) => {
    const statusData = { id, status: value };
    dispatch(getHoodCategoryStatusUpdateRequest(statusData));
  };

  const tableData = {
    columns: [
      {
        Header: "title",
        accessor: "label",
        Cell: (row) => (
          <Tooltip title={getTitleName(_get(row, "row.original.label"))}>
            <MDTypography
              variant="caption" color="text" fontWeight="regular" fontSize="14px"
            >
              {getTitleName(_get(row, "row.original.label"))}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: (row) => (
          <Switch
            checked={_get(row, "row.original.status")}
            onChange={(e, value) =>
              handleChangeStatus(value, _get(row, "row.original._id"))
            }
          />
        ),
        align: "center",
        width: "5%",
      },
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <MDBox width="100px" margin="auto">
            <IconButton sx={{ 
              color: "#54AF3A", 
              backgroundColor: "#E8FFE2", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              '&:hover' :{
                backgroundColor: "#e6f3e2"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() => handleClickOpen(_get(row, "row.original._id"))}
                sx={{ cursor: "pointer" }}
              >
                edit_icon
              </Icon>
            </IconButton>
            <IconButton sx={{ 
              color: "#F05A22", 
              backgroundColor: "#fde4db", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              cursor: "pointer", ml: 2,
              '&:hover' :{
                backgroundColor: "#fbcfbf"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() => {
                  setOpenConfirmation(true);
                  setDeleteId(_get(row, "row.original._id"));
                }}
                sx={{ cursor: "pointer" }}
              >
                delete
              </Icon>
            </IconButton>
          </MDBox>
        ),
        align: "center",
        width: "10%",
      },
    ],

    rows: _get(storeProps, "data", []),
  };
  const { columns, rows } = tableData;

  const handleClickOpen = (id) => {
    setOpen(true);
    dispatch(getHoodCategoryById(id));
    dispatch(getLanguageList());
    labelledData(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsNew(false);
    setNewLabel([]);
  };

  const handleChange = (event, value, index, setFieldValue, formValues) => {
    let checkFormLabel;
    if (!_isEmpty(formValues.label)) {
      checkFormLabel = _find(formValues.label, (e) => e.local === value.local);
    }
    if (!checkFormLabel) setFieldValue(`label.${index}`, value);
    else toast.error("Same Language Not Allowed!");
  };

  const updateCategory = (values) => {
    const labelArr = _map(_get(values, "label"), (label) =>
      _omit(label, ["languageName"])
    );
    let hoodCategoryData = {
      label: labelArr,
    };
    if (isNew) {
      hoodCategoryData.code = labelArr[0].name;
      dispatch(getHoodCategoryAddRequest(hoodCategoryData));
    } else {
      hoodCategoryData.id = _get(storeProps, "categoryId");
      hoodCategoryData.code = _get(storeProps, "code");
      dispatch(getHoodCategoryUpdateRequest(hoodCategoryData));
    }

    handleClose();
  };

  const addCategory = () => {
    dispatch(getLanguageList());
    setIsNew(true);
  };

  const labelledData = (openAgain) => {
    if(!openAgain)
      _map(_get(storeProps, "languageList"), (langListObj) => {
        const obj = {
          local: langListObj.code,
          languageName: langListObj.label,
        };
        newLabel.push(obj);
      });
  };

  const schema = Yup.object().shape({
    label: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Required"),
        local: Yup.string().required("Required"),
      })
    ),
  });

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setDeleteId("");
  };

  const deleteHoodCategory = () => {
    dispatch(getHoodCategoryDeleteRequest({ id: deleteId }));
    handleConfirmationClose();
  };

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="flex-end" textAlign="center" p={2}>
        
            <MDButton variant="outlined" color="orange" onClick={() => addCategory()}>
              {" "}
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                add_icon
              </Icon>{" "}
              Add
            </MDButton>
          
      </MDBox>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={true}
        noEndBorder
        //   canSearch
        forSettings
      />
      <Dialog
        open={open || isNew}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, pr: 8 }}>
          {isNew ? "Add Category" : "Edit Category"}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 10,
                color: (theme) => theme.palette.grey[700],
                backgroundColor: (theme) => theme.palette.grey[200],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <Divider sx={{ marginTop: 0 }} />
        <DialogContent sx={{ marginTop: 0 }}>
          <Formik
            initialValues={isNew ? initialValues : storeProps}
            validationSchema={schema}
            enableReinitialize
            onSubmit={async (values) => updateCategory(values)}
            render={({
              values,
              errors,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <FieldArray
                    name="label"
                    render={({ remove, push }) => (
                      <>
                        {values.label.map((label, index) => (
                          <>
                            <Grid container spacing={2} py={3}>
                              <Grid item xs={12} md={12}>
                                {values.label.length < storeProps.languageList.length && (
                                  <MDBox textAlign="right">
                                  <MDButton
                                    p={3}
                                    size="small"
                                    variant="text"
                                    color="info"
                                    sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                    onClick={() =>
                                      push({
                                        local: "",
                                        name: "",
                                        languageName: "",
                                      })
                                    }
                                  >
                                   Add Language
                                  </MDButton>
                                  </MDBox>
                                )}
                                {values.label.length > 1 && (
                                  <MDBox textAlign="right">
                                    <MDButton
                                      variant="text"
                                      color="error"
                                      onClick={() => remove(index)}
                                      sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                    >
                                      Remove
                                    </MDButton>
                                  </MDBox>
                                )}
                                <Field name={`label`}>
                                  {({ field }) => {
                                    console.log('field.languageName', field, (!isNew && !_isEmpty(label.languageName)))
                                  return (
                                    <>
                                      <Autocomplete
                                        getOptionLabel={(option) =>
                                          option.languageName
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          option.local === value.local
                                        }
                                        value={field.value[index]}
                                        onChange={(event, value) =>
                                          handleChange(
                                            event,
                                            value,
                                            index,
                                            setFieldValue,
                                            values
                                          )
                                        }
                                        options={newLabel}                                       
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Language"
                                          />
                                        )}
                                        disabled={(!isNew && !_isEmpty(label.languageName))}
                                      />
                                    </>
                                  )}}
                                </Field>
                                {errors?.label?.[index]?.local &&
                                  // touched?.label?.[index]?.local &&
                                  showError(errors.label[index].local)}
                                <Field name={`label.${index}.name`}>
                                  {({ field }) => (
                                   <TextField
                                      fullWidth
                                      multiline
                                      label="Name"
                                      InputProps={{
                                          rows: 3
                                      }}
                                      placeholder="Name"
                                      style={{ width: "100%", marginTop: 10 }}
                                      onChange={field.onChange(field.name)}
                                      value={field.value}
                                    />
                                  )}
                                </Field>
                                {errors?.label?.[index]?.name &&
                                  // touched?.label?.[index]?.name &&
                                  showError(errors.label[index].name)}
                                
                              </Grid>
                            </Grid>
                            
                          </>
                        ))}
                        <MDBox
                          pt={3}
                          display="flex"
                          justifyContent={
                            values.label.length < 2
                              ? "flex-end"
                              : "flex-end"
                          }
                        >
                          
                          
                            {values.label.length !== 0 && (
                              <MDButton
                                variant="outlined"
                                color="success"
                                type="submit"
                                sx={{ marginRight: "15px" }}
                              >
                                Save
                              </MDButton>
                            )}
                            <MDButton
                              variant="outlined"
                              color="error"
                              onClick={handleClose}
                            >
                              Cancel
                            </MDButton>
                          
                        </MDBox>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openConfirmation} onClose={handleConfirmationClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign="center" sx={{ m: 0, px: 2, pt: 4 }}>
          {"Are you sure you want to delete Hood Category?"}
        </DialogTitle>
        <DialogActions textAlign="center" display="flex" sx={{ justifyContent:"center", pb:4 }}>
        <MDButton
            variant="outlined"
            color="success"
            onClick={deleteHoodCategory}
            sx={{mr:1, minWidth:"125px"}}
          >
            Agree
          </MDButton>
          <MDButton
            variant="outlined"
            color="error"
            onClick={handleConfirmationClose}
            sx={{minWidth:"125px"}}
          >
            Disagree
          </MDButton>
          
        </DialogActions>
      </Dialog>
    </MDBox>
  );
};

export default HoodCategories;
