import { reverse as _reverse, get as _get } from "lodash";
import {
 SAVE_LABEL_LANGUAGE_BY_ID,
 SAVE_LABEL_LANGUAGE_LIST
} from "../types/action-type";
const initialState = {
  labelLanguageList: [],
  loading: false,
  error: null,
  labelLanguageData: {},
  pagination:{}
};
export default function labelLanguageReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_LABEL_LANGUAGE_LIST:
      return {
        ...state,
        loading: true,
        labelLanguageList: _reverse(_get(action,'data.records', [])),
        pagination: {
          totalRecord: _get(action,'data.totalRecord', 0),
          pageIndex: _get(action,'data.pageIndex', 0),
          pageSize: _get(action,'data.pageSize', 0),
        }
      };
    case SAVE_LABEL_LANGUAGE_BY_ID:
      return {
        ...state,
        loading: true,
        labelLanguageData: action.data,
      };
    default:
      return state;
  }
}
