import { reverse as _reverse } from "lodash";
import {
  SAVE_PLACE_CATEGORY_BY_ID,
  SAVE_PLACE_CATEGORY_LIST,
  SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN,
  SAVE_PLACE_LIST,
  CLEAR_PLACE_DATA_SUCCESS,
  SAVE_PLACE_TAG_CATEGORY_LIST,
  SAVE_PLACE_TAG_CATEGORY_BY_ID,
  SAVE_PLACES_LIST,
  SAVE_PLACE_DETAILS,
  SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN,
  // SAVE_PLACE_TAG_SUBCATEGORY__LIST
} from "../types/action-type";
const initialState = {
  placeCategoryList: [],
  placeCategoryListForDropdown: [],
  loading: false,
  error: null,
  placeCategoryData: {},
  placeCategoryDataForDropdown: {},
  placeList: [],
  placeData: {},
  placeSubCategoryData: {},
  placeSubCategoryList: [],
  placeTagSubCategoryList: [],
  placeTagSubCategoryData:{},
  placeTagCategoryData: {},
  placesList:[],
  placeDetails:{}
};
export default function placeReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_PLACE_CATEGORY_LIST:
      return {
        ...state,
        loading: true,
        placeCategoryList: _reverse(action.data),
      };
  case SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN:
        return {
          ...state,
          loading: true,
          placeCategoryListForDropdown: _reverse(action.data),
        };
    case SAVE_PLACE_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
        placeCategoryData: action.data,
      };
    case SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN:
        return {
          ...state,
          loading: true,
          placeCategoryDataForDropdown: action.data,
        };
    case SAVE_PLACE_LIST:
      return {
        ...state,
        placeList: action.data,
      }
    case CLEAR_PLACE_DATA_SUCCESS:
      return {
        ...state,
        placeData: {}
      }
      case SAVE_PLACE_TAG_CATEGORY_LIST:
        return {
          ...state,
          loading: true,
          placeSubCategoryList: _reverse(action.data),
        };
        // case SAVE_PLACE_TAG_SUBCATEGORY__LIST:
        //   return {
        //     ...state,
        //     loading: true,
        //     placeTagSubCategoryList: _reverse(action.data),
        //   };
      case SAVE_PLACE_TAG_CATEGORY_BY_ID:
        return {
          ...state,
          loading: true,
          placeTagCategoryData: action.data,
        };
      case SAVE_PLACES_LIST:
        return {
          ...state,
          loading: true,
          placesList: action.data
        }  
      case SAVE_PLACE_DETAILS:
        return {
          ...state,
          loading: true,
          placeDetails:action.data
        }
    default:
      return state;
  }
}
