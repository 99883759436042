import {GET_DELETED_USERS_LIST,SAVE_DELETED_USERS_LIST} from '../types/action-type';

export const getDeletedUsersList = (pageIndex, pageSize, searchKey) => ({
   
    type: GET_DELETED_USERS_LIST,
    pageIndex,
    pageSize,
    searchKey,
});
export const saveDeletedUsersList = (data) => ({
    type: SAVE_DELETED_USERS_LIST,
    data,
});