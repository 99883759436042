import {
  SAVE_APP_USER_BY_ID,
  SAVE_USER_LIST,
  SAVE_HOOD_LIST_BY_USER_ID,
  SAVE_PLACE_LIST_BY_USER_ID,
  SAVE_USER_FOLLOWER_LIST,
  CLEAR_USER_FOLLOWER_LIST,
} from "../types/action-type";
import { reverse as _reverse } from "lodash";
const initialState = {
  loading: false,
  error: null,
  list: [],
  userData: {},
  hoodList: [],
  placeList: [],
  followerList: [],
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_LIST:
      return {
        ...state,
        loading: true,
        list: _reverse(action.data),
      };
    case SAVE_APP_USER_BY_ID:
      return {
        ...state,
        loading: true,
        userData: action.data,
      };
    case SAVE_HOOD_LIST_BY_USER_ID:
      return {
        ...state,
        loading: true,
        hoodList: _reverse(action.data),
      };
    case SAVE_PLACE_LIST_BY_USER_ID:
      return {
        ...state,
        loading: true,
        placeList: _reverse(action.data),
      };
    case SAVE_USER_FOLLOWER_LIST:
      return {
        ...state,
        loading: true,
        followerList: _reverse(action.data),
      };
    case CLEAR_USER_FOLLOWER_LIST:
      return {
        ...state,
        loading: true,
        followerList: [],
      };
    default:
      return state;
  }
}
