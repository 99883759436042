import {
  GET_COUNTRY_LIST,
  SAVE_COUNTRY_LIST,
  GET_COUNTRY_FORM_DATA,
  SAVE_COUNTRY_FORM_DATA,
  GET_COUNTRY_BY_ID,
  SAVE_COUNTRY_BY_ID,
  CLEAR_COUNTRY_REQUEST,
  CLEAR_COUNTRY_SUCCESS,
} from "../types/action-type";

export const getCountryList = (pageIndex, pageSize, searchKey) => ({
  type: GET_COUNTRY_LIST,
  pageIndex, pageSize, searchKey
});

export const saveCountryList = (data) => ({
  type: SAVE_COUNTRY_LIST,
  data,
});

export const setCountryFormData = (data, navigate) => ({
  type: GET_COUNTRY_FORM_DATA,
  data,
  navigate,
});

export const saveCountryFormData = (data) => ({
  type: SAVE_COUNTRY_FORM_DATA,
  data,
});

export const getCountryById = (id) => ({
  type: GET_COUNTRY_BY_ID,
  id,
});

export const saveCountryById = (data) => ({
  type: SAVE_COUNTRY_BY_ID,
  data,
});

export const clearCountry = () => ({
  type: CLEAR_COUNTRY_REQUEST,
});

export const clearCountrySuccess = () => ({
  type: CLEAR_COUNTRY_SUCCESS,
});
