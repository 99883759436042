import { color } from "@mui/system";
import MDBox from "../../components/MDBox";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <MDBox
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {children}
    </MDBox>
  );
};

export default TabPanel;
