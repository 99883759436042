import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import pxToRem from "../../../src/assets/theme/functions/pxToRem";

import MDButton from "../../components/MDButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {  Accordion, AccordionDetails, AccordionSummary, Autocomplete, Avatar, Chip,  Icon,  IconButton,  TextField, Tooltip,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from "@mui/material";
// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {
  concat,
  get as _get,
  isEmpty,
  isObject,
  map as _map,
} from "lodash";
import { Field, Form, Formik } from "formik";
import {
  renderTextField,
  renderCheckbox,
  renderSelectField
} from "../../inputFields/formFields";
import { deletePostsCommentById, getPostsById, getTagCategoryList,updatePosts } from "../../actions/posts";
import { Carousel } from "react-responsive-carousel";
import MDAvatar from "../../components/MDAvatar";
const  PostsDetails= () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const storeProps=useSelector(state=>{
      return {
        data:_get(state,"posts.postsData",{}),
        tagCategoryList:_get(state,"posts.categoryData",[])
      }
    });
  
    useEffect(() => {
      dispatch(getPostsById(params.id));
        dispatch(getTagCategoryList())
    }, [dispatch]);
    const categoryData=_get(storeProps,"tagCategoryList",[]);
    let [initialValues,setInitialValues] = useState(storeProps.data);
    useEffect(() => {
      setInitialValues(storeProps.data);
    }, [storeProps.data])
    
  let subData = initialValues?.tagsCode?.map((item) => item)
    useEffect(() => {
      setCategory(categoryData?.filter((item)=>{
        return !isEmpty(subData?.filter((subItem)=> {
          if(item._id === subItem) return item.label
        }))
      }))
    }, [initialValues])
    const handleChange = (event,values) => {  
     subData=values.map((item)=>item._id)
      setInitialValues({
        ...initialValues,
        tagsCode:subData
      })
  } 

     const [category, setCategory] = useState([]);
     const [deletedId, setDeletedId] = useState();
     const [deleteDialog, setDeleteDialog] = useState(false);
     const [postId, setPostId] = useState();
    const handleSubmitForUpdate=async (values)=>{
      const values2={
        ...values,
        id:params.id
      }
      dispatch(updatePosts(values2, navigate))
   }
  const handleDeleteClickOpen = (id,postId) => {
      setDeletedId(id)
      setPostId(postId)
      setDeleteDialog(true)
  };
      const deleteUser=(id,postId)=>{
        setDeletedId(id)
        setPostId(postId)
        if (deletedId) {
          console.log("deletedId",deletedId)
        dispatch(deletePostsCommentById(deletedId,postId))
        setDeleteDialog(false)
    }
    // dispatch(deleteUserById(deletedUserId))
    // setDeleteDialog(false)
    }
    const handleDeleteClose = () => {
    setDeleteDialog(false)
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={0} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={0}
              mt={0}
              py={3}
              px={2}
              mb={0}
              variant="contained"
              bgColor='rgba(0, 0, 0, 0.0)'
              borderRadius="lg"
              // coloredShadow="info"
            >
              <MDTypography variant="h5" color="black">
                Edit Posts Details
              </MDTypography>
            </MDBox>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleSubmitForUpdate}
              >
              {({
                values,
                setFieldValue,
                handleSubmit,
              }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <MDBox px={2}>
                        <MDBox width="100%" textAlign="center">
                        {_get(initialValues, 'images', []).length > 0 &&
                          <>
                              {initialValues.images.map((img, index) => (
                          <Avatar
                                  key={index}
                                
                                  src={img.url}
                                  variant="square"
                                  sx={{ width: '20%', height: '20%', m: 2 }}
                                /> 

                              ))}
                          </>

                        }
                        {
                          _get(initialValues, 'images', []).length === 0 &&
                          <Avatar
                              variant="square"
                              sx={{ width: '20%', height: '20%', m: 2 }}
                              src="https://www.pngitem.com/pimgs/m/150-1503945_image-placeholder-png-user-profile-placeholder-image-png.png"  
                            /> 
                        }
                        </MDBox>
                        <MDBox width="100%" mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="title"
                                component={renderTextField}
                                label="Post Title"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="post"
                                component={renderTextField}
                                label="Hood Description"
                                multiline={true}
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="user.firstName"
                                component={renderTextField}
                                label="User Creator"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="hood.name"
                                component={renderTextField}
                                label="Hood Name"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MDTypography color="black" variant="text" fontSize="16px">
                                Is Post Active: 
                                  <Field
                                    name="isPostActive"
                                    label="Is Post Active"
                                    component={renderCheckbox}
                                    //sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                                    size="medium"
                                    color="orange"
                                    fullWidth
                                    />
                              </MDTypography> 
                            </Grid>
                            {subData&&
                            // categoryData&&
                              <Grid item xs={12} sm={6}>
                                <MDTypography color="black" variant="text" fontSize="16px">
                                 Tags Category:
                               </MDTypography>
                                <Autocomplete
                                  multiple
                                  name="tagsCode"
                                  options={categoryData}
                                  value={category}
                                  key={category}
                                  getOptionSelected={(option, value) => {
                                    return option.label === value.label}}
                                  getOptionLabel={(option) => option.label}
                                  onChange={ handleChange}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                      return(
                                        <Chip variant="outlined" name="tagsCode" key={index}  value={option._id} label={option.label} {...getTagProps({ index })} />
                                      )
                                    })
                                  } 
                                  renderInput={(params) => <TextField name="tagsCode" {...params}   id={params._id} value={params._id}  />}
                                />
                              </Grid>
                          }
                           <Grid item xs={10} sm={12}>
                          {initialValues?.postComments?.length > 0 &&
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <MDTypography color="black" variant="text" fontSize="16px">
                                  Comment 
                                </MDTypography>
                              </AccordionSummary>
                              <AccordionDetails>
                                 {initialValues?.postComments?.map((item, index) => {
                        return (
                          <MDBox key={index} p={1} mb={1.5} sx={({ palette: { grey },  borders: { borderWidth } }) => ({border: `${borderWidth[1]} solid ${grey[200]}`, borderRadius: '8px'})}>
                              <MDBox display="flex" alignItems="flex-start">
                           <MDAvatar src={item.user?.profilePicture!==""?item.user?.profilePicture:"http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"} sx={{ height: "auto", width: "auto", maxWidth: "50px",  objectFit: "cover", borderRadius: "6px", marginRight: "10px" }} /> 
                                 <Tooltip title="Delete Comment" placement="bottom" arrow={true}>
                            <IconButton sx={{
                                color: "#F05A22",
                                backgroundColor: "#fde4db",
                                borderRadius: "6px",
                                width: pxToRem(35),
                                height: pxToRem(35),
                                ml: 2,
                                '&:hover': {
                                    backgroundColor: "#fbcfbf"
                                }
                                
                            }}onClick={() => handleDeleteClickOpen(item._id,item.postId)}  >{/* onClick={() => handleDeleteClickOpen(_get(row, "row.original._id"))} */}
                                <Icon
                                    fontSize="small"
                                    // onClick={() => handleDeleteClickOpen(_get(row, "row.original._id"))}
                                    sx={{ cursor: "pointer" }}
                                >
                                    delete_icon
                                </Icon>
                            </IconButton>
                        </Tooltip>
                            </MDBox>
                            <MDBox>
                              <MDTypography variant="h6" color="black">
                           {item.user?.firstName} {item.user?.lastName} 
                              </MDTypography>
                              <MDTypography variant="body2" color="black">
                                {item.comment}
                              </MDTypography>
                            </MDBox>
                       
                          </MDBox>
                        )
                      }
                        )}
                              </AccordionDetails>
                            </Accordion>
                            }
                            </Grid>
                          </Grid>
                      </MDBox>
              {deleteDialog&&deletedId!==null?
              <Dialog open={deleteDialog} onClose={()=>setDeleteDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                  Delete Comment
                  </DialogTitle>
                <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
                <DialogContent>
                  <DialogContentText sx={{ maxHeight: "400px"}}>
                    Are you sure you want to delete this comment?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={()=>handleDeleteClose()} variant="outlined" color="error">Cancel</MDButton> 
                  <MDButton onClick={()=>deleteUser(deletedId,postId)} variant="outlined" color="error">Delete</MDButton> 
                </DialogActions>
                </Dialog>
            :null}
                    </MDBox>
                     <MDBox  p={2} textAlign="right" >
                        <MDButton
                          variant="outlined"
                          color="success"
                          type="submit"
                          sx={{ marginRight: "15px" }}
                        >
                          Save
                        </MDButton>
                      <MDButton
                        variant="outlined"
                        color="error"
                        onClick={() => navigate("/posts")}
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Grid>
      </Grid> 
    </MDBox>
     </DashboardLayout>
    )
}

export default PostsDetails;