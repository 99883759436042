import { useRef, useCallback, useEffect } from "react";
import MDBox from "../components/MDBox";

export const showError = (message) => (
  <MDBox 
    sx={{ 
      textDecoration: "none", 
      fontFamily: ["Roboto","Helvetica","Arial","sans-serif"].join(","),
      fontSize: "0.75rem",
      fontWeight: 300,
      lineHeight: 1.25,
      letterSpacing: "0.03333em",
      textAlign: "left",
      marginTop: "3px",
      marginRight: "14px",
      marginBottom: 0,
      marginLeft: "14px"
    }} 
    color="#F44335"
  >
    {message}
  </MDBox>
);


export const useInfiniteScroll = (callback, isFetching) => {
  const observer = useRef();

  useEffect(() => {
    callback();
  }, []);

  const lastElementRef = useCallback(
    (node) => {
      if (isFetching) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          callback();
        }
      });

      if (node) observer.current.observe(node);
    },
    [callback, isFetching]
  );

  return [lastElementRef];
};