import { SAVE_POSTS_LISTS,SAVE_POSTS_BY_ID, CLEAR_POSTS_DETAILS,SAVE_TAG_CATEGORY} from '../types/action-type';
import { reverse as _reverse } from "lodash";
const initialState = {
    loading: false,
    error: null,
    postListData: {},
    postsData: {},
    categoryData:[],
};
export default function postsReducer(state = initialState, action) {

    switch (action.type) {
        case SAVE_POSTS_LISTS:
            return {
                ...state,
                loading: true,
                postListData: _reverse(action.data),
            };
        case SAVE_POSTS_BY_ID:
            return {
                ...state,
                loading: true,
                postsData: action.data,
            };
        case CLEAR_POSTS_DETAILS:
            return {
                ...state,
                postsData: {},
            };
        case SAVE_TAG_CATEGORY:
            return {
                ...state,
                loading: true,
                categoryData: _reverse(action.data),
            };
        default:
            return state;
    }
}