
// Material Dashboard 2 React base styles
import colors from "../../base/colors";
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";

// Material Dashboard 2 React helper functions
import pxToRem from "../../functions/pxToRem";
import rgba from "../../functions/rgba";
const { grey, white, orange, purple } = colors;
const { borderRadius, borderWidth } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: rgba(`${white.main}`,1),
      // borderRadius: borderRadius.xl,
      minHeight: "unset",
      padding: pxToRem(0),
      borderRadius:"0px !important",
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
      flexWrap: "nowrap",
      overflowX: "auto",
      overflowY: "hidden",
      flexDirection: "row",
      flex: 1,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      height: "100%",
      borderRadius:"0px !important",
      // backgroundColor: rgba(orange.main,0.1),
      backgroundColor:white.main,
      borderBottom: `${borderWidth[2]} solid ${orange.main}`,
      color: `${orange.main}`,
      // boxShadow: tabsBoxShadow.indicator,
      transition: "all 500ms ease",
    },
  },
};

export default tabs;
