import {
    GET_PLACE_CATEGORY_LIST,
    SAVE_PLACE_CATEGORY_LIST,
    GET_PLACE_CATEGORY_LIST_FOR_DROPDOWN,
    SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN,
    GET_PLACE_CATEGORY_BY_ID,
    SAVE_PLACE_CATEGORY_BY_ID,
    GET_PLACE_CATEGORY_UPDATE_REQUEST,
    SAVE_PLACE_CATEGORY_UPDATE_SUCCESS,
    GET_PLACE_CATEGORY_ADD_REQUEST,
    GET_PLACE_TAG_SUBCATEGORY_LIST,
    SAVE_PLACE_CATEGORY_ADD_SUCCESS,
    GET_PLACE_CATEGORY_STATUS_UPDATE_REQUEST,
    SAVE_PLACE_CATEGORY_STATUS_UPDATE_SUCCESS,
    GET_PLACE_CATEGORY_DELETE_REQUEST,
    SAVE_PLACE_CATEGORY_DELETE_SUCCESS,
    GET_PLACE_TAG_CATEGORY_LIST,
    SAVE_PLACE_TAG_CATEGORY_LIST,
    SAVE_PLACE_TAG_SUBCATEGORY_LIST,
    GET_PLACE_TAG_CATEGORY_BY_ID,
    SAVE_PLACE_TAG_CATEGORY_BY_ID,
    GET_PLACE_TAG_CATEGORY_STATUS_UPDATE_REQUEST,
    SAVE_PLACE_TAG_CATEGORY_STATUS_UPDATE_SUCCESS,
    GET_PLACE_TAG_CATEGORY_ADD_REQUEST,
    SAVE_PLACE_TAG_CATEGORY_ADD_SUCCESS,
    GET_PLACE_TAG_CATEGORY_UPDATE_REQUEST,
    SAVE_PLACE_TAG_CATEGORY_UPDATE_SUCCESS,
    GET_PLACE_TAG_CATEGORY_DELETE_REQUEST,
    SAVE_PLACE_TAG_CATEGORY_DELETE_SUCCESS,
    GET_PLACE_TABLE_LIST,
    GET_PLACE_LIST,
    SAVE_PLACE_LIST,
    GET_PLACE_BY_ID,
    SAVE_PLACE_BY_ID,
    CLEAR_PLACE_DATA_REQUEST,
    CLEAR_PLACE_DATA_SUCCESS,
    SAVE_PLACES_LIST,
    GET_PLACE_DETAILS,
    SAVE_PLACE_DETAILS,
    UPDATE_PLACE_DETAILS,
    GET_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN,
    SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN
  } from "../types/action-type";
  
  export const getPlaceCategoryList = () => ({
    type: GET_PLACE_CATEGORY_LIST,
  });
  export const getPlaceCategoryListForDropdown = () => ({
    type: GET_PLACE_CATEGORY_LIST_FOR_DROPDOWN,
  });
  export const getPlaceTagCategoryList = () => ({
    type: GET_PLACE_TAG_CATEGORY_LIST,
  });
  
  export const savePlaceCategoryList = (data) => ({
    type: SAVE_PLACE_CATEGORY_LIST,
    data,
  });
  export const savePlaceCategoryListForDropdown = (data) => ({
    type: SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN,
    data,
  });

  export const savePlaceTagCategoryList = (data) => ({
    type: SAVE_PLACE_TAG_CATEGORY_LIST,
    data,
  });

  export const getPlacesTableList = (pageIndex, pageSize, searchKey,primaryCode) => ({
    type: GET_PLACE_TABLE_LIST,
    pageIndex,
    pageSize,
    searchKey,
    primaryCode
  })
  
  // export const savePlaceTagSubCategoryList = (data) => ({
  //   type: SAVE_PLACE_TAG_SUBCATEGORY_LIST,
  //   data,
  // });

  export const getPlaceCategoryById = (id) => ({
    type: GET_PLACE_CATEGORY_BY_ID,
    id,
  });
  export const getPlaceCategoryByIdForDropdown = (id) => ({
    type: GET_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN,
    id,
  });
  export const getPlaceTagCategoryById = (categoryId, subCategoryId) => ({
    type: GET_PLACE_TAG_CATEGORY_BY_ID,
    categoryId,
    subCategoryId
  });
  
  export const savePlaceCategoryById = (data) => ({
    type: SAVE_PLACE_CATEGORY_BY_ID,
    data,
  });

  export const savePlaceCategoryByIdForDropdown = (data) => ({
    type: SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN,
    data,
  });

  export const savePlaceTagCategoryById = (data) => ({
    type: SAVE_PLACE_TAG_CATEGORY_BY_ID,
    data,
  });

  export const getPlaceCategoryUpdateRequest = (data) => ({
    type: GET_PLACE_CATEGORY_UPDATE_REQUEST,
    data,
  });

  export const getPlaceTagCategoryUpdateRequest = (data) => ({
    type: GET_PLACE_TAG_CATEGORY_UPDATE_REQUEST,
    data,
  });
  
  export const savePlaceCategoryUpdateSuccess = () => ({
    type: SAVE_PLACE_CATEGORY_UPDATE_SUCCESS,
  });

  export const savePlaceTagCategoryUpdateSuccess = () => ({
    type: SAVE_PLACE_TAG_CATEGORY_UPDATE_SUCCESS,
  });
  
  export const getPlaceCategoryAddRequest = (data) => ({
    type: GET_PLACE_CATEGORY_ADD_REQUEST,
    data,
  });

  export const getPlaceTagCategoryAddRequest = (data) => ({
    type: GET_PLACE_TAG_CATEGORY_ADD_REQUEST,
    data,
  });
  
  export const savePlaceCategoryAddSuccess = () => ({
    type: SAVE_PLACE_CATEGORY_ADD_SUCCESS,
  });
  
  export const savePlaceTagCategoryAddSuccess = () => ({
    type: SAVE_PLACE_TAG_CATEGORY_ADD_SUCCESS,
  });
  
  export const getPlaceCategoryStatusUpdateRequest = (data) => ({
    type: GET_PLACE_CATEGORY_STATUS_UPDATE_REQUEST,
    data,
  });

  export const getPlaceTagCategoryStatusUpdateRequest = (data) => ({
    type: GET_PLACE_TAG_CATEGORY_STATUS_UPDATE_REQUEST,
    data,
  });
  
  export const savePlaceCategoryStatusUpdateSuccess = () => ({
    type: SAVE_PLACE_CATEGORY_STATUS_UPDATE_SUCCESS,
  });

  export const savePlaceTagCategoryStatusUpdateSuccess = () => ({
    type: SAVE_PLACE_TAG_CATEGORY_STATUS_UPDATE_SUCCESS,
  });
  
  export const getPlaceCategoryDeleteRequest = (data) => ({
    type: GET_PLACE_CATEGORY_DELETE_REQUEST,
    data,
  });

  export const getPlaceTagCategoryDeleteRequest = (data) => ({
    type: GET_PLACE_TAG_CATEGORY_DELETE_REQUEST,
    data,
  });


  export const savePlaceTagCategoryDeleteSuccess = (data) => ({
    type: SAVE_PLACE_TAG_CATEGORY_DELETE_SUCCESS,
    data,
  });
  
  export const savePlaceCategoryDeleteSuccess = () => ({
    type: SAVE_PLACE_CATEGORY_DELETE_SUCCESS,
  });
  
  export const getPlacesList = (pageIndex, pageSize, searchKey) => ({
    type: GET_PLACE_LIST,
    pageIndex, pageSize, searchKey
  });
  
  export const savePlacesList = (data) => ({
    type: SAVE_PLACE_LIST,
    data,
  });
  
  export const getPlaceById = (id) => ({
    type: GET_PLACE_BY_ID,
    id,
  });
  
  export const savePlaceById = (data) => ({
    type: SAVE_PLACE_BY_ID,
    data,
  });
  
  export const clearPlaceData = () => ({
    type: CLEAR_PLACE_DATA_REQUEST,
  });
  
  export const clearPlaceDataSuccess = () => ({
    type: CLEAR_PLACE_DATA_SUCCESS,
  });

  export const savePlaceList = (data) => ({
    type: SAVE_PLACES_LIST,
    data
  })

  export const getPlaceDetailsById = (placeId) => ({
    type: GET_PLACE_DETAILS,
    placeId 
  })

  export const savePlaceDetails = (data) => ({
    type:SAVE_PLACE_DETAILS,
    data
  })

  export const updateThePlaceDetails = (data, navigate) => ({
    type:UPDATE_PLACE_DETAILS,
    data,
    navigate
  })