// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable/serverTable";
import { map as _map } from "lodash";
import { get as _get } from "lodash";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Divider,
} from "@mui/material";

import MDButton from "../../components/MDButton";

import CloseIcon from "@mui/icons-material/Close";
import {
  getHoodById,
  getPlacesById,
  deleteUserById,
  getUserFollowerById,
} from "../../actions/user";
import pxToRem from "../../../src/assets/theme/functions/pxToRem";
import { useState } from "react";
import MDAvatar from "../../components/MDAvatar";
import { CLEAR_USER_FOLLOWER_LIST } from "../../types/action-type";
// Data

const UsersList = () => {
  const navigate = useNavigate();

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "user.list.records", []),
      hoodList: _get(state, "user.hoodList.data", []),
      placeList: _get(state, "user.placeList.data", []),
      followerList: _get(state, "user.followerList.data", []),
      totalRecords: _get(state, "user.list.totalRecord", 0),
      pageIndex: _get(state, "user.list.pageIndex", 0),
      pageSize: _get(state, "user.list.pageSize", 30),
    };
  });
  const UserDetails = ({ firstName, lastName, image }) => {
    return (
      <MDBox display="flex" alignItems="left">
        <MDBox ml={0}>
          <MDTypography
            color="text"
            display="block"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            fontSize="14px"
          >
            {firstName + " " + lastName}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };
  const tableData = {
    columns: [
      {
        Header: "name",
        accessor: (row) => _get(row, "firstName") + _get(row, "lastName"),
        Cell: (row) => (
          <UserDetails
            variant="caption"
            color="text"
            fontWeight="regular"
            fontSize="14px"
            firstName={_get(row, "row.original.firstName")}
            lastName={_get(row, "row.original.lastName")}
          />
        ),
        align: "left",
      },
      {
        Header: "email",
        accessor: "email",
        Cell: (row) => (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="regular"
            fontSize="14px"
          >
            {_get(row, "row.original.email")}
          </MDTypography>
        ),
        align: "left",
      },
      {
        Header: "mobile No",
        accessor: "mobileNo",
        Cell: (row) => (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="regular"
            fontSize="14px"
          >
            {_get(row, "row.original.dialCode") +
              _get(row, "row.original.mobileNo")}
          </MDTypography>
        ),
        align: "left",
      },
      {
        Header: "birthdate",
        accessor: "birthDate",
        Cell: (row) => (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="regular"
            fontSize="14px"
          >
            {_get(row, "row.original.birthDate").substring(0, 10)}
          </MDTypography>
        ),
        align: "left",
      },
      {
        Header: "Followers",
        accessor: "Followers",
        Cell: (row) => (
          <Tooltip title="View Followers" placement="bottom">
            <MDTypography
              variant="caption"
              color="orange"
              fontWeight="medium"
              fontSize="15px"
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickUser(_get(row, "row.original._id"))}
            >
              {_get(row, "row.original.followersCount") || 0}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "Hoods",
        accessor: "totalHoodCreated",
        Cell: (row) => (
          <Tooltip title="View hoods" placement="bottom">
            <MDTypography
              variant="caption"
              color="orange"
              fontWeight="medium"
              fontSize="15px"
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickHood(_get(row, "row.original._id"))}
            >
              {_get(row, "row.original.totalHoodCreated")}
            </MDTypography>
          </Tooltip>
        ),
        align: "center",
      },
      {
        Header: "Places",
        accessor: "totalPlacesAdded",
        Cell: (row) => (
          <Tooltip title="View places" placement="bottom">
            <MDTypography
              variant="caption"
              color="orange"
              fontWeight="medium"
              fontSize="15px"
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickPlaces(_get(row, "row.original._id"))}
            >
              {_get(row, "row.original.totalPlacesAdded")}
            </MDTypography>
          </Tooltip>
        ),
        align: "center",
      },
      {
        Header: "created Date",
        accessor: "createdAt",
        Cell: (row) => (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="regular"
            fontSize="14px"
          >
            {_get(row, "row.original.createdAt").substring(0, 10)}
          </MDTypography>
        ),
        align: "left",
      },
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <>
            <Tooltip title="View" placement="bottom" arrow={true}>
              <IconButton
                sx={{
                  color: "#F05A22",
                  backgroundColor: "#fde4db",
                  borderRadius: "6px",
                  width: pxToRem(35),
                  height: pxToRem(35),

                  "&:hover": {
                    backgroundColor: "#fbcfbf",
                  },
                }}
                onClick={() =>
                  navigate(`/user/${_get(row, "row.original._id")}/view`)
                }
              >
                <Icon
                  fontSize="small"
                  // onClick={() =>
                  //   navigate(`/user/${_get(row, "row.original._id")}/view`)
                  // }
                  sx={{ cursor: "pointer" }}
                >
                  remove_red_eye_icon
                </Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom" arrow={true}>
              <IconButton
                sx={{
                  color: "#F05A22",
                  backgroundColor: "#fde4db",
                  borderRadius: "6px",
                  width: pxToRem(35),
                  height: pxToRem(35),
                  ml: 2,
                  "&:hover": {
                    backgroundColor: "#fbcfbf",
                  },
                }}
                onClick={() =>
                  handleDeleteClickOpen(_get(row, "row.original._id"))
                }
              >
                <Icon
                  fontSize="small"
                  // onClick={() => handleDeleteClickOpen(_get(row, "row.original._id"))}
                  sx={{ cursor: "pointer" }}
                >
                  delete_icon
                </Icon>
              </IconButton>
            </Tooltip>
          </>
        ),
        align: "center",
        width: "15%",
      },
    ],
    rows: _get(storeProps, "data", []),
    totalRecords: _get(storeProps, "totalRecords", 0),
    pageIndex: _get(storeProps, "pageIndex", 0),
    pageSize: _get(storeProps, "pageSize", 30),
  };
  const [userId, setUserId] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [userIdPlaces, setUserIdPlaces] = useState();
  const [showDialogPlaces, setShowDialogPlaces] = useState(false);
  const [showUserDialog, setShowUserDialog] = useState(false);
  const [userIdFollowers, setUserIdFollowers] = useState();
  const [deletedUserId, setDeletedUserId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleClickHood = (userId) => {
    dispatch(getHoodById(userId));
    setUserId(userId);
    setShowDialog(true);
  };
  const handleClickUser = (userId) => {
    dispatch({ type: CLEAR_USER_FOLLOWER_LIST });
    setUserIdFollowers(null);
    setShowUserDialog(false);

    setTimeout(() => {
      dispatch(getUserFollowerById(userId));
      setUserIdFollowers(userId);
      setShowUserDialog(true);
    }, 5);
  };
  const handleClickPlaces = (userId) => {
    dispatch(getPlacesById(userId));
    setUserIdPlaces(userId);
    setShowDialogPlaces(true);
  };
  const handleDeleteClickOpen = (userId) => {
    setDeletedUserId(userId);
    setDeleteDialog(true);
  };
  const deleteUser = (userId) => {
    setDeletedUserId(userId);
    if (deletedUserId) {
      dispatch(deleteUserById(deletedUserId));
      setDeleteDialog(false);
    }
    // dispatch(deleteUserById(deletedUserId))
    // setDeleteDialog(false)
  };
  const handleDeleteClose = () => {
    setUserId("");
    setDeleteDialog(false);
  };

  const dispatch = useDispatch();
  const { columns, rows, totalRecords, pageIndex, pageSize } = tableData;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                mb={-8}
                py={2}
                px={2}
                variant="contained"
                bgColor="rgba(0, 0, 0, 0.0)"
                borderRadius="lg"
                zIndex="1"
                width="calc(100% - 250px)"
              >
                <MDTypography variant="h5" color="black" pt={1.1}>
                  App Users List
                </MDTypography>
              </MDBox>
              <MDBox pt={0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  // entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                  totalRecords={totalRecords}
                  pageIndex1={pageIndex}
                  pageSize1={pageSize}
                  tableFor="appUserList"
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {showDialog && userId !== null ? (
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              View Hoods
              <IconButton
                aria-label="close"
                onClick={() => setShowDialog(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[700],
                  backgroundColor: (theme) => theme.palette.grey[200],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
            <DialogContent>
              <DialogContentText sx={{ maxHeight: "400px" }}>
                {storeProps.hoodList.length > 0 ? (
                  _map(storeProps.hoodList, (hood, idex) => {
                    return (
                      <MDBox key={idex} p={0}>
                        <MDBox
                          key={idex}
                          p={1}
                          mb={1.5}
                          sx={({
                            palette: { grey },
                            borders: { borderWidth },
                          }) => ({
                            border: `${borderWidth[1]} solid ${grey[200]}`,
                            borderRadius: "8px",
                          })}
                        >
                          <MDBox
                            key={idex}
                            p={0}
                            display="flex"
                            alignItems="center"
                          >
                            {_map(hood.images, (item) => (
                              <MDAvatar
                                src={
                                  item !== ""
                                    ? item
                                    : "http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"
                                }
                                sx={{
                                  height: "auto",
                                  width: "auto",
                                  maxWidth: "50px",
                                  objectFit: "cover",
                                  borderRadius: "6px",
                                  marginRight: "10px",
                                }}
                              ></MDAvatar>
                            ))}

                            <MDTypography
                              variant="text"
                              sx={{ color: (theme) => theme.palette.grey[600] }}
                            >
                              {hood.name}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    );
                  })
                ) : (
                  <MDBox
                    p={1}
                    mb={1.5}
                    sx={({ palette: { grey }, borders: { borderWidth } }) => ({
                      border: `${borderWidth[1]} solid ${grey[200]}`,
                      borderRadius: "8px",
                    })}
                  >
                    <MDTypography
                      variant="text"
                      sx={{ color: (theme) => theme.palette.grey[600] }}
                    >
                      No Hoods To Display
                    </MDTypography>
                  </MDBox>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => setShowDialog(false)}
                variant="outlined"
                color="error"
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        ) : null}
        {showUserDialog && userIdFollowers !== null ? (
          <Dialog
            open={showUserDialog}
            onClose={() => setShowUserDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              View Followers
              <IconButton
                aria-label="close"
                onClick={() => setShowUserDialog(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[700],
                  backgroundColor: (theme) => theme.palette.grey[200],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
            <DialogContent>
              <DialogContentText sx={{ maxHeight: "400px" }}>
                {storeProps.followerList.followers?.length > 0 ? (
                  storeProps.followerList.followers.map((follower, index) => {
                    return (
                      <MDBox key={index} p={0}>
                        <MDBox
                          p={1}
                          mb={1.5}
                          sx={({
                            palette: { grey },
                            borders: { borderWidth },
                          }) => ({
                            border: `${borderWidth[1]} solid ${grey[200]}`,
                            borderRadius: "8px",
                          })}
                        >
                          <MDBox p={0} display="flex" alignItems="center">
                            <MDAvatar
                              src={
                                follower.profilePicture
                                  ? follower.profilePicture
                                  : "http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"
                              }
                              sx={{
                                height: "auto",
                                width: "auto",
                                maxWidth: "50px",
                                objectFit: "cover",
                                borderRadius: "6px",
                                marginRight: "10px",
                              }}
                            />
                            <MDTypography
                              variant="text"
                              sx={{ color: (theme) => theme.palette.grey[600] }}
                            >
                              {follower.firstName} {follower.lastName}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    );
                  })
                ) : (
                  <MDBox
                    p={1}
                    mb={1.5}
                    sx={({ palette: { grey }, borders: { borderWidth } }) => ({
                      border: `${borderWidth[1]} solid ${grey[200]}`,
                      borderRadius: "8px",
                    })}
                  >
                    <MDTypography
                      variant="text"
                      sx={{ color: (theme) => theme.palette.grey[600] }}
                    >
                      No Users To Display
                    </MDTypography>
                  </MDBox>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => setShowUserDialog(false)}
                variant="outlined"
                color="error"
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        ) : null}
        {showDialogPlaces && userIdPlaces !== null ? (
          <Dialog
            open={showDialogPlaces}
            onClose={() => setShowDialogPlaces(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              View Places
              <IconButton
                aria-label="close"
                onClick={() => setShowDialogPlaces(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[700],
                  backgroundColor: (theme) => theme.palette.grey[200],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
            <DialogContent>
              <DialogContentText sx={{ maxHeight: "400px" }}>
                {storeProps.placeList.length > 0 ? (
                  _map(storeProps.placeList, (places, idex) => {
                    return (
                      <MDBox key={idex} p={0}>
                        <MDBox
                          key={idex}
                          p={1}
                          mb={1.5}
                          sx={({
                            palette: { grey },
                            borders: { borderWidth },
                          }) => ({
                            border: `${borderWidth[1]} solid ${grey[200]}`,
                            borderRadius: "8px",
                          })}
                        >
                          <MDBox
                            key={idex}
                            p={0}
                            display="flex"
                            alignItems="center"
                          >
                            {places.images.length > 0 ? (
                              _map(places.images, (item) => {
                                return (
                                  <MDAvatar
                                    src={item.url}
                                    sx={{
                                      height: "auto",
                                      width: "auto",
                                      maxWidth: "50px",
                                      objectFit: "cover",
                                      borderRadius: "6px",
                                      marginRight: "10px",
                                    }}
                                  ></MDAvatar>
                                );
                              })
                            ) : (
                              <MDAvatar
                                src={
                                  "http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"
                                }
                                sx={{
                                  height: "auto",
                                  width: "auto",
                                  maxWidth: "50px",
                                  objectFit: "cover",
                                  borderRadius: "6px",
                                  marginRight: "10px",
                                }}
                              ></MDAvatar>
                            )}
                            <MDTypography
                              variant="text"
                              sx={{ color: (theme) => theme.palette.grey[600] }}
                            >
                              {places.name}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    );
                  })
                ) : (
                  <MDBox
                    p={1}
                    mb={1.5}
                    sx={({ palette: { grey }, borders: { borderWidth } }) => ({
                      border: `${borderWidth[1]} solid ${grey[200]}`,
                      borderRadius: "8px",
                    })}
                  >
                    <MDTypography
                      variant="text"
                      sx={{ color: (theme) => theme.palette.grey[600] }}
                    >
                      No Places To Display
                    </MDTypography>
                  </MDBox>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => setShowDialogPlaces(false)}
                variant="outlined"
                color="error"
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        ) : null}

        {deleteDialog && deletedUserId !== null ? (
          <Dialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Delete User</DialogTitle>
            <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
            <DialogContent>
              <DialogContentText sx={{ maxHeight: "400px" }}>
                Are you sure you want to delete this user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => handleDeleteClose()}
                variant="outlined"
                color="error"
              >
                Cancel
              </MDButton>
              <MDButton
                onClick={() => deleteUser(deletedUserId)}
                variant="outlined"
                color="error"
              >
                Delete
              </MDButton>
            </DialogActions>
          </Dialog>
        ) : null}
      </MDBox>
    </DashboardLayout>
  );
};

export default UsersList;
