// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

import { makeStyles } from '@material-ui/core/styles';

// Data
import cmsTableData from "./data/cmsTableData";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "30px",
    // boxShadow: '0 8px 16px 0 #BDC9D7',    
    boxShadow: '0 8px 16px 0 #000',    
  },
  borderzero: {
    borderRadius: "0px",
    backgroundColor: "#000",
  }
}));
const CmsList = () => {
  const styles = useStyles();
  const { columns, rows } = cmsTableData();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card className={styles.card} > */}
            <Card>
            
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                mb={0}
                variant="contained"
                bgColor='rgba(0, 0, 0, 0.0)'
                borderRadius="lg"
                zIndex="1"
                width="calc(100% - 250px)"
                // coloredShadow="info"
              >
                <MDTypography variant="h5" color="black">
                  CMS List
                </MDTypography>
              </MDBox>

              {rows.length > 0 ? (
                <MDBox pt={0} >
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    
                   
                  />
                </MDBox>
              ) : (
                <MDBox textAlign={"center"} p={10}>
                  Loading...
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default CmsList;
