import {
  GET_MIA_BESTFITS_LIST,
  SAVE_MIA_BESTFITS_LIST,
  GET_MIA_BESTFIT_STATUS_UPDATE_REQUEST,
  SAVE_MIA_BESTFIT_STATUS_UPDATE_RESPONSE,
  GET_ADD_MIA_BESTFIT_REQUEST,
  SAVE_ADD_MIA_BESTFIT_RESPONSE,
  GET_MIA_BESTFIT_BY_ID,
  SAVE_MIA_BESTFIT_BY_ID,
  GET_MIA_BESTFIT_DELETE_REQUEST,
  SAVE_MIA_BESTFIT_DELETE_SUCCESS,
  GET_MIA_BESTFIT_UPDATE_REQUEST,
  SAVE_MIA_BESTFIT_UPDATE_RESPONSE,
} from "../types/action-type";

export const getMiaBestfitList = () => ({
  type: GET_MIA_BESTFITS_LIST,
});

export const saveMiaBestfitList = (data) => ({
  type: SAVE_MIA_BESTFITS_LIST,
  data,
});

export const updateMiaBestfitStatusReq = (data) => ({
  type: GET_MIA_BESTFIT_STATUS_UPDATE_REQUEST,
  data,
});

export const updateMiaBestfitStatusSuccess = () => ({
  type: SAVE_MIA_BESTFIT_STATUS_UPDATE_RESPONSE,
});

export const addMiaBestfitReq = (data) => ({
  type: GET_ADD_MIA_BESTFIT_REQUEST,
  data,
});

export const addMiaBestfitSuccess = () => ({
  type: SAVE_ADD_MIA_BESTFIT_RESPONSE,
});

export const getMiaBestfitById = (id) => ({
  type: GET_MIA_BESTFIT_BY_ID,
  id,
});

export const saveMiaBestfitById = (data) => ({
  type: SAVE_MIA_BESTFIT_BY_ID,
  data,
});

export const deleteMiaBestfitReq = (data) => ({
  type: GET_MIA_BESTFIT_DELETE_REQUEST,
  data,
});

export const deleteMiaBestfitSuccess = () => ({
  type: SAVE_MIA_BESTFIT_DELETE_SUCCESS,
});

export const updateMiaBestfitReq = (data) => ({
  type: GET_MIA_BESTFIT_UPDATE_REQUEST,
  data,
});

export const updateMiaBestfitSuccess = () => ({
  type: SAVE_MIA_BESTFIT_UPDATE_RESPONSE,
});
