// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { get as _get } from "lodash";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MDAvatar from "../../../components/MDAvatar";
import IconButton from '@mui/material/IconButton';
import pxToRem from "../../../../src/assets/theme/functions/pxToRem";
import { Tooltip } from "@mui/material";

export default function Data() {
  const navigate = useNavigate();

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "country.list.records", []),
      totalRecords: _get(state, "country.list.totalRecord", 0),
      pageIndex: _get(state, "country.list.pageIndex", 0),
      pageSize: _get(state, "country.list.pageSize", 10),
    };
  });

  const CountryDetails = ({ image, name }) => {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar  src={image} name={name} sx={{ height: "auto", width: "auto", maxWidth: "50px",  objectFit: "cover", borderRadius: "6px" }} />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="regular">
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };

  return {
    columns: [
      {
        Header: "country",
        accessor: (row) => _get(row, "name"),
        Cell: (row) => (
          <CountryDetails variant="caption" color="text" fontWeight="regular" fontSize="14px"
            image={_get(row, "row.original.flag")}
            name={_get(row, "row.original.name")}
          />
        ),
        align: "left",
        width: "5%",
      },
      {
        Header: "language",
        accessor: (row) => console.log("check row :", _get(row,"label[0].name")),
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.label[0].name")}
          </MDTypography>
        ),
        align: "left",
      },
      {
        Header: "dialCode",
        accessor: "dialCode",
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.dialCode")}
          </MDTypography>
        ),
        align: "left",
      },
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <Tooltip title="view" placement="bottom" arrow={true}>
            <IconButton sx={{ 
              color: "#F05A22", 
              backgroundColor: "#fde4db", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              cursor: "pointer", ml: 2,
              '&:hover' :{
                backgroundColor: "#fbcfbf"
              }
            }}>
            <Icon
              fontSize="small"
              onClick={() =>
                navigate(`/country/${_get(row, "row.original._id")}/view`)
              }
              sx={{ cursor: "pointer" }}
            >
              remove_red_eye_icon
            </Icon>
            </IconButton>
          </Tooltip>
        ),
        align: "center",
        width: "5%",
      },
    ],
    rows: _get(storeProps, "data", []),
    totalRecords: _get(storeProps, "totalRecords", 0),
    pageIndex: _get(storeProps, "pageIndex", 0),
    pageSize: _get(storeProps, "pageSize", 10),
  };
}
