import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import { Box } from "@mui/system";
import { Field, FieldArray, Form, Formik } from "formik";
import { get as _get, isEqual as _isEqual } from "lodash";
import MDButton from "../../components/MDButton";
import { Avatar, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  clearCountry,
  getCountryById,
  setCountryFormData,
} from "../../actions/country";
import MDInput from "../../components/MDInput";
import * as Yup from "yup";
import { renderTextField } from "../../inputFields/formFields";
import pxToRem from "../../../src/assets/theme/functions/pxToRem";

const Country = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const countryView = _isEqual(_get(params, "action", ""), "view");
  console.log("countryView", countryView, "params", params, "params.action")
  const countryEdit = _isEqual(_get(params, "action", ""), "edit");
  useEffect(() => {
    (countryView || countryEdit) && dispatch(getCountryById(params.id));
  }, [countryView, countryEdit, params, dispatch]);

  const storeProps = useSelector((state) => {
    const initLabel = [
      {
        local: "",
        name: "",
        nationality: "",
      },
    ];
    return {
      label: _get(state, "country.countryData.label", initLabel),
      code: _get(state, "country.countryData.code", ""),
      dialCode: _get(state, "country.countryData.dialCode", ""),
      flag: _get(state, "country.countryData.flag", null),
    };
  });

  useEffect(() => {
    return () => {
      (countryView || countryEdit) && dispatch(clearCountry());
    };
  }, [countryView, countryEdit, params, dispatch]);

  const CountryValidateSchema = Yup.object().shape({
    label: Yup.array().of(
      Yup.object().shape({
        local: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        nationality: Yup.string().required("Required"),
      })
    ),

    code: Yup.string().required("Required"),
    dialCode: Yup.string().required("Required"),
    flag: Yup.string().required("Required"),
  });

  const saveCountry = (values) => {
    let formdata = new FormData();
    formdata.append(
      "file",
      _get(values, "flag[0]"),
      _get(values, "flag[0].name")
    );
    formdata.append("label", JSON.stringify(_get(values, "label")));
    formdata.append("dialCode", _get(values, "dialCode"));
    formdata.append("code", _get(values, "code"));
    formdata.append("status", _get(values, "status"));

    dispatch(setCountryFormData(formdata, navigate));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              
              <Formik
                initialValues={storeProps}
                validationSchema={CountryValidateSchema}
                enableReinitialize
                onSubmit={async (values) => saveCountry(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleReset,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <MDBox
                        mx={0}
                        mt={0}
                        py={3}
                        px={2}
                        variant="contained"
                        bgColor="transperent"
                        borderRadius="lg"
                        // coloredShadow="info"
                      >
                        <MDTypography variant="h5" color="black">
                          {countryView
                            ? "View Country"
                            : countryEdit
                            ? "Edit Country"
                            : "Add Country"}
                        </MDTypography>
                      </MDBox>
                      <Grid
                        container
                        spacing={2}
                        px={2}
                        justifyContent="flex-end"
                      >
                        <FieldArray name="label">
                          {({ insert, remove, push }) => (
                            <>
                              {!countryView && values.label.length < 2 && (
                                <MDBox display="flex" justifyContent="flex-end" width="100%" mt={-6}>
                                  <MDButton
                                    variant="outlined"
                                    color="orange"
                                    padding="10px"
                                    onClick={() =>
                                      push({
                                        local: "",
                                        name: "",
                                        nationality: "",
                                      })
                                    } 
                                    size="tiny"
                                   
                                  >
                                    Add language
                                  </MDButton>
                                </MDBox>
                              )}
                              {values.label.map((lab, index) => (
                                <React.Fragment key={index}>
                                  <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%" pl={2} >
                                    <MDBox width="calc(100% - 60px)">
                                      <Grid container spacing={2} pl={0} pr={0}>
                                      <Grid item xs={12} md={4}>
                                        <Field
                                          name={`label.${index}.local`}
                                          component={renderTextField}
                                          label="Language"
                                          fullWidth
                                          InputProps={{
                                            readOnly: countryView
                                         }}
                                        //  disabled={countryView}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field
                                          name={`label.${index}.name`}
                                          component={renderTextField}
                                          label="Name"
                                          fullWidth
                                          InputProps={{
                                            readOnly: countryView
                                         }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field
                                          name={`label.${index}.nationality`}
                                          component={renderTextField}
                                          label="Nationality"
                                          fullWidth
                                          InputProps={{
                                            readOnly: countryView
                                         }}
                                        />
                                      </Grid>
                                      </Grid>
                                    </MDBox>
                                    <MDBox width="45px" pt={1}>
                                      
                                        <IconButton
                                          size="small"
                                          disabled={countryView}
                                          onClick={() => remove(index)}
                                          color="error"
                                          sx={{ 
                                            backgroundColor: "#fde4db !important", 
                                            borderRadius: "6px" ,
                                            width: pxToRem(42),
                                            height: pxToRem(42),
                                            cursor: "pointer", 
                                            '&:hover' :{
                                              backgroundColor: "#fbcfbf !important"
                                            },
                                            '&.Mui-disabled' : {
                                              color: "#F44335 !important",
                                              opacity: "0.5",
                                            }
                                          }}>
                                          
                                            <DeleteIcon />
                                          
                                        </IconButton>
                                    
                                    </MDBox>
                                    </MDBox>
                                </React.Fragment>
                              ))}
                              
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%" px={2} >
                        <MDBox width="calc(100% - 60px)">
                          <Grid container spacing={2} pl={0} pr={0}>
                            <Grid item xs={12} md={4}>
                              <Field
                                name="code"
                                component={renderTextField}
                                label="Code"
                                fullWidth
                                InputProps={{
                                  readOnly: countryView
                               }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                name="dialCode"
                                component={renderTextField}
                                label="Dial Code"
                                fullWidth
                                InputProps={{
                                  readOnly: countryView
                               }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} mt={!countryView ? 2 : 0}>
                              {countryView ? (
                                <Avatar
                                  alt="flag"
                                  variant="square"
                                  src={_get(storeProps, "flag")}
                                  sx={{ height: "auto", width: "auto", maxWidth: "60px",  objectFit: "cover", borderRadius: "6px" , marginTop: "18px"}}
                                />
                              ) : (
                                <Field name="flag">
                                  {(form, meta, value) => (
                                    <MDInput
                                      fullWidth
                                      id="flag"
                                      name="flag"
                                      type="file"
                                      disabled={countryView}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "flag",
                                          event.currentTarget.files
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              )}
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox width="45px"></MDBox>
                      </MDBox>
                      <Box p={2} textAlign="right">
                        {!countryView && (
                          <MDButton
                            variant="outlined"
                            color="success"
                            type="submit"
                            sx={{ marginRight: "15px" }}
                          >
                            Save
                          </MDButton>
                        )}
                        <MDButton
                          variant="outlined"
                          color="error"
                          onClick={() => navigate("/country")}
                        >
                          Cancel
                        </MDButton>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Country;
