import {
  SAVE_MIA_INTEREST_BY_ID,
  SAVE_MIA_INTEREST_LIST,
} from "../types/action-type";

const initialState = {
  list: [],
  interestData: {},
};

export default function miaInterestReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_MIA_INTEREST_LIST:
      return {
        ...state,
        list: action.data,
      };
    case SAVE_MIA_INTEREST_BY_ID:
      return {
        ...state,
        interestData: action.data,
      };
    default:
      return state;
  }
}
