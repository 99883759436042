import { GET_DELETED_USERS_LIST,SAVE_DELETED_USERS_LIST} from "../types/action-type";
import _get from "lodash/get";
import { saveDeletedUsersList } from "../actions/deleteUser";
import { takeLatest, call, put } from "redux-saga/effects";
import mias from "../api/index";

function* getDeletedUsersList({pageIndex, pageSize, searchKey}) {
    try {
        const response = yield call(mias.apis.deletedUsers.deletedUserList, pageIndex, pageSize, searchKey);
        response.data.data.pageIndex = pageIndex;
        response.data.data.pageSize = pageSize;
        yield put(saveDeletedUsersList(_get(response, "data.data", [])));
    } catch (error) {
        console.log("error in getDeletedUsersList", error);
    }
}

export default function* deletedUsers() {
    yield takeLatest(GET_DELETED_USERS_LIST, getDeletedUsersList);
    yield takeLatest(SAVE_DELETED_USERS_LIST, saveDeletedUsersList);
}
