import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import { Field, Form, Formik } from "formik";
import { get as _get, isEqual as _isEqual, omit as _omit } from "lodash";
import MDButton from "../../components/MDButton";
import { getRolesList } from "../../actions/roles";
import {
  clearAdminData,
  createAdmin,
  getAdminUserById,
  updateAdminUser,
} from "../../actions/adminUser";
import {
  renderAutoComplete,
  renderTextField,
} from "../../inputFields/formFields";
import moment from "moment";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { showError } from "../../utils";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const appUserView = _isEqual(_get(params, "action", ""), "view");
  const appUserEdit = _isEqual(_get(params, "action", ""), "edit");

  useEffect(() => {
    (appUserView || appUserEdit) && dispatch(getAdminUserById(params.id));
    dispatch(getRolesList());
  }, [appUserView, params, dispatch, appUserEdit]);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    //dialCode: "",
   // mobileNo: "",
   // birthDate: "",
    roleById: { name: "", _id: "" },
    userPermissions: {"appUser":{"create":true,"view":true},"adminUser":{"create":true,"view":true},"hoods":{"create":true},"cms":{"create":true},"country":{"create":true,"view":true},"settings":{"view":true,"create":true}}
  };

  const storeProps = useSelector((state) => {
    return {
      firstName: _get(state, "auth.adminData.firstName", ""),
      lastName: _get(state, "auth.adminData.lastName", ""),
      email: _get(state, "auth.adminData.email", ""),
      // dialCode: _get(state, "auth.adminData.dialCode", ""),
      // mobileNo: _get(state, "auth.adminData.mobileNo", ""),
    //  birthDate: _get(state, "auth.adminData.birthDate", ""),
      rolesList: _get(state, "roles.list", []),
      roleById: _get(state, "auth.adminData.roleById", initialValues.roleById),
    };
  });
  const rolesList=storeProps.rolesList.filter((item)=>item.name!=="Admin")
  console.warn("rolesList",rolesList)
  const saveAdmin = (values) => {
    values.role_id = values.roleById._id;
    values = _omit(values, ["rolesList", "roleById"]);
    if (appUserEdit) {
      values = _omit(values, ["password"]);
      values.id = params.id;
      dispatch(updateAdminUser(values, navigate));
    } else {
      dispatch(createAdmin(values, navigate));
    }
  };

  useEffect(() => {
    return () => {
      appUserView && dispatch(clearAdminData());
    };
  }, [appUserView, dispatch]);

  const schema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password:
      !appUserView &&
      !appUserEdit &&
      Yup.string().min(6, "Min 6 Characters Required!").required("Required"),
 
  });
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                mb={0}
                variant="contained"
                bgColor='rgba(0, 0, 0, 0.0)'
                borderRadius="lg"
                // coloredShadow="info"
              >
                <MDTypography variant="h5" color="black">
                  {appUserView
                    ? "View Admin"
                    : appUserEdit
                    ? "Edit Admin"
                    : "Add Admin"}
                </MDTypography>
              </MDBox>
              <Formik
                initialValues={
                  appUserView || appUserEdit ? storeProps : initialValues
                }
                enableReinitialize
                validationSchema={schema}
                onSubmit={async (values) => saveAdmin(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleReset,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2} pl={2} pr={2}>
                        <Grid item xs={12} md={6}>
                          <Field
                            name="firstName"
                            component={renderTextField}
                            label="First Name"
                            fullWidth
                            disabled={appUserView}
                            color="secondary"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            name="lastName"
                            component={renderTextField}
                            label="Last Name"
                            fullWidth
                            disabled={appUserView}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            name="email"
                            component={renderTextField}
                            label="Email"
                            fullWidth
                            type="email"
                            disabled={appUserView}
                            m={0}
                          />
                        </Grid>
                        {!appUserView && !appUserEdit && (
                          <Grid item xs={12} md={6}>
                            <Field
                              name="password"
                              component={renderTextField}
                              label="Password"
                              fullWidth
                              type="password"
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6} mt={2}>
                          <Field
                            name="roleById"
                            label="Role"
                            component={renderAutoComplete}
                            getOptionLabel={(option) => option.name}
                            menuoptionlist={rolesList}
                            disabled={appUserView}
                            fullWidth
                            displayLabel="name"
                            size="small"
                            sx= { "Height: '15rem'" }
                            
                          />
                          {errors?.role_id &&
                            touched?.roleById &&
                            showError(errors.role_id)}
                        </Grid>
                      </Grid>
                      <MDBox p={2}  pt={4} textAlign="right">
                        {!appUserView && (
                          <MDButton
                            variant="outlined"
                            color="success"
                            type="submit"
                            sx={{ marginRight: "15px" }}
                          >
                            Save
                          </MDButton>
                        )}
                        <MDButton
                          variant="outlined"
                          color="error"
                          onClick={() => navigate("/admins")}
                        >
                          Cancel
                        </MDButton>
                      </MDBox>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Admin;
