// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable/serverTable";

// Data
import adminsTableData from "./data/adminsTableData";
import { useNavigate } from "react-router-dom";
import MDButton from "../../components/MDButton";

const AdminsList = () => {
  const { columns, rows, totalRecords, pageIndex, pageSize } = adminsTableData();
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card >
             
              <MDBox
                mx={0}
                mt={0}
                py={2}
                px={2}               
                mb={0}
                variant="contained"
                bgColor='rgba(0, 0, 0, 0.0)'
                borderRadius="lg"
                position="relative"
                // coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                zIndex="1"
                width="calc(100% - 250px)"
              >
                <MDTypography variant="h5" color="black">
                  Admins List
                </MDTypography>

                <MDButton
                  size="medium"
                  variant="outlined"
                  color="orange"
                  onClick={() => navigate("/admin/add")}
                >
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    add_icon
                  </Icon>
                  &nbsp;Add Admin
                </MDButton>
              </MDBox>
                
              <MDBox pt={3} mt={-12.5}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  // showTotalEntries={false}
                  noEndBorder
                  canSearch
                  tableFor="adminList"
                  totalRecords={totalRecords}
                  pageIndex1={pageIndex}
                  pageSize1={pageSize}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminsList;
