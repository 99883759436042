import { call, put, takeLatest } from "redux-saga/effects";
import { 
  clearHoodDataSuccess,
  clearPostListSuccess,
  saveHoodById,
  saveHoodCategoryAddSuccess,
  saveHoodCategoryById, 
  saveHoodCategoryDeleteSuccess, 
  saveHoodCategoryList, 
  saveHoodCategoryStatusUpdateSuccess, 
  saveHoodCategoryUpdateSuccess, 
  saveHoodRuleAddSuccess, 
  saveHoodRuleById, 
  saveHoodRuleDeleteSuccess, 
  saveHoodRulesList, 
  saveHoodRuleStatusUpdateSuccess, 
  saveHoodRuleUpdateSuccess, 
  saveHoodsList, 
  saveHoodTagAddSuccess, 
  saveHoodTagById, 
  saveHoodTagCategoryAddSuccess, 
  saveHoodTagCategoryById, 
  saveHoodTagCategoryDeleteSuccess, 
  saveHoodTagCategoryList, 
  saveHoodTagCategoryStatusUpdateSuccess, 
  saveHoodTagCategoryUpdateSuccess, 
  saveHoodTagDeleteSuccess, 
  saveHoodTagsList, 
  saveHoodTagUpdateSuccess, 
  savePostListByHoodId,
  saveHoodCategoryListForDropdown
} from "../actions/hoods";
import {
  GET_HOOD_CATEGORY_ADD_REQUEST,
  GET_HOOD_CATEGORY_BY_ID,
  GET_HOOD_CATEGORY_LIST,
  GET_HOOD_CATEGORY_UPDATE_REQUEST,
  GET_HOOD_RULE_ADD_REQUEST,
  GET_HOOD_RULE_BY_ID,
  GET_HOOD_RULE_UPDATE_REQUEST,
  GET_HOOD_TAGS_LIST,
  GET_HOOD_TAG_ADD_REQUEST,
  GET_HOOD_TAG_BY_ID,
  GET_HOOD_TAG_CATEGORY_ADD_REQUEST,
  GET_HOOD_TAG_CATEGORY_BY_ID,
  GET_HOOD_TAG_CATEGORY_LIST,
  GET_HOOD_TAG_CATEGORY_UPDATE_REQUEST,
  GET_HOOD_TAG_UPDATE_REQUEST,
  GET_HOOD_CATEGORY_STATUS_UPDATE_REQUEST,
  GET_HOOD_RULE_STATUS_UPDATE_REQUEST,
  GET_HOOD_TAG_CATEGORY_STATUS_UPDATE_REQUEST,
  GET_HOOD_TAG_STATUS_UPDATE_REQUEST,
  SAVE_HOOD_CATEGORY_ADD_SUCCESS,
  SAVE_HOOD_CATEGORY_BY_ID,
  SAVE_HOOD_CATEGORY_LIST,
  SAVE_HOOD_CATEGORY_UPDATE_SUCCESS,
  SAVE_HOOD_RULE_ADD_SUCCESS,
  SAVE_HOOD_RULE_BY_ID,
  SAVE_HOOD_RULE_UPDATE_SUCCESS,
  SAVE_HOOD_TAGS_LIST,
  SAVE_HOOD_TAG_ADD_SUCCESS,
  SAVE_HOOD_TAG_BY_ID,
  SAVE_HOOD_TAG_CATEGORY_ADD_SUCCESS,
  SAVE_HOOD_TAG_CATEGORY_BY_ID,
  SAVE_HOOD_TAG_CATEGORY_LIST,
  SAVE_HOOD_TAG_UPDATE_SUCCESS,
  SAVE_HOOD_CATEGORY_STATUS_UPDATE_SUCCESS,
  SAVE_HOOD_RULE_STATUS_UPDATE_SUCCESS,
  SAVE_HOOD_TAG_CATEGORY_STATUS_UPDATE_SUCCESS,
  SAVE_HOOD_TAG_STATUS_UPDATE_SUCCESS,
  GET_HOOD_CATEGORY_DELETE_REQUEST,
  SAVE_HOOD_CATEGORY_DELETE_SUCCESS,
  GET_HOOD_RULE_DLETE_REQUEST,
  SAVE_HOOD_RULE_DLETE_SUCCESS,
  GET_HOOD_TAG_CATEGORY_DELETE_REQUEST,
  SAVE_HOOD_TAG_CATEGORY_DELETE_SUCCESS,
  GET_HOOD_TAG_DELETE_REQUEST,
  SAVE_HOOD_TAG_DELETE_SUCCESS,
  GET_HOODS_LIST,
  SAVE_HOODS_LIST,
  GET_HOOD_BY_ID,
  SAVE_HOOD_BY_ID,
  GET_POSTS_LIST_BY_HOOD_ID,
  SAVE_POSTS_LIST_BY_HOOD_ID,
  CLEAR_HOOD_DATA_REQUEST,
  CLEAR_HOOD_DATA_SUCCESS,
  CLEAR_POST_LIST_REQUEST,
  CLEAR_POST_LIST_SUCCESS,
  UPDATE_HOODS_DETAILS,
  GET_HOOD_CATEGORY_LIST_FOR_DROPDOWN,
  SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN,
} from "../types/action-type";
import { get as _get } from "lodash";
import mias from "../api";
import toast from "react-hot-toast";

function* getHoodCategoryList() {
  try {
    const response = yield call(mias.apis.hoods.getHoodCategoryList);
    yield put(saveHoodCategoryList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodCategoryList saga error ::", error);
  }
}
function* getHoodCategoryListForDropdown() {
  try {
    const response = yield call(mias.apis.hoods.getHoodCategoryListForDropdown);
    yield put(saveHoodCategoryListForDropdown(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodCategoryListdropdown saga error ::", error);
  }
}


function* getHoodRulesList() {
  try {
    const response = yield call(mias.apis.hoods.getHoodRulesList);
    yield put(saveHoodRulesList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    
  }
}

function* getHoodCategoryById({id}) {
  try {
    const response = yield call(mias.apis.hoods.getHoodCategoryById, id);
    response.data.data._id = id;
    yield put(saveHoodCategoryById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodCategoryById saga error ::", error);
  }
}

function* getHoodRuleById({id}) {
  try {
    const response = yield call(mias.apis.hoods.getHoodRuleById, id);
    yield put(saveHoodRuleById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodRuleById saga error ::", error);
  }
}

function* updateHoodCategory(data){
  try{
    yield call(mias.apis.hoods.updateHoodCategory, data.data)
    yield put(saveHoodCategoryUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodCategoryList);
    yield put(saveHoodCategoryList(_get(response, "data.data", [])));
    toast.success("Hood Category Updated!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodCategory saga error ::", error)
  }
}

function* updateHoodRule(data){
  try{
    yield call(mias.apis.hoods.updateHoodRule, data.data)
    yield put(saveHoodRuleUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodRulesList);
    yield put(saveHoodRulesList(_get(response, "data.data", [])));
    toast.success("Hood Rule Updated!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodRule saga error ::", error)
  }
}

function* getHoodTagCategoryList() {
  try {
    const response = yield call(mias.apis.hoods.getHoodTagCategoryList);
    yield put(saveHoodTagCategoryList(_get(response, "data.data", [])));
    console.log("getHoodTagCategoryList saga response ::", response);
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodTagCategoryList saga error ::", error);
  }
}

function* getHoodTagList() {
  try {
    const response = yield call(mias.apis.hoods.getHoodTagList);
    console.log("getHoodTagList saga response ::", response);
    yield put(saveHoodTagsList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodTagList saga error ::", error);
  }
}

function* getHoodTagCategoryById({id}) {
  try {
    const response = yield call(mias.apis.hoods.getHoodTagCategoryById, id);
    yield put(saveHoodTagCategoryById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodTagCategoryById saga error ::", error);
  }
}

function* getHoodTagById({id}) {
  try {
    let response = yield call(mias.apis.hoods.getHoodTagById, id);
    console.log("getHoodTagById saga ::", response);
    response.data.data.tagCategory = {
      tagCategory_id: _get(response, "data.data.tagCategory_id", ""),
      tagCategory_name: _get(response, "data.data.tagCategory_name", "")
    }
    yield put(saveHoodTagById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodTagById saga error ::", error);
  }
}

function* updateHoodTagCategory(data){
  try{
    yield call(mias.apis.hoods.updateHoodTagCategory, data.data)
    yield put(saveHoodTagCategoryUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagCategoryList);
    toast.success("Hood Tag Category Updated!")
    yield put(saveHoodTagCategoryList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodTagCategory saga error ::", error)
  }
}

function* updateHoodTag(data){
  try{
    yield call(mias.apis.hoods.updateHoodTag, data.data)
    yield put(saveHoodTagUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagList);
    yield put(saveHoodTagsList(_get(response, "data.data", [])));
    toast.success("Hood Tag Updated!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodTag saga error ::", error)
  }
}

function* createHoodCategory(data){
  try{
    yield call(mias.apis.hoods.createHoodCategory, data.data)
    yield put(saveHoodCategoryAddSuccess())
    const response = yield call(mias.apis.hoods.getHoodCategoryList);
    yield put(saveHoodCategoryList(_get(response, "data.data", [])));
    toast.success("Hood Category Created!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("createHoodCategory saga error ::", error)
  }
}

function* createHoodRule(data){
  try{
    yield call(mias.apis.hoods.createHoodRule, data.data)
    yield put(saveHoodRuleAddSuccess())
    const response = yield call(mias.apis.hoods.getHoodRulesList);
    yield put(saveHoodRulesList(_get(response, "data.data", [])));
    toast.success("Hood Rule Created!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("createHoodRule saga error ::", error)
  }
}

function* createHoodTagCategory(data){
  try{
    yield call(mias.apis.hoods.createHoodTagCategory, data.data)
    yield put(saveHoodTagCategoryAddSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagCategoryList);
    yield put(saveHoodTagCategoryList(_get(response, "data.data", [])));
    toast.success("Hood Tag Category Created!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("createHoodTagCategory saga error ::", error)
  }
}

function* createHoodTag(data){
  try{
    yield call(mias.apis.hoods.createHoodTag, data.data)
    yield put(saveHoodTagAddSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagList);
    yield put(saveHoodTagsList(_get(response, "data.data", [])));
    toast.success("Hood Tag Created!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("createHoodTag saga error ::", error)
  }
}

function* updateHoodCategoryStatus(data){
  try{
    const apiResp = yield call(mias.apis.hoods.updateHoodCategoryStatus, data.data)
    yield put(saveHoodCategoryStatusUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodCategoryList);
    yield put(saveHoodCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodCategoryStatus saga error ::", error)
  }
}

function* updateHoodRuleStatus(data){
  try{
    const apiResp = yield call(mias.apis.hoods.updateHoodRuleStatus, data.data)
    yield put(saveHoodRuleStatusUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodRulesList);
    yield put(saveHoodRulesList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("UpdateHoodRuleStatus saga error ::", error)
  }
}

function* updateHoodTagCategoryStatus(data){
  try{
    const apiResp = yield call(mias.apis.hoods.updateHoodTagCategoryStatus, data.data)
    yield put(saveHoodTagCategoryStatusUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagCategoryList);
    yield put(saveHoodTagCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodTagCategoryStatus saga error ::", error)
  }
}

function* updateHoodTagStatus(data){
  try{
    const apiResp = yield call(mias.apis.hoods.updateHoodTagStatus, data.data)
    yield put(saveHoodTagUpdateSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagList);
    yield put(saveHoodTagsList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodTagStatus saga error ::", error)
  }
}

function* deleteHoodCategory(data){
  try{
    const apiResp = yield call(mias.apis.hoods.deleteHoodCategory, data.data)
    yield put(saveHoodCategoryDeleteSuccess())
    const response = yield call(mias.apis.hoods.getHoodCategoryList);
    yield put(saveHoodCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("deleteHoodCategory saga error ::", error)
  }
}

function* deleteHoodRule(data){
  try{
    const apiResp = yield call(mias.apis.hoods.deleteHoodRule, data.data)
    yield put(saveHoodRuleDeleteSuccess())
    const response = yield call(mias.apis.hoods.getHoodRulesList);
    yield put(saveHoodRulesList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("deleteHoodRule saga error ::", error)
  }
}

function* deleteHoodTagCategory(data){
  try{
    const apiResp = yield call(mias.apis.hoods.deleteHoodTagCategory, data.data)
    yield put(saveHoodTagCategoryDeleteSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagCategoryList);
    yield put(saveHoodTagCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("deleteHoodTagCategory saga error ::", error)
  }
}

function* deleteHoodTag(data){
  try{
    const apiResp = yield call(mias.apis.hoods.deleteHoodTag, data.data)
    yield put(saveHoodTagDeleteSuccess())
    const response = yield call(mias.apis.hoods.getHoodTagList);
    yield put(saveHoodTagsList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("deleteHoodTag saga error ::", error)
  }
}

function* getHoodsList({pageIndex, pageSize, searchKey,categoryCode}) {
  try {
    let response = yield call(mias.apis.hoods.getHoodsList, pageIndex, pageSize, searchKey,categoryCode);
    response.data.data.pageIndex = pageIndex;
    response.data.data.pageSize = pageSize;
    if (response.data.data.totalRecord === 0) {
     toast("No Records Found");
    }
    yield put(saveHoodsList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("getHoodsList list saga error ::", err);
  }
}

function* getHoodById({id}) {
  try {
    const response = yield call(mias.apis.hoods.getHoodById, id);
    yield put(saveHoodById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getHoodById saga error ::", error);
  }
}

function* getPostListByHoodId({data, callback = () => {}}) {
  try {
    console.log("data from saga", data);
    const postResp = yield call(mias.apis.hoods.getPostsListHoodById, data);
    console.log("postResp from saga", postResp);
    setTimeout(() => {
      callback(_get(postResp, "data.data", []).length > 0);
    }, 2000);
    yield put(savePostListByHoodId(_get(postResp, "data.data", [])));

  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getPostListByHoodId saga error ::", error);
  }
}

function* clearHoodData(){
  try{
    yield put(clearHoodDataSuccess());
  } catch(error){
    console.log("clear hood error :", error)
  }
}

function* clearPostList(){
  try{
    yield put(clearPostListSuccess());
  } catch(error){
    console.log("clear post list error :", error)
  }
}

function* updateHoodDetails({updateData, navigate }) {
  try {
    const response = yield call(mias.apis.hoods.updateHood, updateData);
    toast.success(_get(response, "data.message"));
    navigate("/hoods")
  }
  catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("getPostListByHoodId saga error ::", error);
  }
}

export default function* hoods() {
  yield takeLatest(GET_HOOD_CATEGORY_LIST, getHoodCategoryList);
  yield takeLatest(SAVE_HOOD_CATEGORY_LIST, saveHoodCategoryList);
  yield takeLatest(GET_HOOD_CATEGORY_LIST_FOR_DROPDOWN, getHoodCategoryListForDropdown);
  yield takeLatest(SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN, saveHoodCategoryListForDropdown);
  yield takeLatest(GET_HOOD_CATEGORY_LIST, getHoodRulesList);
  yield takeLatest(SAVE_HOOD_CATEGORY_LIST, saveHoodRulesList);
  yield takeLatest(GET_HOOD_CATEGORY_BY_ID, getHoodCategoryById);
  yield takeLatest(SAVE_HOOD_CATEGORY_BY_ID, saveHoodCategoryById);
  yield takeLatest(GET_HOOD_RULE_BY_ID, getHoodRuleById);
  yield takeLatest(SAVE_HOOD_RULE_BY_ID, saveHoodRuleById);
  yield takeLatest(GET_HOOD_CATEGORY_UPDATE_REQUEST, updateHoodCategory);
  yield takeLatest(SAVE_HOOD_CATEGORY_UPDATE_SUCCESS, saveHoodCategoryUpdateSuccess);
  yield takeLatest(GET_HOOD_RULE_UPDATE_REQUEST, updateHoodRule);
  yield takeLatest(SAVE_HOOD_RULE_UPDATE_SUCCESS, saveHoodRuleUpdateSuccess);
  yield takeLatest(GET_HOOD_TAG_CATEGORY_LIST, getHoodTagCategoryList);
  yield takeLatest(SAVE_HOOD_TAG_CATEGORY_LIST, saveHoodTagCategoryList);
  yield takeLatest(GET_HOOD_TAGS_LIST, getHoodTagList);
  yield takeLatest(SAVE_HOOD_TAGS_LIST, saveHoodTagsList);
  yield takeLatest(GET_HOOD_TAG_CATEGORY_BY_ID, getHoodTagCategoryById);
  yield takeLatest(SAVE_HOOD_TAG_CATEGORY_BY_ID, saveHoodTagCategoryById);
  yield takeLatest(GET_HOOD_TAG_BY_ID, getHoodTagById);
  yield takeLatest(SAVE_HOOD_TAG_BY_ID, saveHoodTagById);
  yield takeLatest(GET_HOOD_TAG_CATEGORY_UPDATE_REQUEST, updateHoodTagCategory);
  yield takeLatest(SAVE_HOOD_CATEGORY_UPDATE_SUCCESS, saveHoodTagCategoryUpdateSuccess);
  yield takeLatest(GET_HOOD_TAG_UPDATE_REQUEST, updateHoodTag);
  yield takeLatest(SAVE_HOOD_TAG_UPDATE_SUCCESS, saveHoodTagUpdateSuccess);
  yield takeLatest(GET_HOOD_CATEGORY_ADD_REQUEST, createHoodCategory);
  yield takeLatest(SAVE_HOOD_CATEGORY_ADD_SUCCESS, saveHoodCategoryAddSuccess);
  yield takeLatest(GET_HOOD_RULE_ADD_REQUEST, createHoodRule);
  yield takeLatest(SAVE_HOOD_RULE_ADD_SUCCESS, saveHoodCategoryAddSuccess);
  yield takeLatest(GET_HOOD_TAG_CATEGORY_ADD_REQUEST, createHoodTagCategory);
  yield takeLatest(SAVE_HOOD_TAG_CATEGORY_ADD_SUCCESS, saveHoodTagCategoryAddSuccess);
  yield takeLatest(GET_HOOD_TAG_ADD_REQUEST, createHoodTag);
  yield takeLatest(SAVE_HOOD_TAG_ADD_SUCCESS, saveHoodTagAddSuccess);
  yield takeLatest(GET_HOOD_CATEGORY_STATUS_UPDATE_REQUEST, updateHoodCategoryStatus);
  yield takeLatest(SAVE_HOOD_CATEGORY_STATUS_UPDATE_SUCCESS, saveHoodCategoryStatusUpdateSuccess);
  yield takeLatest(GET_HOOD_RULE_STATUS_UPDATE_REQUEST, updateHoodRuleStatus);
  yield takeLatest(SAVE_HOOD_RULE_STATUS_UPDATE_SUCCESS, saveHoodRuleStatusUpdateSuccess);
  yield takeLatest(GET_HOOD_TAG_CATEGORY_STATUS_UPDATE_REQUEST, updateHoodTagCategoryStatus);
  yield takeLatest(SAVE_HOOD_TAG_CATEGORY_STATUS_UPDATE_SUCCESS, saveHoodTagCategoryStatusUpdateSuccess);
  yield takeLatest(GET_HOOD_TAG_STATUS_UPDATE_REQUEST, updateHoodTagStatus);
  yield takeLatest(SAVE_HOOD_TAG_STATUS_UPDATE_SUCCESS, saveHoodTagUpdateSuccess);
  yield takeLatest(GET_HOOD_CATEGORY_DELETE_REQUEST, deleteHoodCategory);
  yield takeLatest(SAVE_HOOD_CATEGORY_DELETE_SUCCESS, saveHoodCategoryDeleteSuccess);
  yield takeLatest(GET_HOOD_RULE_DLETE_REQUEST, deleteHoodRule);
  yield takeLatest(SAVE_HOOD_RULE_DLETE_SUCCESS, saveHoodRuleDeleteSuccess);
  yield takeLatest(GET_HOOD_TAG_CATEGORY_DELETE_REQUEST, deleteHoodTagCategory);
  yield takeLatest(SAVE_HOOD_TAG_CATEGORY_DELETE_SUCCESS, saveHoodTagCategoryDeleteSuccess);
  yield takeLatest(GET_HOOD_TAG_DELETE_REQUEST, deleteHoodTag);
  yield takeLatest(SAVE_HOOD_TAG_DELETE_SUCCESS, saveHoodTagDeleteSuccess);
  yield takeLatest(GET_HOODS_LIST, getHoodsList);
  yield takeLatest(SAVE_HOODS_LIST, saveHoodsList);
  yield takeLatest(GET_HOOD_BY_ID, getHoodById);
  yield takeLatest(SAVE_HOOD_BY_ID, saveHoodById);
  yield takeLatest(GET_POSTS_LIST_BY_HOOD_ID, getPostListByHoodId);
  yield takeLatest(SAVE_POSTS_LIST_BY_HOOD_ID, savePostListByHoodId);
  yield takeLatest(CLEAR_HOOD_DATA_REQUEST, clearHoodData);
  yield takeLatest(CLEAR_HOOD_DATA_SUCCESS, clearHoodDataSuccess);
  yield takeLatest(CLEAR_POST_LIST_REQUEST, clearPostList);
  yield takeLatest(CLEAR_POST_LIST_SUCCESS, clearPostListSuccess);
  yield takeLatest(UPDATE_HOODS_DETAILS, updateHoodDetails)
}
