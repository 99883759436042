import { call, put, takeLatest } from "redux-saga/effects";
import mias from "../api";
import { get as _get } from "lodash";
import { GET_COUNTS_FOR_DASHBOARD, SAVE_COUNTS_FOR_DASHBOARD } from "../types/action-type";
import { saveCountsFordashboard } from "../actions/dashboard";

function* getCountsFordashboard() {
  try {
    const response = yield call(mias.apis.dashboard.getCounts);
    yield put(saveCountsFordashboard(_get(response, "data.data", [])));
  } catch (err) {
    console.log("getCountsFordashboard saga error ::", err);
  }
}

export default function* dashboard() {
  yield takeLatest(GET_COUNTS_FOR_DASHBOARD, getCountsFordashboard);
  yield takeLatest(SAVE_COUNTS_FOR_DASHBOARD, saveCountsFordashboard);
}
