import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Grid, Switch } from "@mui/material";
import { get as _get } from "lodash";
import MDTypography from "../../../components/MDTypography";
import { getRolesList } from "../../../actions/roles";

const RolesList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRolesList());
  }, [dispatch]);

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "roles.list", []),
    };
  });

  const tableData = {
    columns: [
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => (
          <MDTypography Variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.name")}
          </MDTypography>
        ),
        align: "left",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: (row) => (
          <MDBox>
            <Switch checked={_get(row, "row.original.status")} />
          </MDBox>
        ),
        align: "center",
        width: "5%",
      },
    ],

    rows: _get(storeProps, "data", []),
  };

  const { columns, rows } = tableData;

  return (
    <MDBox>
      
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
        //   canSearch
        forSettings
      />
    </MDBox>
  );
};

export default RolesList;
