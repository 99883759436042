import {
  SAVE_LOGIN_USER_DATA,
  LOGOUT_REQUEST_SUCCESS,
  SAVE_ADMIN_LIST,
  SAVE_ADMIN_USER_BY_ID,
  CLEAR_ADMIN_DATA_SUCCESS,
} from "../types/action-type";
const initialState = {
  data: {},
  loading: false,
  error: null,
  list: [],
  adminData: {},
};
export default function adminUserReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_LOGIN_USER_DATA:
      return {
        ...state,
        loading: true,
        data: action.data,
      };
    case LOGOUT_REQUEST_SUCCESS:
      return {
        state: initialState,
      };
    case SAVE_ADMIN_LIST:
      return {
        ...state,
        loading: true,
        list: action.data,
      };
    case SAVE_ADMIN_USER_BY_ID:
      return {
        ...state,
        loading: true,
        adminData: action.data,
      };
    case CLEAR_ADMIN_DATA_SUCCESS:
        return {
          ...state,
          adminData: {},
        };
    default:
      return state;
  }
}
