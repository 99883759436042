import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";

import CloseIcon from '@mui/icons-material/Close';

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get as _get,
  find as _find,
  map as _map,
  omit as _omit,
  isEmpty as _isEmpty,
} from "lodash";
import MDTypography from "../../../components/MDTypography";
import { getHoodRulepdateRequest } from "../../../actions/hoods";
import { Field, FieldArray, Form, Formik } from "formik";
import MDButton from "../../../components/MDButton";
import { showError } from "../../../utils";
import { getLanguageList } from "../../../actions/language";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  addMiaBestfitReq,
  deleteMiaBestfitReq,
  getMiaBestfitById,
  getMiaBestfitList,
  updateMiaBestfitReq,
  updateMiaBestfitStatusReq,
} from "../../../actions/miaBestfits";

import IconButton from '@mui/material/IconButton';
import pxToRem from "../../../../src/assets/theme/functions/pxToRem";

const MiaBestfits = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [newLabel, setNewLabel] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    dispatch(getMiaBestfitList());
  }, [dispatch]);

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "miaBestfits.list.records", []),
      label: _get(state, "miaBestfits.bestfitData.label", []),
      ruleId: _get(state, "miaBestfits.bestfitData._id", ""),
      status: _get(state, "miaBestfits.bestfitData.status", false),
      languageList: _get(state, "language.list", []),
    };
  });

  const initialValues = {
    code: "",
    label: [
      {
        local: "",
        name: "",
        languageName: "",
      },
    ],
  };

  const getTitleName = (label) =>
    _map(label, (lab) => ({ name: lab.name }))[0].name;

  const handleChangeStatus = (value, id) => {
    const statusData = { id, status: value };
    dispatch(updateMiaBestfitStatusReq(statusData));
  };

  const tableData = {
    columns: [
      {
        Header: "title",
        accessor: "code",
        Cell: (row) => (
          <Tooltip title={getTitleName(_get(row, "row.original.label"))}>
            <MDTypography
              variant="caption" color="text" fontWeight="regular" fontSize="14px"
            >
              {getTitleName(_get(row, "row.original.label"))}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: (row) => (
          <Switch
            checked={_get(row, "row.original.status")}
            onChange={(e, value) =>
              handleChangeStatus(value, _get(row, "row.original._id"))
            }
          />
        ),
        align: "center",
        width:"5%",
      },
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <MDBox width="100px" margin="auto">
            <IconButton sx={{ 
              color: "#54AF3A", 
              backgroundColor: "#E8FFE2", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              '&:hover' :{
                backgroundColor: "#e6f3e2"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() => handleClickOpen(_get(row, "row.original._id"))}
                sx={{ cursor: "pointer" }}
              >
                edit_icon
              </Icon>
            </IconButton>
            <IconButton sx={{ 
              color: "#F05A22", 
              backgroundColor: "#fde4db", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              cursor: "pointer", ml: 2,
              '&:hover' :{
                backgroundColor: "#fbcfbf"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() => {
                  setOpenConfirmation(true);
                  setDeleteId(_get(row, "row.original._id"));
                }}
                sx={{ cursor: "pointer"}}
              >
                delete
              </Icon>
            </IconButton>
          </MDBox>
        ),
        align: "center",
        width:"10%",
      },
    ],

    rows: _get(storeProps, "data", []),
  };
  const { columns, rows } = tableData;

  const handleClickOpen = (id) => {
    setOpen(true);
    dispatch(getMiaBestfitById(id));
  };

  const handleClose = () => {
    setOpen(false);
    setIsNew(false);
    setNewLabel([]);
  };

  const handleChange = (event, value, index, setFieldValue, formValues) => {
    let checkFormLabel;
    if (!_isEmpty(formValues.label)) {
      checkFormLabel = _find(formValues.label, (e) => e.local === value.local);
    }
    if (!checkFormLabel) setFieldValue(`label.${index}`, value);
    else toast.error("Same Language Not Allowed!");
  };

  const updateBestfit = (values) => {
    const labelArr = _map(_get(values, "label"), (label) =>
      _omit(label, ["languageName"])
    );
    let bestfitData = {
      label: labelArr,
    };
    if (isNew) {
      //   bestfitData.code = labelArr[0].name;
      dispatch(addMiaBestfitReq(bestfitData));
    } else {
      bestfitData.id = _get(storeProps, "ruleId");
      //   bestfitData.code = _get(storeProps, "code");
      dispatch(updateMiaBestfitReq(bestfitData));
    }

    handleClose();
  };

  const addRule = () => {
    dispatch(getLanguageList());
    setIsNew(true);
    labelledData();
  };

  const labelledData = () => {
    _map(_get(storeProps, "languageList"), (langListObj) => {
      const obj = {
        local: langListObj.code,
        languageName: langListObj.label,
      };
      newLabel.push(obj);
    });
  };

  const schema = Yup.object().shape({
    label: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Required"),
        local: Yup.string().required("Required"),
      })
    ),
  });

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setDeleteId("");
  };

  const deleteMiaBestfit = () => {
    dispatch(deleteMiaBestfitReq({ id: deleteId }));
    handleConfirmationClose();
  };

  const getOptionList = () => {
      if (storeProps.label.length <= 2 && !isAddLanguage) {
        return storeProps.label
      } else if (isAddLanguage && storeProps.label.length < 2 && _isEmpty(newLabel)) {
        labelledData()
        return newLabel
      } else if (!_isEmpty(newLabel)) {
        return newLabel;
      } else {
        return storeProps.label
      }
  }

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="flex-end" textAlign="center" p={2}>
        
         
            <MDButton variant="outlined" color="orange" onClick={() => addRule()}>
              {" "}
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                add_icon
              </Icon>{" "}
              Add
            </MDButton>
          
        
      </MDBox>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={true}
        noEndBorder
        //   canSearch
        forSettings
      />
      <Dialog
        open={open || isNew}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, pr: 8 }}>
          {isNew ? "Add Bestfit" : "Edit Bestfit"}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 10,
                color: (theme) => theme.palette.grey[700],
                backgroundColor: (theme) => theme.palette.grey[200],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
        <DialogContent sx={{ marginTop: 0 }}>
          <Formik
            initialValues={isNew ? initialValues : storeProps}
            validationSchema={schema}
            enableReinitialize
            onSubmit={async (values) => updateBestfit(values)}
            render={({
              values,
              errors,
              touched,
              handleReset,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <FieldArray
                    name="label"
                    render={({ insert, remove, push }) => (
                      <>
                        <Stack direction="column" spacing={2} py={3} >
                        {values.label.map((label, index) => (
                          <React.Fragment key={index}>
                              <MDBox width="100%">
                              {values.label.length < storeProps.languageList.length && (
                                  <MDBox textAlign="right">
                                    <MDButton
                                      p={3}
                                      size="small"
                                      variant="text"
                                      color="info"
                                      sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                      onClick={() => {
                                        isNew ?
                                        push({
                                          local: "",
                                          name: "",
                                          languageName: "",
                                        })
                                       :
                                        push({
                                          local: "",
                                          name: "",
                                          languageName: "",
                                        })
                                        setIsAddLanguage(true)
                                      }
                                    }
                                    >
                                      Add Language
                                    </MDButton>
                                    </MDBox>
                                )}
                                {values.label.length > 1 && (
                                  <MDBox textAlign="right">
                                    <MDButton
                                      variant="text"
                                      color="error"
                                      onClick={() => remove(index)}
                                      sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                    >
                                      Remove
                                    </MDButton>
                                  </MDBox>
                                )}
                                <Field name={`label`}>
                                  {({ field }) => (
                                    <>
                                      <Autocomplete
                                        getOptionLabel={(option) => {
                                          return option.languageName
                                        }
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          option.local === value.local
                                        }
                                        value={field.value[index]}
                                        onChange={(event, value) =>
                                          handleChange(
                                            event,
                                            value,
                                            index,
                                            setFieldValue,
                                            values
                                          )
                                        }
                                        options={
                                          isNew ? newLabel : getOptionList()
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Language"
                                          />
                                        )}
                                        disabled={(!isNew && !_isEmpty(label.languageName))}
                                      />
                                    </>
                                  )}
                                </Field>
                                {errors?.label?.[index]?.local &&
                                  // touched?.label?.[index]?.local &&
                                  showError(errors.label[index].local)}
                                <Field name={`label.${index}.name`}>
                                  {({ field }) => (
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Name"
                                      // minRows={3}
                                      InputProps={{
                                          rows: 3
                                      }}
                                      placeholder="Name"
                                      style={{ width: "100%", marginTop: 20 }}
                                      onChange={field.onChange(field.name)}
                                      value={field.value}
                                    />
                                  )}
                                </Field>
                                {errors?.label?.[index]?.name &&
                                  // touched?.label?.[index]?.name &&
                                  showError(errors.label[index].name)}
                                 
                                 </MDBox>
                            {/* <Divider /> */}
                          </React.Fragment>
                        ))}
                        </Stack>
                        <MDBox
                          pt={2}
                          display="flex"
                          justifyContent={
                            values.label.length < 2
                              ? "flex-end"
                              : "flex-end"
                          }
                        >
                          
                          <MDBox>
                            {values.label.length !== 0 && (
                              <MDButton
                                variant="outlined"
                                color="success"
                                type="submit"
                                sx={{ marginRight: "15px" }}
                              >
                                Save
                              </MDButton>
                            )}
                            <MDButton
                              variant="outlined"
                              color="error"
                              onClick={handleClose}
                            >
                              Cancel
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openConfirmation} onClose={handleConfirmationClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign="center" sx={{ m: 0, px: 4, pt: 4 }}>
          {"Are you sure you want to delete Mia Bestfit?"}
        </DialogTitle>
        <DialogActions textAlign="center" display="flex" sx={{ justifyContent:"center", pb:4 }}>
          <MDButton variant="outlined" color="success" onClick={deleteMiaBestfit} sx={{mr:1, minWidth:"125px"}}>
            Agree
          </MDButton>
          <MDButton
            variant="outlined"
            color="error"
            onClick={handleConfirmationClose}
            sx={{minWidth:"125px"}}
          >
            Disagree
          </MDButton>
          
        </DialogActions>
      </Dialog>
    </MDBox>
  );
};

export default MiaBestfits;
