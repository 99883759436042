import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import TabPanel from "./../tabPanel";
import { useState } from "react";
import LanguagesList from "./languagesList";
import RolesList from "./rolesList";
import MiaInterest from "./miaInterest";
import MiaBestfits from "./MiaBestfits";

import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import Card from "@mui/material/Card";

const GeneralSettings = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <Card>  
          <MDBox borderBottom="1px solid #dee2e6">
            <AppBar position="static" > 
              <Tabs
                variant="standard"
                orientation={"horizontal"}
                value={tabValue}
                onChange={handleSetTabValue}
                textColor="primary"
                // indicatorColor="secondary"
              >
                <Tab
                  label="Languages"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      language
                    </Icon>
                  }
                />
                <Tab
                  label="Roles"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      group_icon
                    </Icon>
                  }
                />
                <Tab
                  label="Mia Interest"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      color_lens
                    </Icon>
                  }
                />
                  <Tab
                  label="Mia Bestfits"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      female
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </MDBox>
          
          <TabPanel value={tabValue} index={0}>
            <LanguagesList />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <RolesList />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <MiaInterest />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <MiaBestfits />
          </TabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default GeneralSettings;
