import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import { Field, Form, Formik } from "formik";
import { get as _get, isEqual as _isEqual, round as _round } from "lodash";
import MDButton from "../../components/MDButton";
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Switch,
  Slider,
  Card,
  Grid,
} from "@mui/material";
import { getAppUserById, updateAppUserById } from "../../actions/user";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { renderTextField } from "../../inputFields/formFields";
import { AccordionDetails } from "@material-ui/core";

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const [kidsExpanded, setKidsexpanded] = useState(true);
  const [usersExpanded, setUsersexpanded] = useState(true);
  const [bestFitExpanded, setBestFitExpanded] = useState(true);

  const params = useParams();

  const appUserView = _isEqual(_get(params, "action", ""), "view");

  useEffect(() => {
    appUserView && dispatch(getAppUserById(params.id));
  }, [appUserView, params, dispatch]);

  const storeProps = useSelector((state) => {
    return {
      firstName: _get(state, "user.userData.firstName", ""),
      lastName: _get(state, "user.userData.lastName", ""),
      email: _get(state, "user.userData.email", ""),
      dialCode: _get(state, "user.userData.dialCode", ""),
      mobileNo: _get(state, "user.userData.mobileNo", ""),
      dialFlag: _get(state, "user.userData.dialFlag", ""),
      birthDate: _get(state, "user.userData.birthDate", ""),
      appLanguage: _get(state, "user.userData.appLanguage", ""),
      nationalityFlag: _get(state, "user.userData.address.nationalityFlag", ""),
      nationalityCode: _get(state, "user.userData.address.nationalityCode", ""),
      nationalityLabel: _get(
        state,
        "user.userData.address.nationalityLabel",
        ""
      ),
      livesInFlag: _get(state, "user.userData.address.livesInFlag", ""),
      livesInCode: _get(state, "user.userData.address.livesInCode", ""),
      city: _get(state, "user.userData.address.city", ""),
      isNotificationOn: _get(state, "user.userData.isNotificationOn", ""),
      isHibernate: _get(state, "user.userData.isHibernate", ""),
      isPublic: _get(state, "user.userData.isPublic", ""),
      profilePicture: _get(state, "user.userData.profilePicture", ""),
      bio: _get(state, "user.userData.bio", ""),
      instagramId: _get(state, "user.userData.instagramId", ""),
      isVerified: _get(state, "user.userData.isVerified", ""),
      showChildrenInfo: _get(state, "user.userData.showChildrenInfo", false),
      canCreateGroup: _get(state, "user.userData.canCreateGroup", false),
      kidsDetails: _get(state, "user.userData.kidsDetails", []),

      userInterets: _get(state, "user.userData.interest", []),
      bestFit: _get(state, "user.userData.bestFit", []),
      dueDate: _get(state, "user.userData.pregnancyDetails.dueDate", ""),
    };
  });

  const interestSortingRow = [];

  storeProps.userInterets.map((item) => {
    if (item.label === "Food") interestSortingRow[0] = item;
    else if (item.label === "Boost") interestSortingRow[1] = item;
    else if (item.label === "Active") interestSortingRow[2] = item;
    else if (item.label === "Adventurer") interestSortingRow[3] = item;
    else if (item.label === "Structure") interestSortingRow[4] = item;
    else if (item.label === "Culture") interestSortingRow[5] = item;
    else if (item.label === "Outdoor") interestSortingRow[6] = item;
    else if (item.label === "Cooking") interestSortingRow[7] = item;
    else if (item.label === "Creativity") interestSortingRow[8] = item;
    return interestSortingRow
  });

  const saveAppUserProfile = (value) => {
    const userData = {
      ...value,
      id: params.id,
      canCreateGroup: value.canCreateGroup,
    };
    dispatch(updateAppUserById(userData, navigate));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                mb={0}
                variant="contained"
                bgColor='rgba(0, 0, 0, 0.0)'
                borderRadius="lg"
              >
                <MDTypography variant="h5" color="black">
                  {appUserView ? "View App User" : "Add App User"}
                </MDTypography>
              </MDBox>
              <Formik
                initialValues={storeProps}
                enableReinitialize
                onSubmit={async (values) => saveAppUserProfile(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleReset,
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                }) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <MDBox px={2}>
                        <MDBox width="100%" textAlign="center">
                          <Avatar
                            alt="dialFlag"
                            variant="square"
                            src={_get(storeProps, "profilePicture")}
                            sx={{
                              height: "200px",
                              width: "200px",
                              maxWidth: "100%",
                              m: "auto",
                              objectFit: "cover",
                              borderRadius: "6px",
                              marginTop: "7px",
                            }}
                          />
                        </MDBox>
                        <MDBox width="100%" mt={3}>
                          <Grid container spacing={1} pl={0} pr={0}>
                            <Grid item sm={6}>
                              <Field
                                name="firstName"
                                component={renderTextField}
                                label="First Name"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <Field
                                name="lastName"
                                component={renderTextField}
                                label="Last Name"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} pl={0} pr={0}>
                            <Grid item sm={6}>
                              <Field
                                name="email"
                                component={renderTextField}
                                label="Email"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <Field
                                name="birthDate"
                                component={renderTextField}
                                label="Birthdate"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} pl={0} pr={0}>
                            <Grid item sm={6}>
                              <Field
                                name="bio"
                                component={renderTextField}
                                label="Bio"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <Field
                                name="instagramId"
                                component={renderTextField}
                                label="Instagram Id"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <MDBox display="flex" alignItems="center">
                            <Avatar
                              alt="dialFlag"
                              variant="square"
                              src={_get(storeProps, "dialFlag")}
                              sx={{
                                height: "auto",
                                width: "auto",
                                maxWidth: "60px",
                                objectFit: "cover",
                                borderRadius: "6px",
                                mt: 1,
                              }}
                            />
                            <Field
                              name="dialCode"
                              component={renderTextField}
                              label="Code"
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ marginLeft: 10 }}
                            />
                            <Field
                              name="mobileNo"
                              component={renderTextField}
                              label="Mobile"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ marginLeft: 10 }}
                            />
                          </MDBox>
                          <Grid item xs={12} md={12} mt={3}>
                            <Accordion
                              style={{
                                padding: "0px",
                                boxShadow: "0px 0px 4px rgb(0 0 0 / 30%)",
                                border: "none",
                              }}
                              expanded={expanded}
                              onChange={() => setExpanded(!expanded)}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <MDTypography
                                  variant="h5"
                                  color="black"
                                  fontSize="16px"
                                >
                                  Address
                                </MDTypography>
                              </AccordionSummary>
                              <Grid container spacing={3} p={2} pt={0}>
                                <Grid item xs={12} md={6}>
                                  <MDBox display="flex" alignItems="center">
                                    <Avatar
                                      alt="nationalityFlag"
                                      variant="square"
                                      src={_get(storeProps, "nationalityFlag")}
                                      sx={{
                                        height: "auto",
                                        width: "auto",
                                        maxWidth: "60px",
                                        objectFit: "cover",
                                        borderRadius: "6px",
                                        mt: 1,
                                      }}
                                    />
                                    <Field
                                      name="nationalityLabel"
                                      component={renderTextField}
                                      label="Nationality"
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      style={{ marginLeft: 10 }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <MDBox display="flex" alignItems="center">
                                    <Avatar
                                      alt="livesInFlag"
                                      variant="square"
                                      src={_get(storeProps, "livesInFlag")}
                                      sx={{
                                        height: "auto",
                                        width: "auto",
                                        maxWidth: "60px",
                                        objectFit: "cover",
                                        borderRadius: "6px",
                                        mt: 1,
                                      }}
                                    />
                                    <Field
                                      name="city"
                                      component={renderTextField}
                                      label="City"
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      style={{ marginLeft: 10 }}
                                    />
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Accordion>
                          </Grid>
                          {storeProps.showChildrenInfo &&
                            storeProps.kidsDetails.length > 0 && (
                              <Grid item xs={12} md={12} mt={3}>
                                <Accordion
                                  style={{
                                    padding: "0px",
                                    boxShadow: "0px 0px 4px rgb(0 0 0 / 30%)",
                                    border: "none",
                                  }}
                                  expanded={kidsExpanded}
                                  onChange={() =>
                                    setKidsexpanded(!kidsExpanded)
                                  }
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                  >
                                    <MDTypography
                                      variant="h5"
                                      color="black"
                                      fontSize="16px"
                                    >
                                      Number of children
                                    </MDTypography>
                                  </AccordionSummary>
                                  {storeProps.kidsDetails.map(
                                    (kidetail, kdIdx) => (
                                      <Grid
                                        container
                                        spacing={3}
                                        p={2}
                                        pt={0}
                                        key={kdIdx}
                                      >
                                        <Grid item xs={12} md={4}>
                                          {/* <MDBox display="flex" alignItems="center"> */}
                                          <Field
                                            //name={kidetail.gender==='do_not_know'?`Rather Not to Say`:`kidsDetails.${kdIdx}.gender`}
                                            name={`kidsDetails.${kdIdx}.gender`}
                                            component={renderTextField}
                                            label="Gender"
                                            value={
                                              kidetail.gender !== "do_not_know"
                                                ? kidetail.gender
                                                : "Rather not say"
                                            }
                                            fullWidth
                                            InputProps={{
                                              readOnly: true,
                                            }}

                                            // style={{ marginLeft: 10 }}
                                          />
                                          {/* </MDBox> */}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          {/* <MDBox display="flex" alignItems="center"> */}
                                          <Field
                                            name={`kidsDetails.${kdIdx}.kidsAge`}
                                            component={renderTextField}
                                            label="Kids Age"
                                            value={kidetail.kidsAge}
                                            fullWidth
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field
                                            name={`kidsDetails.${kdIdx}.birthDate`}
                                            component={renderTextField}
                                            label="Kids birthdate"
                                            value={kidetail.birthDate}
                                            fullWidth
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    )
                                  )}
                                </Accordion>
                              </Grid>
                            )}
                          {storeProps.bestFit.length > 0 && (
                            <Accordion
                              style={{
                                padding: "0px",
                                boxShadow: "0px 0px 4px rgb(0 0 0 / 30%)",
                                border: "none",
                              }}
                              expanded={bestFitExpanded}
                              onChange={() =>
                                setBestFitExpanded(!bestFitExpanded)
                              }
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <MDTypography
                                  variant="h6"
                                  color="black"
                                  fontSize="18px"
                                >
                                  Family Info
                                </MDTypography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={4} pt={2} pr={0}>
                                  {storeProps.bestFit.map((bestFit, bIndex) => (
                                    <Grid item xs={12} md={4}>
                                      <React.Fragment key={bIndex}>
                                        {bestFit === "Pregnant" ? (
                                          <MDTypography
                                            color="black"
                                            fontSize="16px"
                                            mb={0}
                                          >
                                            {bestFit}:
                                            {storeProps.dueDate.substring(
                                              0,
                                              10
                                            )}
                                          </MDTypography>
                                        ) : (
                                          <MDTypography
                                            color="black"
                                            fontSize="16px"
                                            mb={0}
                                          >
                                            {bestFit}
                                          </MDTypography>
                                        )}
                                        {/* <MDTypography color="black" fontSize="16px" mb={0} >
                                          {bestFit}
                                          </MDTypography> */}
                                      </React.Fragment>
                                    </Grid>
                                  ))}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          <Grid container spacing={1} mt={4} pr={0}>
                            <Grid item xs={12} sm={6} md={3} mb={2}>
                              <MDBox display="flex">
                                <MDTypography
                                  pt={1}
                                  color="black"
                                  variant="text"
                                  fontSize="16px"
                                >
                                  Notification :
                                </MDTypography>
                                <Field
                                  name="isNotificationOn"
                                  fullWidth
                                  disabled
                                >
                                  {({ field }) => (
                                    <Switch checked={field.value} />
                                  )}
                                </Field>
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} mb={2}>
                              <MDBox display="flex">
                                <MDTypography
                                  pt={1}
                                  color="black"
                                  variant="text"
                                  fontSize="16px"
                                >
                                  Show Children Info :
                                </MDTypography>
                                <Field
                                  name="showChildrenInfo"
                                  fullWidth
                                  disabled
                                >
                                  {({ field }) => (
                                    <Switch checked={field.value} />
                                  )}
                                </Field>
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} mb={2}>
                              <MDBox display="flex">
                                <MDTypography
                                  pt={1}
                                  color="black"
                                  variant="text"
                                  fontSize="16px"
                                >
                                  Public :
                                </MDTypography>
                                <Field name="isPublic" fullWidth disabled>
                                  {({ field }) => (
                                    <Switch checked={field.value} />
                                  )}
                                </Field>
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} mb={2}>
                              <MDBox display="flex">
                                <MDTypography
                                  pt={1}
                                  color="black"
                                  variant="text"
                                  fontSize="16px"
                                >
                                  Create Group:
                                </MDTypography>
                                <Field name="canCreateGroup" fullWidth disabled>
                                  {({ field }) => (
                                    <Switch
                                      checked={field.value}
                                      onChange={(e, val) => {
                                        setFieldValue("canCreateGroup", val);
                                      }}
                                    />
                                  )}
                                </Field>
                              </MDBox>
                            </Grid>
                          </Grid>

                          {storeProps.userInterets.length > 0 && (
                            <Accordion
                              style={{
                                padding: "0px",
                                boxShadow: "0px 0px 4px rgb(0 0 0 / 30%)",
                                border: "none",
                              }}
                              expanded={usersExpanded}
                              onChange={() => setUsersexpanded(!usersExpanded)}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <MDTypography
                                  variant="h6"
                                  color="black"
                                  fontSize="18px"
                                >
                                  User Interest
                                </MDTypography>
                                <hr
                                  style={{
                                    borderTop: "1px solid #e3e3e3",
                                    borderWidth: "1px 0 0 0",
                                  }}
                                />
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={4} pt={2} pr={0}>
                                  {interestSortingRow.map(
                                    (userInteret, iIndex) => (
                                      <React.Fragment key={iIndex}>
                                        <Grid item xs={12} md={4}>
                                          <MDTypography
                                            color="black"
                                            fontSize="16px"
                                            mb={0}
                                          >
                                            {userInteret.label}
                                          </MDTypography>
                                          <Slider
                                            value={_round(
                                              userInteret.value.$numberDecimal
                                            )}
                                            min={0}
                                            max={10}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="non-linear-slider"
                                          />
                                          <MDBox
                                            display="flex"
                                            justifyContent="space-between"
                                            mb={0}
                                          >
                                            <MDTypography
                                              color="#000"
                                              fontSize="13px"
                                              fontWeight="500"
                                            >
                                              {userInteret.startLabel}
                                            </MDTypography>
                                            <MDTypography
                                              color="#000"
                                              fontSize="13px"
                                              fontWeight="500"
                                            >
                                              {userInteret.endLabel}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                      </React.Fragment>
                                    )
                                  )}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </MDBox>
                      </MDBox>

                      <MDBox
                        p={2}
                        textAlign="right"
                        // mt={2} sx={({ palette: { grey }, borders: { borderWidth } }) => ({
                        // borderTop: `${borderWidth[1]} solid ${grey[300]}`,
                        // backgroundColor: grey[200],
                        // variant: "contained",
                        // borderRadius: "0 0 10px 10px"
                        // })}
                      >
                        <MDButton
                          variant="outlined"
                          color="success"
                          type="submit"
                          // onClick={() => navigate("/users")}
                          sx={{ mr: 2 }}
                        >
                          Save
                        </MDButton>
                        <MDButton
                          variant="outlined"
                          color="error"
                          onClick={() => navigate("/users")}
                        >
                          Cancel
                        </MDButton>
                      </MDBox>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default User;
