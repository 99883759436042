import { call, put, takeLatest } from "redux-saga/effects";
import {
  addMiaBestfitSuccess,
  deleteMiaBestfitSuccess,
  saveMiaBestfitById,
  saveMiaBestfitList,
  updateMiaBestfitStatusSuccess,
  updateMiaBestfitSuccess,
} from "../actions/miaBestfits";
import mias from "../api";
import {
  GET_ADD_MIA_BESTFIT_REQUEST,
  GET_MIA_BESTFITS_LIST,
  GET_MIA_BESTFIT_BY_ID,
  GET_MIA_BESTFIT_DELETE_REQUEST,
  GET_MIA_BESTFIT_STATUS_UPDATE_REQUEST,
  GET_MIA_BESTFIT_UPDATE_REQUEST,
  SAVE_ADD_MIA_BESTFIT_RESPONSE,
  SAVE_MIA_BESTFITS_LIST,
  SAVE_MIA_BESTFIT_BY_ID,
  SAVE_MIA_BESTFIT_DELETE_SUCCESS,
  SAVE_MIA_BESTFIT_STATUS_UPDATE_RESPONSE,
  SAVE_MIA_BESTFIT_UPDATE_RESPONSE,
} from "../types/action-type";
import { get as _get } from "lodash";
import toast from "react-hot-toast";

function* getMiaBestfitsList() {
  try {
    const response = yield call(mias.apis.miaBestfits.getMiaBestfitsList);
    yield put(saveMiaBestfitList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getMiaBestfitsList saga error ::", error);
  }
}

function* updateMiaBestfitStatus(data) {
  try {
    const apiResp = yield call(
      mias.apis.miaBestfits.updateMiaBestfitStatus,
      data.data
    );
    yield put(updateMiaBestfitStatusSuccess());
    const response = yield call(mias.apis.miaBestfits.getMiaBestfitsList);
    yield put(saveMiaBestfitList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("updateMiaBestfitStatus saga error ::", error);
  }
}

function* addMiaBestfit(data) {
  try {
    const apiResp = yield call(mias.apis.miaBestfits.addMiaBestfit, data.data);
    yield put(addMiaBestfitSuccess());
    const response = yield call(mias.apis.miaBestfits.getMiaBestfitsList);
    yield put(saveMiaBestfitList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("addMiaBestfit saga error ::", error);
  }
}

function* getMiaBestfitById({ id }) {
  try {
    const response = yield call(mias.apis.miaBestfits.getMiaBestfitById, id);
    console.log("saga resp :", response)
    yield put(saveMiaBestfitById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getMiaBestfitById saga error ::", error);
  }
}

function* deleteMiaBestfit(data){
  try{
    const apiResp = yield call(mias.apis.miaBestfits.deleteMiaBestfit, data.data)
    yield put(deleteMiaBestfitSuccess())
    const response = yield call(mias.apis.miaBestfits.getMiaBestfitsList);
    yield put(saveMiaBestfitList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"))
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("deleteMiaBestfit saga error ::", error)
  }
}

function* updateMiaBestfit(data){
  try{
    yield call(mias.apis.miaBestfits.updateMiaBestfit, data.data)
    yield put(updateMiaBestfitSuccess())
    const response = yield call(mias.apis.miaBestfits.getMiaBestfitsList);
    yield put(saveMiaBestfitList(_get(response, "data.data", [])));
    toast.success("Hood Rule Updated!")
  } catch (error) {
    toast.error(_get(error,"response.data.message",_get(error,"message","")))
    console.log("updateHoodRule saga error ::", error)
  }
}

export default function* miaBestfits() {
  yield takeLatest(GET_MIA_BESTFITS_LIST, getMiaBestfitsList);
  yield takeLatest(SAVE_MIA_BESTFITS_LIST, saveMiaBestfitList);
  yield takeLatest(GET_MIA_BESTFIT_STATUS_UPDATE_REQUEST, updateMiaBestfitStatus);
  yield takeLatest(SAVE_MIA_BESTFIT_STATUS_UPDATE_RESPONSE, saveMiaBestfitList);
  yield takeLatest(GET_ADD_MIA_BESTFIT_REQUEST, addMiaBestfit);
  yield takeLatest(SAVE_ADD_MIA_BESTFIT_RESPONSE, addMiaBestfitSuccess);
  yield takeLatest(GET_MIA_BESTFIT_BY_ID, getMiaBestfitById);
  yield takeLatest(SAVE_MIA_BESTFIT_BY_ID, saveMiaBestfitById);
  yield takeLatest(GET_MIA_BESTFIT_DELETE_REQUEST, deleteMiaBestfit);
  yield takeLatest(SAVE_MIA_BESTFIT_DELETE_SUCCESS, deleteMiaBestfitSuccess);
  yield takeLatest(GET_MIA_BESTFIT_UPDATE_REQUEST, updateMiaBestfit);
  yield takeLatest(SAVE_MIA_BESTFIT_UPDATE_RESPONSE, updateMiaBestfitSuccess);
}
