
// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { get as _get } from "lodash";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import MDBadge from "../../../components/MDBadge";
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import pxToRem from "../../../../src/assets/theme/functions/pxToRem";

export default function Data() {
  const navigate = useNavigate();
  const storeProps = useSelector((state) => {
    return {
        data: _get(state, "places.placesList.records", []),
        totalRecords: _get(state, "places.placesList.totalRecord", 0),
        pageIndex: _get(state, "places.placesList.pageIndex", 0),
        pageSize: _get(state, "places.placesList.pageSize", 30),
    };
  });

  return {
    columns: [
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
        Cell: (row) => (
          <MDTypography display="block" variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.name")}
          </MDTypography>
        ),
        align: "left",
      },
/*       {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px" >
            {_get(row, "row.original.address")}
          </MDTypography>
        ),
        align: "left",
      }, */
      {
        Header: "Phone No",
        accessor: "phoneNumber",
        disableSortBy: true,
        Cell: (row) => (
          <Tooltip title="Phone Number" placement="bottom">
            <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px"
              sx={{ cursor: "pointer" }}
            >
            {_get(row, "row.original.phoneNumber")}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header:"Category",
        accessor: "categoryName",
        disableSortBy: true,
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.categoryName")}
          </MDTypography>
        ),
        align: "left",
      },
 /*      {
        Header: "Review",
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.totalRating.$numberDecimal")}
          </MDTypography>
        ),
        align: "center",
      }, */
      {
        Header:"Saved Places",
        accessor:(a => a.bookmarkCount),
        sortType: 'basic',
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.bookmarkCount")}
          </MDTypography>
        ),
        align: "center",
      },
      {
        Header:"miaRating",
        accessor:(a => a.miaTotalRating),
        sortType: 'basic',
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
            {_get(row, "row.original.miaTotalRating").toFixed(1)}
          </MDTypography>
        ),
        align: "center",
      },
      {
        Header: "Original Reviewer",
        disableSortBy: true,
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
          {`${_get(row, "row.original.createrUserDetail.firstName","")} ${_get(row, "row.original.createrUserDetail.lastName","")}`}
        </MDTypography>
        ),
        align: "center",
      },
      {
        Header: "isActive",
        accessor: "isActive",
        disableSortBy: true,
        Cell: (row) => (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={_get(row, "row.original.isActive") ? "Yes" : "No"}
              color={_get(row, "row.original.isActive") ? "success" : "error"}
              variant="contained"
              size="sm"
            />
          </MDBox>
        ),
        align: "center",
      },
      {
        Header: "action",
        accessor: "action",
        disableSortBy: true,
        Cell: (row) => (
          <Tooltip title="Edit" placement="bottom" arrow={true}>
            <IconButton sx={{ 
              color: "#F05A22", 
              backgroundColor: "#fde4db", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              '&:hover' :{
                backgroundColor: "#fbcfbf"
              }
            }}  onClick={() =>
              navigate(`/places/${_get(row, "row.original._id")}/edit`)
            }>
            <Icon
            fontSize="small"
            // onClick={() =>
            //   navigate(`/places/${_get(row, "row.original._id")}/edit`)
            // }
            sx={{ cursor: "pointer" }}
          >
            edit_icon
          </Icon>
          </IconButton>
        </Tooltip>
        ),
        align: "center",
        width: "5%",
      },
    ],

    rows: _get(storeProps, "data", []),
    totalRecords: _get(storeProps, "totalRecords", 0),
    pageIndex: _get(storeProps, "pageIndex", 0),
    pageSize: _get(storeProps, "pageSize", 30),
  };
}
