import { combineReducers } from "redux";
import adminUser from "../reducers/adminUser";
import cmsListReducer from "../reducers/cmsList";
import languageReducer from "../reducers/language";
import countryReducer from "../reducers/country";
import placeReducer from "../reducers/places";
import labelLanguageReducer from "../reducers/labelLanguage";
import hoodsReducer from "../reducers/hoods";
import rolesReducer from "../reducers/roles";
import userReducer from "../reducers/user";
import dashboardReducer from "../reducers/dashboard";
import miaInterestReducer from "../reducers/miaInterest";
import miaBestfitsReducer from "../reducers/miaBestfits";
import deletedUserReducer from "../reducers/deletedUser";
import postsReducer from "../reducers/posts";

const RootReducer = combineReducers({
  auth: adminUser,
  user: userReducer,
  cms: cmsListReducer,
  language: languageReducer,
  country: countryReducer,
  hoods: hoodsReducer,
  places: placeReducer,
  labelLanguage:labelLanguageReducer,
  roles: rolesReducer,
  dashboard: dashboardReducer,
  miaInterest: miaInterestReducer,
  miaBestfits: miaBestfitsReducer,
  deletedUser:deletedUserReducer,
  posts:postsReducer
});

export default RootReducer;
