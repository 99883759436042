import {
  GET_CMS_LIST,
  SAVE_CMS_LIST,
  GET_CMS,
  SAVE_CMS,
  CLEAR_CMS_REQUEST,
  CLEAR_CMS_SUCCESS,
  SAVE_CMS_DATA_REQUEST,
  SAVE_CMS_DATA_SUCESS,
} from "../types/action-type";

export const getCmsList = () => ({
  type: GET_CMS_LIST,
});

export const saveCmsList = (data) => ({
  type: SAVE_CMS_LIST,
  data,
});

export const getCms = (id) => ({
  type: GET_CMS,
  id,
});

export const saveCms = (data) => ({
  type: SAVE_CMS,
  data,
});

export const clearCms = () => ({
  type: CLEAR_CMS_REQUEST,
});

export const clearCmsSuccess = () => ({
  type: CLEAR_CMS_SUCCESS,
});

export const saveCmsForm = (data, navigate) => ({
  type: SAVE_CMS_DATA_REQUEST,
  data,
  navigate,
});

export const saveCmsFormSuccess = () => ({
  type: SAVE_CMS_DATA_SUCESS,
});