import { call, put, takeLatest } from "redux-saga/effects";
import { clearCmsSuccess, saveCms, saveCmsList, saveCmsFormSuccess } from "../actions/cmsList";
import mias from "../api";
import { CLEAR_CMS_REQUEST, CLEAR_CMS_SUCCESS, GET_CMS, 
    GET_CMS_LIST, 
    SAVE_CMS, 
    SAVE_CMS_DATA_REQUEST, 
    SAVE_CMS_DATA_SUCESS, 
    SAVE_CMS_LIST 
} from "../types/action-type";

import { get as _get } from "lodash";
import toast from "react-hot-toast";

function* getCmsList() {
  try {
    const response = yield call(mias.apis.cms.getCmsList);
    yield put(saveCmsList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get cms list saga error ::", err);
  }
}

function* getCms({id}) {
  try {
    const response = yield call(mias.apis.cms.getCms, id);
    yield put(saveCms(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get cms saga error ::", err);
  }
}

function* clearCms(){
  try{
    yield put(clearCmsSuccess());
  } catch(error){
    console.log("clear cms error :", error)
  }
}

function* saveCmsForm(data){
  try{
    const response = yield call(mias.apis.cms.saveCms, data.data);
    // console.log("check saveCmsForm ::", response);
    yield put(saveCmsFormSuccess())
    data.navigate("/cms")
    toast.success("Saved!")
  } catch(error){
    console.log("save cms data error ::", error)
    toast.error(_get(error,"response.data.message",""))
  }
}
export default function* cmsList() {
  yield takeLatest(GET_CMS_LIST, getCmsList);
  yield takeLatest(SAVE_CMS_LIST, saveCmsList);
  yield takeLatest(GET_CMS, getCms);
  yield takeLatest(SAVE_CMS, saveCms);
  yield takeLatest(CLEAR_CMS_REQUEST, clearCms);
  yield takeLatest(CLEAR_CMS_SUCCESS, clearCmsSuccess)
  yield takeLatest(SAVE_CMS_DATA_REQUEST, saveCmsForm)
  yield takeLatest(SAVE_CMS_DATA_SUCESS, saveCmsFormSuccess)
}
