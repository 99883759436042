import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "../../../components/MDTypography";
import { get as _get } from "lodash";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { getCmsList } from "../../../actions/cmsList";
import pxToRem from "../../../../src/assets/theme/functions/pxToRem";
import { Tooltip } from "@mui/material";

export default function Data() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCmsList());
  }, [dispatch]);

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "cms.list", []),
    };
  });

  return {
    columns: [
      {
        Header: "title",
        accessor: "code",
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px" textTransform="capitalize" >
            {_get(row, "row.original.code")}
          </MDTypography>
        ),
        align: "left",
      },
      // {
      //   Header: "status",
      //   accessor: "status",
      //   Cell: (row) => (
      //     <MDBox mt={0.5}>
      //       <Switch
      //         disabled
      //         checked={
      //           _isEqual(_get(row, "row.original.status"), "1") ? true : false
      //         }
      //         color="warning"
      //       />
      //     </MDBox>
      //   ),
      //   align: "center",
      // },
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <Tooltip title="Edit" placement="bottom" arrow={true}>
            <IconButton sx={{
              color: "#F05A22",
              backgroundColor: "#fde4db",
              borderRadius: "6px",
              width: pxToRem(35),
              height: pxToRem(35),
              cursor: "pointer", ml: 2,
              '&:hover': {
                backgroundColor: "#fbcfbf"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() =>
                  navigate(`/cms/${_get(row, "row.original._id")}/edit`)
                }
                sx={{ cursor: "pointer" }}
              >
                edit_icon
              </Icon>
            </IconButton>
          </Tooltip>
        ),
        align: "center",
        width: "5%",
      },
    ],

    rows: _get(storeProps, "data", []),
  };
}
