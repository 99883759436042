
// @mui material components
import Card from "@mui/material/Card";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { Field, Form, Formik } from "formik";
import { renderTextField } from "../../../../inputFields/formFields";

// Authentication layout components
// import CoverLayout from "../../../../layouts/authentication/components/CoverLayout";

// Authentication layout components
import BasicLayout from "../../../../layouts/authentication/components/BasicLayout";

import * as Yup from "yup";
// Images
import bgImage from "../../../../assets/images/bg-image.jpg";
import { resetPasswordAdmin } from "../../../../actions/adminUser";


function Cover() {
  const {token} = useParams();
  const initialValues = {
    password: "",
    confirmPassword: "",
    token,
  };
  const resetPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Min 6 Characters Required!")
      .required("Required"),
    confirmPassword: Yup.string()
      .min(6, "Min 6 Characters Required!")
      .required("Required")
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
  });

  const dispatch=useDispatch();
  const navigate = useNavigate();
  const resetPassword = async (values) => {
      dispatch(resetPasswordAdmin(values, navigate))
  };
  return (
    <BasicLayout image={bgImage}>
      <Card sx={{boxShadow:"none !important"}}>
        <MDBox p={3} textAlign="center">
          <MDTypography variant="h4" fontWeight="semibold" color="black" textAlign="center" mb={1} >
            Reset Password
          </MDTypography>
        </MDBox>
        <Formik
                    initialValues={initialValues}
                     validationSchema={resetPasswordValidationSchema}
                    onSubmit={async (values) => resetPassword(values)}
                >
                    {({ errors, handleSubmit, isSubmitting, touched }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <MDBox px={3} pb={3}>
                                <Field
                                    name="password"
                                    component={renderTextField}
                                    fullWidth
                                    autoFocus
                                    label="Password"
                                    type="password"
                                />
                                <Field
                                    name="confirmPassword"
                                    component={renderTextField}
                                    fullWidth
                                    autoFocus
                                    label="Confirm Password"
                                    type="password"
                                />
                                <MDBox mt={4}>
                                    <MDButton
                                        variant="contained"
                                        color="btnTheme"
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        sx={{ fontSize: "16px", letterSpacing: "0.3px", fontWeight: "600" }}
                                    >
                                        Submit
                                    </MDButton>
                                </MDBox>
                                <MDBox mt={1} textAlign="center">
                                    <MDTypography variant="button" color="text">
                                        <MDTypography
                                            component={Link}
                                            to="/admin/signin"
                                            variant="button"
                                            color="btnTheme"
                                            fontWeight="medium"
                                            textGradient

                                        >
                                            Have a account ? Sign in
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Form>
                    )}
                </Formik>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
