import React from 'react'
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable/serverTable";
// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { get as _get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Tooltip } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import pxToRem from "../../../src/assets/theme/functions/pxToRem";
import { useNavigate } from "react-router-dom";
import MDBadge from '../../components/MDBadge';
import { useState } from 'react';
import MDButton from '../../components/MDButton';
import { deletePostsById } from '../../actions/posts';
const PostsList = () => {
    const handleDeleteClickOpen = (id) => {
        setDeletedId(id)
        setDeleteDialog(true)
    };
    const storeProps = useSelector((state) => {
        return {
            data: _get(state, "posts.postListData.records", []),
            totalRecords: _get(state, "posts.postListData.totalRecord", 0),
            pageIndex: _get(state, "posts.postListData.pageIndex", 0),
            pageSize: _get(state, "posts.postListData.pageSize", 30),
        };
    });
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const tableData = {

        columns: [
            {
                Header: "Image",
                accessor: "image",
                Cell: (row) => (
                    <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" src={_get(row, "row.original.images[0].url")} />

                    </MDTypography>
                ),
            },
            {
                Header: "title",
                accessor: "title",
                Cell: (row) => (
                    <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
                        {_get(row, "row.original.title")}
                    </MDTypography>
                ),
                align: "left",
            },
            {
                Header: "Post",
                accessor: "post",
                Cell: (row) => (
                    <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
                        {`${_get(row, "row.original.post").substring(0, 20)}...`}
                    </MDTypography>
                ),
                align: "left",
            },
            {
                Header: "created By",
                accessor: "user.firstName",
                Cell: (row) => (
                    <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
                        {_get(row, "row.original.user.firstName")}
                    </MDTypography>
                ),
                align: "left",
            },
            {
                Header: "Hood Name",
                accessor: "hoodName",
                Cell: (row) => (
                    <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
                        {_get(row, "row.original.hood.name")}
                    </MDTypography>
                ),
                align: "left",
            },
            {
                Header: "Post Active",
                accessor: "isPostActive",
                Cell: (row) => (
                    <MDBox ml={-1}>
                        <MDBadge
                            badgeContent={_get(row, "row.original.isPostActive") ? "Yes" : "No"}
                            color={_get(row, "row.original.isPostActive") ? "success" : "error"}
                            variant="contained"
                            size="sm"

                        />
                    </MDBox>
                ),
                align: "left",
            },
            {
                Header: "action",
                accessor: "action",
                Cell: (row) => (
                    <><Tooltip title="Edit" placement="bottom" arrow={true}>
                        <IconButton sx={{
                            color: "#F05A22",
                            backgroundColor: "#fde4db",
                            borderRadius: "6px",
                            width: pxToRem(35),
                            height: pxToRem(35),
                            '&:hover': {
                                backgroundColor: "#fbcfbf"
                            }
                        }}   onClick={() => navigate(`/posts/${_get(row, "row.original._id")}/edit`)}>
                            <Icon
                                fontSize="small"
                             //   onClick={() => navigate(`/posts/${_get(row, "row.original._id")}/edit`)}
                                sx={{ cursor: "pointer" }}
                            >
                                remove_red_eye_icon
                            </Icon>
                        </IconButton>
                    </Tooltip><Tooltip title="Delete" placement="bottom" arrow={true}>
                            <IconButton sx={{
                                color: "#F05A22",
                                backgroundColor: "#fde4db",
                                borderRadius: "6px",
                                width: pxToRem(35),
                                height: pxToRem(35),
                                ml: 2,
                                '&:hover': {
                                    backgroundColor: "#fbcfbf"
                                }
                            }} onClick={() => handleDeleteClickOpen(_get(row, "row.original._id"))}>
                                <Icon
                                    fontSize="small"
                                    // onClick={() => handleDeleteClickOpen(_get(row, "row.original._id"))}
                                    sx={{ cursor: "pointer" }}
                                >
                                    delete_icon
                                </Icon>
                            </IconButton>
                        </Tooltip></>
                ),
                align: "center",
            },
        ],
        rows: _get(storeProps, "data", []),
        totalRecords: _get(storeProps, "totalRecords", 0),
        pageIndex: _get(storeProps, "pageIndex", 0),
        pageSize: _get(storeProps, "pageSize", 30),


    }
    const [deletedId, setDeletedId] = useState();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const { columns, rows, totalRecords, pageIndex, pageSize } = tableData;
    const deleteUser=(id)=>{
    setDeletedId(id)
    if(deletedId){
        dispatch(deletePostsById(deletedId))
        setDeleteDialog(false)
    }
    // dispatch(deleteUserById(deletedUserId))
    // setDeleteDialog(false)
    }
    const handleDeleteClose = () => {
    setDeleteDialog(false)
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={0} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={0}
                                mt={0}
                                mb={-8}
                                py={2}
                                px={2}
                                variant="contained"
                                bgColor='rgba(0, 0, 0, 0.0)'
                                borderRadius="lg"
                                zIndex="1"
                                width="calc(100% - 250px)"
                            // coloredShadow="info"
                            >
                                <MDTypography variant="h5" color="black" pt={1.1}>
                                    Posts List
                                </MDTypography>

                            </MDBox>
                            <MDBox pt={0}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    // entriesPerPage={true}
                                    showTotalEntries={true}
                                    noEndBorder
                                    canSearch={true}
                                    totalRecords={totalRecords}
                                    pageIndex1={pageIndex}
                                    pageSize1={pageSize}
                                    tableFor="postsList"
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                     {
      deleteDialog&&deletedId!==null?
        <Dialog open={deleteDialog} onClose={()=>setDeleteDialog(false)} fullWidth maxWidth="sm">
          <DialogTitle>
            Delete Post
            </DialogTitle>
          <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
          <DialogContent>
            <DialogContentText sx={{ maxHeight: "400px"}}>
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={()=>handleDeleteClose()} variant="outlined" color="error">Cancel</MDButton> 
            <MDButton onClick={()=>deleteUser(deletedId)} variant="outlined" color="error">Delete</MDButton> 
          </DialogActions>
          </Dialog>
      :null}
            </MDBox>
        </DashboardLayout>
    )
}

export default PostsList