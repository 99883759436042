import {
  GET_HOOD_CATEGORY_LIST,
  GET_HOOD_RULES_LIST,
  SAVE_HOOD_CATEGORY_LIST,
  SAVE_HOOD_RULES_LIST,
  GET_HOOD_CATEGORY_BY_ID,
  SAVE_HOOD_CATEGORY_BY_ID,
  GET_HOOD_RULE_BY_ID,
  SAVE_HOOD_RULE_BY_ID,
  GET_HOOD_CATEGORY_UPDATE_REQUEST,
  SAVE_HOOD_CATEGORY_UPDATE_SUCCESS,
  GET_HOOD_RULE_UPDATE_REQUEST,
  SAVE_HOOD_RULE_UPDATE_SUCCESS,
  GET_HOOD_TAG_CATEGORY_LIST,
  SAVE_HOOD_TAG_CATEGORY_LIST,
  GET_HOOD_TAGS_LIST,
  SAVE_HOOD_TAGS_LIST,
  GET_HOOD_TAG_CATEGORY_BY_ID,
  SAVE_HOOD_TAG_CATEGORY_BY_ID,
  GET_HOOD_TAG_BY_ID,
  SAVE_HOOD_TAG_BY_ID,
  GET_HOOD_TAG_CATEGORY_UPDATE_REQUEST,
  SAVE_HOOD_TAG_CATEGORY_UPDATE_SUCCESS,
  GET_HOOD_TAG_UPDATE_REQUEST,
  SAVE_HOOD_TAG_UPDATE_SUCCESS,
  GET_HOOD_CATEGORY_ADD_REQUEST,
  SAVE_HOOD_CATEGORY_ADD_SUCCESS,
  GET_HOOD_RULE_ADD_REQUEST,
  SAVE_HOOD_RULE_ADD_SUCCESS,
  GET_HOOD_TAG_CATEGORY_ADD_REQUEST,
  SAVE_HOOD_TAG_CATEGORY_ADD_SUCCESS,
  GET_HOOD_TAG_ADD_REQUEST,
  SAVE_HOOD_TAG_ADD_SUCCESS,
  GET_HOOD_CATEGORY_STATUS_UPDATE_REQUEST,
  SAVE_HOOD_CATEGORY_STATUS_UPDATE_SUCCESS,
  GET_HOOD_RULE_STATUS_UPDATE_REQUEST,
  SAVE_HOOD_RULE_STATUS_UPDATE_SUCCESS,
  GET_HOOD_TAG_CATEGORY_STATUS_UPDATE_REQUEST,
  SAVE_HOOD_TAG_CATEGORY_STATUS_UPDATE_SUCCESS,
  GET_HOOD_TAG_STATUS_UPDATE_REQUEST,
  SAVE_HOOD_TAG_STATUS_UPDATE_SUCCESS,
  GET_HOOD_CATEGORY_DELETE_REQUEST,
  SAVE_HOOD_CATEGORY_DELETE_SUCCESS,
  GET_HOOD_RULE_DLETE_REQUEST,
  SAVE_HOOD_RULE_DLETE_SUCCESS,
  GET_HOOD_TAG_CATEGORY_DELETE_REQUEST,
  SAVE_HOOD_TAG_CATEGORY_DELETE_SUCCESS,
  GET_HOOD_TAG_DELETE_REQUEST,
  SAVE_HOOD_TAG_DELETE_SUCCESS,
  GET_HOODS_LIST,
  SAVE_HOODS_LIST,
  GET_HOOD_BY_ID,
  SAVE_HOOD_BY_ID,
  SAVE_POSTS_LIST_BY_HOOD_ID,
  GET_POSTS_LIST_BY_HOOD_ID,
  CLEAR_HOOD_DATA_REQUEST,
  CLEAR_HOOD_DATA_SUCCESS,
  CLEAR_POST_LIST_REQUEST,
  CLEAR_POST_LIST_SUCCESS,
  GET_HOOD_CATEGORY_LIST_FOR_DROPDOWN,
  SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN,
  UPDATE_HOODS_DETAILS
} from "../types/action-type";

export const getHoodCategoryList = () => ({
  type: GET_HOOD_CATEGORY_LIST,
});

export const saveHoodCategoryList = (data) => ({
  type: SAVE_HOOD_CATEGORY_LIST,
  data,
});

export const getHoodCategoryListForDropdown = () => ({
  type: GET_HOOD_CATEGORY_LIST_FOR_DROPDOWN,
});

export const saveHoodCategoryListForDropdown = (data) => ({
  type: SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN,
  data,
});

export const getHoodRulesList = () => ({
  type: GET_HOOD_RULES_LIST,
});

export const saveHoodRulesList = (data) => ({
  type: SAVE_HOOD_RULES_LIST,
  data,
});

export const getHoodCategoryById = (id) => ({
  type: GET_HOOD_CATEGORY_BY_ID,
  id,
});

export const saveHoodCategoryById = (data) => ({
  type: SAVE_HOOD_CATEGORY_BY_ID,
  data,
});

export const getHoodRuleById = (id) => ({
  type: GET_HOOD_RULE_BY_ID,
  id,
});

export const saveHoodRuleById = (data) => ({
  type: SAVE_HOOD_RULE_BY_ID,
  data,
});

export const getHoodCategoryUpdateRequest = (data) => ({
  type: GET_HOOD_CATEGORY_UPDATE_REQUEST,
  data,
});

export const saveHoodCategoryUpdateSuccess = () => ({
  type: SAVE_HOOD_CATEGORY_UPDATE_SUCCESS,
});

export const getHoodRulepdateRequest = (data) => ({
  type: GET_HOOD_RULE_UPDATE_REQUEST,
  data,
});

export const saveHoodRuleUpdateSuccess = () => ({
  type: SAVE_HOOD_RULE_UPDATE_SUCCESS,
});

export const getHoodTagCategoryList = () => ({
  type: GET_HOOD_TAG_CATEGORY_LIST,
});

export const saveHoodTagCategoryList = (data) => ({
  type: SAVE_HOOD_TAG_CATEGORY_LIST,
  data,
});

export const getHoodTagsList = () => ({
  type: GET_HOOD_TAGS_LIST,
});

export const saveHoodTagsList = (data) => ({
  type: SAVE_HOOD_TAGS_LIST,
  data,
});

export const getHoodTagCategoryById = (id) => ({
  type: GET_HOOD_TAG_CATEGORY_BY_ID,
  id,
});

export const saveHoodTagCategoryById = (data) => ({
  type: SAVE_HOOD_TAG_CATEGORY_BY_ID,
  data,
});

export const getHoodTagById = (id) => ({
  type: GET_HOOD_TAG_BY_ID,
  id,
});

export const saveHoodTagById = (data) => ({
  type: SAVE_HOOD_TAG_BY_ID,
  data,
});

export const getHoodTagCategoryUpdateRequest = (data) => ({
  type: GET_HOOD_TAG_CATEGORY_UPDATE_REQUEST,
  data,
});

export const saveHoodTagCategoryUpdateSuccess = () => ({
  type: SAVE_HOOD_TAG_CATEGORY_UPDATE_SUCCESS,
});

export const getHoodTagUpdateRequest = (data) => ({
  type: GET_HOOD_TAG_UPDATE_REQUEST,
  data,
});

export const saveHoodTagUpdateSuccess = () => ({
  type: SAVE_HOOD_TAG_UPDATE_SUCCESS,
});

export const getHoodCategoryAddRequest = (data) => ({
  type: GET_HOOD_CATEGORY_ADD_REQUEST,
  data,
});

export const saveHoodCategoryAddSuccess = () => ({
  type: SAVE_HOOD_CATEGORY_ADD_SUCCESS,
});

export const getHoodRuleAddRequest = (data) => ({
  type: GET_HOOD_RULE_ADD_REQUEST,
  data,
});

export const saveHoodRuleAddSuccess = () => ({
  type: SAVE_HOOD_RULE_ADD_SUCCESS,
});

export const getHoodTagCategoryAddRequest = (data) => ({
  type: GET_HOOD_TAG_CATEGORY_ADD_REQUEST,
  data,
});

export const saveHoodTagCategoryAddSuccess = () => ({
  type: SAVE_HOOD_TAG_CATEGORY_ADD_SUCCESS,
});

export const getHoodTagAddRequest = (data) => ({
  type: GET_HOOD_TAG_ADD_REQUEST,
  data,
});

export const saveHoodTagAddSuccess = () => ({
  type: SAVE_HOOD_TAG_ADD_SUCCESS,
});

export const getHoodCategoryStatusUpdateRequest = (data) => ({
  type: GET_HOOD_CATEGORY_STATUS_UPDATE_REQUEST,
  data,
});

export const saveHoodCategoryStatusUpdateSuccess = () => ({
  type: SAVE_HOOD_CATEGORY_STATUS_UPDATE_SUCCESS,
});

export const getHoodRuleStatusUpdateRequest = (data) => ({
  type: GET_HOOD_RULE_STATUS_UPDATE_REQUEST,
  data,
});

export const saveHoodRuleStatusUpdateSuccess = () => ({
  type: SAVE_HOOD_RULE_STATUS_UPDATE_SUCCESS,
});

export const getHoodTagCategoryStatusUpdateRequest = (data) => ({
  type: GET_HOOD_TAG_CATEGORY_STATUS_UPDATE_REQUEST,
  data,
});

export const saveHoodTagCategoryStatusUpdateSuccess = () => ({
  type: SAVE_HOOD_TAG_CATEGORY_STATUS_UPDATE_SUCCESS,
});

export const getHoodTagStatusUpdateRequest = (data) => ({
  type: GET_HOOD_TAG_STATUS_UPDATE_REQUEST,
  data,
});

export const saveHoodTagStatusUpdateSuccess = () => ({
  type: SAVE_HOOD_TAG_STATUS_UPDATE_SUCCESS,
});

export const getHoodCategoryDeleteRequest = (data) => ({
  type: GET_HOOD_CATEGORY_DELETE_REQUEST,
  data,
});

export const saveHoodCategoryDeleteSuccess = () => ({
  type: SAVE_HOOD_CATEGORY_DELETE_SUCCESS,
});

export const getHoodRuleDeleteRequest = (data) => ({
  type: GET_HOOD_RULE_DLETE_REQUEST,
  data,
});

export const saveHoodRuleDeleteSuccess = () => ({
  type: SAVE_HOOD_RULE_DLETE_SUCCESS,
});

export const getHoodTagCategoryDeleteRequest = (data) => ({
  type: GET_HOOD_TAG_CATEGORY_DELETE_REQUEST,
  data,
});

export const saveHoodTagCategoryDeleteSuccess = () => ({
  type: SAVE_HOOD_TAG_CATEGORY_DELETE_SUCCESS,
});

export const getHoodTagDeleteRequest = (data) => ({
  type: GET_HOOD_TAG_DELETE_REQUEST,
  data,
});

export const saveHoodTagDeleteSuccess = () => ({
  type: SAVE_HOOD_TAG_DELETE_SUCCESS,
});

export const getHoodsList = (pageIndex, pageSize, searchKey,categoryCode) => ({
  type: GET_HOODS_LIST,
  pageIndex, pageSize, searchKey,categoryCode
});

export const saveHoodsList = (data) => ({
  type: SAVE_HOODS_LIST,
  data,
});

export const getHoodById = (id) => ({
  type: GET_HOOD_BY_ID,
  id,
});

export const saveHoodById = (data) => ({
  type: SAVE_HOOD_BY_ID,
  data,
});

export const getPostListByHoodId = (data, callback) => ({
  type: GET_POSTS_LIST_BY_HOOD_ID,
  data, callback,
});

export const savePostListByHoodId = (data) => ({
  type: SAVE_POSTS_LIST_BY_HOOD_ID,
  data,
});

export const clearHoodData = () => ({
  type: CLEAR_HOOD_DATA_REQUEST,
});

export const clearHoodDataSuccess = () => ({
  type: CLEAR_HOOD_DATA_SUCCESS,
});

export const clearPostList = () => ({
  type: CLEAR_POST_LIST_REQUEST,
});

export const clearPostListSuccess = () => ({
  type: CLEAR_POST_LIST_SUCCESS,
});

export const updateHoodDetails = (updateData,navigate) => ({
 type: UPDATE_HOODS_DETAILS,
 updateData,
 navigate
})