import { call, put, takeLatest } from "redux-saga/effects";
import {
  saveLabelLanguageAddSuccess,
  saveLabelLanguageById,
  saveLabelLanguageDeleteSuccess,
  saveLabelLanguageList,
  saveLabelLanguageUpdateSuccess,
  saveLabelLanguageStatusUpdateSuccess,
} from "../actions/labelLanguage";
import {
  GET_LABEL_LANGUAGE_ADD_REQUEST,
  SAVE_LABEL_LANGUAGE_ADD_SUCCESS,
  GET_LABEL_LANGUAGE_BY_ID,
  SAVE_LABEL_LANGUAGE_BY_ID,
  GET_LABEL_LANGUAGE_DELETE_REQUEST,
  SAVE_LABEL_LANGUAGE_DELETE_SUCCESS,
  GET_LABEL_LANGUAGE_UPDATE_REQUEST,
  SAVE_LABEL_LANGUAGE_UPDATE_SUCCESS,
  GET_LABEL_LANGUAGE_LIST,
  SAVE_LABEL_LANGUAGE_LIST,
  GET_LABEL_LANGUAGE_STATUS_UPDATE_REQUEST,
  SAVE_LABEL_LANGUAGE_STATUS_UPDATE_SUCCESS,
} from "../types/action-type";
import { get as _get } from "lodash";
import mias from "../api";
import toast from "react-hot-toast";

function* getLabelLanguageList({ pageIndex, pageSize, searchKey }) {
  try {
    const response = yield call(
      mias.apis.labelLanguage.getLabelLanguageList,
      pageIndex,
      pageSize,
      searchKey
    );
    response.data.data.pageIndex = pageIndex;
    response.data.data.pageSize = pageSize;

    yield put(saveLabelLanguageList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getLabelLanguageList saga error ::", error);
  }
}

function* getLabelLanguageById({ key }) {
  try {
    const response = yield call(
      mias.apis.labelLanguage.getLabelLanguageById,
      key
    );
    console.warn("getLabelLanguageById ::", response);
    yield put(saveLabelLanguageById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getLabelLanguageById saga error ::", error);
  }
}

function* updateLabelLanguage(data) {
  try {
    yield call(mias.apis.labelLanguage.updateLabelLanguage, data.data);
    yield put(saveLabelLanguageUpdateSuccess());
    const response = yield call(
      mias.apis.labelLanguage.getLabelLanguageList,
      data.data.pageIndex,
      data.data.pageSize
    );
    response.data.data.pageIndex = data.data.pageIndex;
    response.data.data.pageSize = data.data.pageSize;
    yield put(saveLabelLanguageList(_get(response, "data.data", [])));
    toast.success("label language updated!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("LabelLanguage saga error ::", error);
  }
}

function* updateLabelLanguageStatus(data) {
  try {
    yield call(mias.apis.labelLanguage.updateLabelLanguageStatus, data.data);
    yield put(saveLabelLanguageStatusUpdateSuccess());
    const response = yield call(mias.apis.labelLanguage.getLabelLanguageList);
    yield put(saveLabelLanguageList(_get(response, "data.data", [])));
    toast.success("label language Status updated!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("LabelLanguage saga error ::", error);
  }
}

function* createLabelLanguage(data) {
  try {
    yield call(mias.apis.labelLanguage.createLabelLanguage, data.data);
    yield put(saveLabelLanguageAddSuccess());
    const response = yield call(
      mias.apis.labelLanguage.getLabelLanguageList,
      1,
      30
    );
    yield put(saveLabelLanguageList(_get(response, "data.data", [])));
    toast.success("labelLanguage Created!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("labelLanguage saga error ::", error);
  }
}

function* deleteLabelLanguage(data) {
  try {
    const apiResp = yield call(
      mias.apis.labelLanguage.deleteLabelLanguage,
      data.data
    );
    yield put(saveLabelLanguageDeleteSuccess());
    const response = yield call(
      mias.apis.labelLanguage.getLabelLanguageList,
      1,
      30
    );
    yield put(saveLabelLanguageList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("deletelabelLanguage saga error ::", error);
  }
}

export default function* labelLanguage() {
  yield takeLatest(GET_LABEL_LANGUAGE_LIST, getLabelLanguageList);
  yield takeLatest(SAVE_LABEL_LANGUAGE_LIST, saveLabelLanguageList);
  yield takeLatest(GET_LABEL_LANGUAGE_BY_ID, getLabelLanguageById);
  yield takeLatest(SAVE_LABEL_LANGUAGE_BY_ID, saveLabelLanguageById);
  yield takeLatest(GET_LABEL_LANGUAGE_UPDATE_REQUEST, updateLabelLanguage);
  yield takeLatest(
    GET_LABEL_LANGUAGE_STATUS_UPDATE_REQUEST,
    updateLabelLanguageStatus
  );
  yield takeLatest(
    SAVE_LABEL_LANGUAGE_UPDATE_SUCCESS,
    saveLabelLanguageUpdateSuccess
  );
  yield takeLatest(
    SAVE_LABEL_LANGUAGE_STATUS_UPDATE_SUCCESS,
    saveLabelLanguageStatusUpdateSuccess
  );
  yield takeLatest(GET_LABEL_LANGUAGE_ADD_REQUEST, createLabelLanguage);
  yield takeLatest(
    SAVE_LABEL_LANGUAGE_ADD_SUCCESS,
    saveLabelLanguageAddSuccess
  );
  yield takeLatest(GET_LABEL_LANGUAGE_DELETE_REQUEST, deleteLabelLanguage);
  yield takeLatest(
    SAVE_LABEL_LANGUAGE_DELETE_SUCCESS,
    saveLabelLanguageDeleteSuccess
  );
}
