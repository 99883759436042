import { call, put, takeLatest } from "redux-saga/effects";
import {
  saveLanguageList,
  saveLanguageSuccess,
  updateLanguageSuccess,
  deleteLanguageSuccess,
  updateLanguageStatusSuccess,
} from "../actions/language";
import mias from "../api";
import { get as _get } from "lodash";
import {
  DELETE_LANGUAGE,
  GET_LANGUAGE_ADD_REQUEST,
  GET_LANGUAGE_LIST,
  GET_LANGUAGE_STATUS_UPDATE_REQUEST,
  SAVE_LANGUAGE,
  UPDATE_LANGUAGE,
} from "../types/action-type";
import toast from "react-hot-toast";

function* getLanguageList() {
  try {
    const response = yield call(mias.apis.language.getLanguageList);
    // console.log("check Language list ::", response);
    yield put(saveLanguageList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get Language list saga error ::", err);
  }
}

function* SaveLanguage(action) {
  try {
    yield call(mias.apis.language.saveLanguage, action.data);
    yield put(saveLanguageSuccess());
    const response = yield call(mias.apis.language.getLanguageList);
    yield put(saveLanguageList(_get(response, "data.data", [])));
    toast.success("Language Created!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("error: ", error);
  }
}
function* UpdateLanguage(action) {
  try {
    yield call(mias.apis.language.updateLanguage, action.data);
    yield put(updateLanguageSuccess());
    const response = yield call(mias.apis.language.getLanguageList);
    yield put(saveLanguageList(_get(response, "data.data", [])));
    toast.success("Language Updated!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("error: ", error);
  }
}

function* UpdateLanguageStatus(action) {
  try {
    yield call(mias.apis.language.updateLanguageStatus, action.data);
    yield put(updateLanguageStatusSuccess());
    const response = yield call(mias.apis.language.getLanguageList);
    yield put(saveLanguageList(_get(response, "data.data", [])));
    toast.success("Language status Updated!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("error: ", error);
  }
}

function* DeleteLanguage(id) {
  try {
    yield call(mias.apis.language.deleteLanguage, id);
    yield put(deleteLanguageSuccess);
    const response = yield call(mias.apis.language.getLanguageList);
    yield put(saveLanguageList(_get(response, "data.data", [])));
    toast.success("Language Updated!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("error: ", error);
  }
}

export default function* language() {
  yield takeLatest(GET_LANGUAGE_LIST, getLanguageList);
  yield takeLatest(GET_LANGUAGE_ADD_REQUEST, SaveLanguage);
  yield takeLatest(SAVE_LANGUAGE, SaveLanguage);
  yield takeLatest(UPDATE_LANGUAGE, UpdateLanguage);
  yield takeLatest(DELETE_LANGUAGE, DeleteLanguage);
  yield takeLatest(GET_LANGUAGE_STATUS_UPDATE_REQUEST, UpdateLanguageStatus);
}
