import { GET_ROLES_LIST, SAVE_ROLES_LIST } from "../types/action-type";

export const getRolesList = () => ({
  type: GET_ROLES_LIST,
});

export const saveRolesList = (data) => ({
  type: SAVE_ROLES_LIST,
  data,
});
