
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import colors from "../../../../assets/theme/base/colors";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
const { white, gradients, grey, transparent } = colors;
function ComplexStatisticsCard({ color, title, count, percentage, icon,to }) {
  const navigate=useNavigate()
  return (
    <Tooltip title={`View ${title}`} placement="top-middle">
    <Card onClick={()=>navigate(to)}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={3} py={3} 
          sx={{ cursor: "pointer" }}>
        <MDBox
          variant="contained"
          bgColor="#fde4db"
          color={color === "light" ? "dark" : "white"}
          // color='dark'
          coloredShadow={color}
          borderRadius="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
        >
          <Icon fontSize="large" style={{color:"#F05A22"}}>
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          
          <MDTypography variant="button" fontWeight="medium" color="grey[100]"  >
            {title}
          </MDTypography>
          <MDTypography variant="h4" color="black">{count}</MDTypography>
        </MDBox>
      </MDBox>
      {/* <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.amount}
          </MDTypography>
          &nbsp;{percentage.label}
        </MDTypography>
      </MDBox> */}
    </Card>
    </Tooltip>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "purple",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
