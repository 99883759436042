
// Material Dashboard 2 React base styles
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";

const { borderRadius } = borders;
const { xxl } = boxShadows;

const dialog = {
  styleOverrides: {
    paper: {
      // borderRadius: borderRadius.xl,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
