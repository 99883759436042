
// Material Dashboard 2 React base styles
import borders from "../base/borders";

const { borderRadius } = borders;

const avatar = {
  styleOverrides: {
    root: {
      transition: "all 200ms ease-in-out",
    },

    rounded: {
      borderRadius: borderRadius.lg,
    },

    img: {
      height: "100%",
      width: "100%",
      maxWidth: "100%"
    },
  },
};

export default avatar;
