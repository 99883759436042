import { GET_POSTS_LISTS, DELETE_POSTS_COMMENT_BY_ID,DELETE_POSTS_BY_ID,SAVE_POSTS_LISTS,GET_POSTS_BY_ID,SAVE_POSTS_BY_ID, GET_TAG_CATEGORY, SAVE_TAG_CATEGORY, UPDATE_POSTS_DETAILS} from "../types/action-type";
import _get from "lodash/get";
import { takeLatest, call, put } from "redux-saga/effects";
import mias from "../api/index";
import { savePostsList,savePostsById,saveTagCategoryList } from "../actions/posts";
import { toast } from "react-hot-toast";

function* getPostsList({pageIndex, pageSize, searchKey}) {
    try {
        const response = yield call(mias.apis.posts.getPostList, pageIndex, pageSize, searchKey);
        response.data.data.pageIndex = pageIndex;
        response.data.data.pageSize = pageSize;
        yield put(savePostsList(_get(response, "data.data", [])));
    } catch (error) {
        toast.error(_get(error,"response.data.message",_get(error,"message","")))
        console.log("error in getPostsList", error);
    }
}
function* getPostsListById({data}) {
    try {
        const id=data;
        const response = yield call(mias.apis.posts.getPostById, id);
        yield put(savePostsById(_get(response, "data.data", [])));
    } catch (error) {
        console.log("error in getPostsListById", error);
    }
}
function* getTagCategoryList() {
    try {
        const response = yield call(mias.apis.posts.getCategoryList);
        yield put(saveTagCategoryList(_get(response, "data.data", [])));
    } catch (error) {
        console.log("error in getTagCategoryList", error);
    }
}
function* updatePostDetails({updateData, navigate }){
    try{
        const response=yield call(mias.apis.posts.updatePosts,updateData);
        toast.success(_get(response, "data.message"));
        navigate("/posts")
    }catch(error){
        toast.error(_get(error,"response.data.message",_get(error,"message","")))
        console.log("error in updatePostDetails",error);
    }
}
function* deletePostById({ id }) {
    try {
        const response = yield call(mias.apis.posts.deletePostsById,id);
        toast.success("Post deleted successfully",5000);
        const api= yield call(mias.apis.posts.getPostList, 1, 30, "");
        yield put(savePostsList(_get(api, "data.data", [])));
    } catch (err) {
        toast.error(_get(err, "response.data.message", _get(err, "message", "")));
    }
}
function* deletePostsCommentsById({ id,postId }) {
    try {
        const response = yield call(mias.apis.posts.deletePostsCommentById,id);
        toast.success("Comment deleted successfully", 5000);
        const api = yield call(mias.apis.posts.getPostById, postId);
        yield put(savePostsById(_get(api, "data.data", [])));
    } catch (err) {
        toast.error(_get(err, "response.data.message", _get(err, "message", "")));
    }
}
export default function* posts() {
    yield takeLatest(GET_POSTS_LISTS, getPostsList);
    yield takeLatest(SAVE_POSTS_LISTS, savePostsList);
    yield takeLatest(GET_POSTS_BY_ID,getPostsListById);
    yield takeLatest(SAVE_POSTS_BY_ID,savePostsById);
    yield takeLatest(GET_TAG_CATEGORY,getTagCategoryList);
    yield takeLatest(SAVE_TAG_CATEGORY,saveTagCategoryList)
    yield takeLatest(UPDATE_POSTS_DETAILS, updatePostDetails)
    yield takeLatest(DELETE_POSTS_BY_ID, deletePostById);
    yield takeLatest(DELETE_POSTS_COMMENT_BY_ID, deletePostsCommentsById);
}
