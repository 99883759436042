import { reverse as _reverse } from "lodash";
import {
  SAVE_HOOD_CATEGORY_BY_ID,
  SAVE_HOOD_CATEGORY_LIST,
  SAVE_HOOD_RULES_LIST,
  SAVE_HOOD_RULE_BY_ID,
  SAVE_HOOD_TAGS_LIST,
  SAVE_HOOD_TAG_CATEGORY_LIST,
  SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN,
  SAVE_HOOD_TAG_CATEGORY_BY_ID,
  SAVE_HOOD_TAG_BY_ID,
  SAVE_HOODS_LIST,
  SAVE_HOOD_BY_ID,
  SAVE_POSTS_LIST_BY_HOOD_ID,
  CLEAR_HOOD_DATA_SUCCESS,
  CLEAR_POST_LIST_SUCCESS,
} from "../types/action-type";
const initialState = {
  hoodCategoryList: [],
  hoodCategoryListForDropdown:[],
  hoodRulesList: [],
  loading: false,
  error: null,
  hoodCategoryData: {},
  hoodRuleData: {},
  hoodTagCategoryList: [],
  hoodTagsList: [],
  hoodTagData: {},
  hoodsList: [],
  hoodData: {},
  postList: [],
};
export default function hoodsReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_HOOD_CATEGORY_LIST:
      return {
        ...state,
        loading: true,
        hoodCategoryList: _reverse(action.data),
      };
    case SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN:
      return {
        ...state,
        loading: true,
        hoodCategoryListForDropdown: _reverse(action.data),
      };
    case SAVE_HOOD_RULES_LIST:
      return {
        ...state,
        loading: true,
        hoodRulesList: _reverse(action.data),
      };
    case SAVE_HOOD_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
        hoodCategoryData: action.data,
      };
    case SAVE_HOOD_RULE_BY_ID:
      return {
        ...state,
        loading: true,
        hoodRuleData: action.data,
      };
    case SAVE_HOOD_TAG_CATEGORY_LIST:
      return {
        ...state,
        loading: true,
        hoodTagCategoryList: _reverse(action.data),
      };
    case SAVE_HOOD_TAG_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
        hoodTagCategoryData: action.data,
      };
    case SAVE_HOOD_TAGS_LIST:
      return {
        ...state,
        loading: true,
        hoodTagsList: _reverse(action.data),
      };
    case SAVE_HOOD_TAG_BY_ID:
      return {
        ...state,
        loading: true,
        hoodTagData: action.data,
      };
    case SAVE_HOODS_LIST:
      return {
        ...state,
        hoodsList: action.data,
      }
    case SAVE_HOOD_BY_ID:
      return {
        ...state,
        hoodData: action.data,
      }
    case SAVE_POSTS_LIST_BY_HOOD_ID:
      return {
        ...state,
        postList: [...state?.postList, ...action.data],
      }
    case CLEAR_HOOD_DATA_SUCCESS:
      return {
        ...state,
        hoodData: {}
      }
    case CLEAR_POST_LIST_SUCCESS:
      return {
        ...state,
        postList: [],
      }
    default:
      return state;
  }
}
