import Grid from "@mui/material/Grid";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import { AppBar, Divider, Icon, Tab, Tabs } from "@mui/material";
import Card from "@mui/material/Card";
import TabPanel from "./../tabPanel";
import { useState } from "react";

import PlaceCategories from "./placeCategories";
import Places from "./places";

import colors from "../../../assets/theme/base/colors";
const { grey, white, orange } = colors;



const PlaceSettings = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  return (
   <DashboardLayout>
    <DashboardNavbar />
    
    <MDBox pt={3}>
        <Card>  
          <MDBox borderBottom="1px solid #dee2e6">
            <AppBar position="static" > 
              <Tabs
                variant="standard"
                orientation={"horizontal"}
                value={tabValue}
                onChange={handleSetTabValue}
                textColor="primary"
                // indicatorColor="secondary"
              >
                <Tab
                  label="PlaceCategories"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      place
                    </Icon>
                  }
                />
                <Tab
                  label="PlaceSubCategory"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      place
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </MDBox>
          
          <TabPanel value={tabValue} index={0}>
            <PlaceCategories />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Places />
          </TabPanel>
        </Card>
      </MDBox>
    
    </DashboardLayout>
  );
};

export default PlaceSettings;
