export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const SAVE_LOGIN_USER_DATA = "SAVE_LOGIN_USER_DATA";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const GET_VALIDATE_USER = "GET_VALIDATE_USER";
export const CREATE_ADMIN_USER_REQUEST = "CREATE_ADMIN_USER_REQUEST";
export const GET_USER_LIST = "GET_USER_LIST";
export const SAVE_USER_LIST = "SAVE_USER_LIST";
export const GET_CMS_LIST = "GET_CMS_LIST";
export const SAVE_CMS_LIST = "SAVE_CMS_LIST";
export const GET_CMS = "GET_CMS";
export const SAVE_CMS = "SAVE_CMS";
export const CLEAR_CMS_REQUEST = "CLEAR_CMS_REQUEST";
export const CLEAR_CMS_SUCCESS = "CLEAR_CMS_SUCCESS";
export const SAVE_CMS_DATA_REQUEST = "SAVE_CMS_DATA_REQUEST";
export const SAVE_CMS_DATA_SUCESS = "SAVE_CMS_DATA_SUCESS";
export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const SAVE_LANGUAGE_LIST = "SAVE_LANGUAGE_LIST";
export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const SAVE_ADMIN_LIST = "SAVE_ADMIN_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const SAVE_COUNTRY_LIST = "SAVE_COUNTRY_LIST";
export const GET_COUNTRY_FORM_DATA = "GET_COUNTRY_FORM_DATA";
export const SAVE_COUNTRY_FORM_DATA = "SAVE_COUNTRY_FORM_DATA";
export const GET_COUNTRY_BY_ID = "GET_COUNTRY_BY_ID";
export const SAVE_COUNTRY_BY_ID = "SAVE_COUNTRY_BY_ID";
export const CLEAR_COUNTRY_REQUEST = "CLEAR_COUNTRY_REQUEST";
export const CLEAR_COUNTRY_SUCCESS = "CLEAR_COUNTRY_SUCCESS";
export const GET_HOOD_CATEGORY_LIST = "GET_HOOD_CATEGORY_LIST";
export const SAVE_HOOD_CATEGORY_LIST = "SAVE_HOOD_CATEGORY_LIST";
export const GET_HOOD_CATEGORY_LIST_FOR_DROPDOWN =
  "GET_HOOD_CATEGORY_LIST_FOR_DROPDOWN";
export const SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN =
  "SAVE_HOOD_CATEGORY_LIST_FOR_DROPDOWN";
export const GET_HOOD_RULES_LIST = "GET_HOOD_RULES_LIST";
export const SAVE_HOOD_RULES_LIST = "SAVE_HOOD_RULES_LIST";
export const GET_HOOD_CATEGORY_BY_ID = "GET_HOOD_CATEGORY_BY_ID";
export const SAVE_HOOD_CATEGORY_BY_ID = "SAVE_HOOD_CATEGORY_BY_ID";
export const GET_HOOD_RULE_BY_ID = "GET_HOOD_RULE_BY_ID";
export const SAVE_HOOD_RULE_BY_ID = "SAVE_HOOD_RULE_BY_ID";
export const GET_HOOD_CATEGORY_UPDATE_REQUEST =
  "GET_HOOD_CATEGORY_UPDATE_REQUEST";
export const SAVE_HOOD_CATEGORY_UPDATE_SUCCESS =
  "SAVE_HOOD_CATEGORY_UPDATE_SUCCESS";
export const GET_HOOD_RULE_UPDATE_REQUEST = "  GET_HOOD_RULE_UPDATE_REQUEST";
export const SAVE_HOOD_RULE_UPDATE_SUCCESS = "  SAVE_HOOD_RULE_UPDATE_SUCCESS";
export const GET_HOOD_TAG_CATEGORY_LIST = "GET_HOOD_TAG_CATEGORY_LIST";
export const SAVE_HOOD_TAG_CATEGORY_LIST = "SAVE_HOOD_TAG_CATEGORY_LIST";
export const GET_HOOD_TAGS_LIST = "GET_HOOD_TAGS_LIST";
export const SAVE_HOOD_TAGS_LIST = "SAVE_HOOD_TAGS_LIST";
export const GET_HOOD_TAG_CATEGORY_BY_ID = "GET_HOOD_TAG_CATEGORY_BY_ID";
export const SAVE_HOOD_TAG_CATEGORY_BY_ID = "SAVE_HOOD_TAG_CATEGORY_BY_ID";
export const GET_HOOD_TAG_BY_ID = "GET_HOOD_TAG_BY_ID";
export const SAVE_HOOD_TAG_BY_ID = "SAVE_HOOD_TAG_BY_ID";
export const GET_HOOD_TAG_CATEGORY_UPDATE_REQUEST =
  "GET_HOOD_TAG_CATEGORY_UPDATE_REQUEST";
export const SAVE_HOOD_TAG_CATEGORY_UPDATE_SUCCESS =
  "SAVE_HOOD_TAG_CATEGORY_UPDATE_SUCCESS";
export const GET_HOOD_TAG_UPDATE_REQUEST = "GET_HOOD_TAG_UPDATE_REQUEST";
export const SAVE_HOOD_TAG_UPDATE_SUCCESS = "SAVE_HOOD_TAG_UPDATE_SUCCESS";
export const GET_HOOD_CATEGORY_ADD_REQUEST = "GET_HOOD_CATEGORY_ADD_REQUEST";
export const SAVE_HOOD_CATEGORY_ADD_SUCCESS = "SAVE_HOOD_CATEGORY_ADD_SUCCESS";
export const GET_HOOD_RULE_ADD_REQUEST = "GET_HOOD_RULE_ADD_REQUEST";
export const SAVE_HOOD_RULE_ADD_SUCCESS = "SAVE_HOOD_RULE_ADD_SUCCESS";
export const GET_HOOD_TAG_CATEGORY_ADD_REQUEST =
  "GET_HOOD_TAG_CATEGORY_ADD_REQUEST";
export const SAVE_HOOD_TAG_CATEGORY_ADD_SUCCESS =
  "SAVE_HOOD_TAG_CATEGORY_ADD_SUCCESS";
export const GET_HOOD_TAG_ADD_REQUEST = "GET_HOOD_TAG_ADD_REQUEST";
export const SAVE_HOOD_TAG_ADD_SUCCESS = "SAVE_HOOD_TAG_ADD_SUCCESS";
export const GET_HOOD_CATEGORY_STATUS_UPDATE_REQUEST =
  "GET_HOOD_CATEGORY_STATUS_UPDATE_REQUEST";
export const SAVE_HOOD_CATEGORY_STATUS_UPDATE_SUCCESS =
  "SAVE_HOOD_CATEGORY_STATUS_UPDATE_SUCCESS";
export const GET_HOOD_RULE_STATUS_UPDATE_REQUEST =
  "GET_HOOD_RULE_STATUS_UPDATE_REQUEST";
export const SAVE_HOOD_RULE_STATUS_UPDATE_SUCCESS =
  "SAVE_HOOD_RULE_STATUS_UPDATE_SUCCESS";
export const GET_HOOD_TAG_CATEGORY_STATUS_UPDATE_REQUEST =
  "GET_HOOD_TAG_CATEGORY_STATUS_UPDATE_REQUEST";
export const SAVE_HOOD_TAG_CATEGORY_STATUS_UPDATE_SUCCESS =
  "SAVE_HOOD_TAG_CATEGORY_STATUS_UPDATE_SUCCESS";
export const GET_HOOD_TAG_STATUS_UPDATE_REQUEST =
  "GET_HOOD_TAG_STATUS_UPDATE_REQUEST";
export const SAVE_HOOD_TAG_STATUS_UPDATE_SUCCESS =
  "SAVE_HOOD_TAG_STATUS_UPDATE_SUCCESS";
export const GET_HOOD_CATEGORY_DELETE_REQUEST =
  "GET_HOOD_CATEGORY_DELETE_REQUEST";
export const SAVE_HOOD_CATEGORY_DELETE_SUCCESS =
  "SAVE_HOOD_CATEGORY_DELETE_SUCCESS";
export const GET_HOOD_RULE_DLETE_REQUEST = "GET_HOOD_RULE_DLETE_REQUEST";
export const SAVE_HOOD_RULE_DLETE_SUCCESS = "SAVE_HOOD_RULE_DLETE_SUCCESS";
export const GET_HOOD_TAG_CATEGORY_DELETE_REQUEST =
  "GET_HOOD_TAG_CATEGORY_DELETE_REQUEST";
export const SAVE_HOOD_TAG_CATEGORY_DELETE_SUCCESS =
  "SAVE_HOOD_TAG_CATEGORY_DELETE_SUCCESS";
export const GET_HOOD_TAG_DELETE_REQUEST = "GET_HOOD_TAG_DELETE_REQUEST";
export const SAVE_HOOD_TAG_DELETE_SUCCESS = "SAVE_HOOD_TAG_DELETE_SUCCESS";
export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const SAVE_ROLES_LIST = "SAVE_ROLES_LIST";
export const GET_APP_USER_BY_ID = "GET_APP_USER_BY_ID";
export const UPDATE_APP_USER_BY_ID = "UPDATE_APP_USER_BY_ID";
export const SAVE_APP_USER_BY_ID = "SAVE_APP_USER_BY_ID";
export const GET_ADMIN_USER_BY_ID = "GET_ADMIN_USER_BY_ID";
export const SAVE_ADMIN_USER_BY_ID = "SAVE_ADMIN_USER_BY_ID";
export const CLEAR_ADMIN_DATA_REQUEST = "CLEAR_ADMIN_DATA_REQUEST";
export const CLEAR_ADMIN_DATA_SUCCESS = "CLEAR_ADMIN_DATA_SUCCESS";
export const GET_HOODS_LIST = "GET_HOODS_LIST";
export const SAVE_HOODS_LIST = "SAVE_HOODS_LIST";
export const UPDATE_HOODS_DETAILS = "UPDATE_HOODS_DETAILS";
export const UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER";
export const GET_HOOD_BY_ID = "GET_HOOD_BY_ID";
export const SAVE_HOOD_BY_ID = "SAVE_HOOD_BY_ID";
export const GET_POSTS_LIST_BY_HOOD_ID = "GET_POSTS_LIST_BY_HOOD_ID";
export const SAVE_POSTS_LIST_BY_HOOD_ID = "SAVE_POSTS_LIST_BY_HOOD_ID";
export const CLEAR_HOOD_DATA_REQUEST = "CLEAR_HOOD_DATA_REQUEST";
export const CLEAR_HOOD_DATA_SUCCESS = "CLEAR_HOOD_DATA_SUCCESS";
export const CLEAR_POST_LIST_REQUEST = "CLEAR_POST_LIST_REQUEST";
export const CLEAR_POST_LIST_SUCCESS = "CLEAR_POST_LIST_SUCCESS";
export const GET_COUNTS_FOR_DASHBOARD = "GET_COUNTS_FOR_DASHBOARD";
export const SAVE_COUNTS_FOR_DASHBOARD = "SAVE_COUNTS_FOR_DASHBOARD";
export const GET_MIA_INTEREST_LIST = "GET_MIA_INTEREST_LIST";
export const SAVE_MIA_INTEREST_LIST = "SAVE_MIA_INTEREST_LIST";
export const GET_MIA_INTEREST_STATUS_UPDATE_REQUEST =
  "GET_MIA_INTEREST_STATUS_UPDATE_REQUEST";
export const SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE =
  "SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE";
export const GET_ADD_MIA_INTEREST_REQUEST = "GET_ADD_MIA_INTEREST_REQUEST";
export const SAVE_ADD_MIA_INTEREST_RESPONSE = "SAVE_ADD_MIA_INTEREST_RESPONSE";
export const GET_MIA_INTEREST_DELETE_REQUEST =
  "GET_MIA_INTEREST_DELETE_REQUEST";
export const SAVE_MIA_INTEREST_DELETE_SUCCESS =
  "SAVE_MIA_INTEREST_DELETE_SUCCESS";
export const GET_MIA_INTEREST_BY_ID = "GET_MIA_INTEREST_BY_ID";
export const SAVE_MIA_INTEREST_BY_ID = "SAVE_MIA_INTEREST_BY_ID";
export const GET_MIA_BESTFITS_LIST = "GET_MIA_BESTFITS_LIST";
export const SAVE_MIA_BESTFITS_LIST = "SAVE_MIA_BESTFITS_LIST";
export const GET_MIA_BESTFIT_STATUS_UPDATE_REQUEST =
  "GET_MIA_BESTFIT_STATUS_UPDATE_REQUEST";
export const SAVE_MIA_BESTFIT_STATUS_UPDATE_RESPONSE =
  "SAVE_MIA_BESTFIT_STATUS_UPDATE_RESPONSE";
export const GET_ADD_MIA_BESTFIT_REQUEST = "GET_ADD_MIA_BESTFIT_REQUEST";
export const SAVE_ADD_MIA_BESTFIT_RESPONSE = "SAVE_ADD_MIA_BESTFIT_RESPONSE";
export const GET_MIA_BESTFIT_BY_ID = "GET_MIA_BESTFIT_BY_ID";
export const SAVE_MIA_BESTFIT_BY_ID = "SAVE_MIA_BESTFIT_BY_ID";
export const GET_MIA_BESTFIT_DELETE_REQUEST = "GET_MIA_BESTFIT_DELETE_REQUEST";
export const SAVE_MIA_BESTFIT_DELETE_SUCCESS =
  "SAVE_MIA_BESTFIT_DELETE_SUCCESS";
export const GET_PLACE_CATEGORY_LIST = "GET_PLACE_CATEGORY_LIST";
export const SAVE_PLACE_CATEGORY_LIST = "SAVE_PLACE_CATEGORY_LIST";
export const GET_PLACE_CATEGORY_LIST_FOR_DROPDOWN =
  "GET_PLACE_CATEGORY_LIST_FOR_DROPDOWN";
export const SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN =
  "SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN";
export const GET_PLACE_CATEGORY_BY_ID = "GET_PLACE_CATEGORY_BY_ID";
export const SAVE_PLACE_CATEGORY_BY_ID = "SAVE_PLACE_CATEGORY_BY_ID";
export const GET_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN =
  "GET_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN";
export const SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN =
  "SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN";
export const GET_PLACE_CATEGORY_UPDATE_REQUEST =
  "GET_PLACE_CATEGORY_UPDATE_REQUEST";
export const SAVE_PLACE_CATEGORY_UPDATE_SUCCESS =
  "SAVE_PLACE_CATEGORY_UPDATE_SUCCESS";
export const GET_PLACE_CATEGORY_ADD_REQUEST = "GET_PLACE_CATEGORY_ADD_REQUEST";
export const SAVE_PLACE_CATEGORY_ADD_SUCCESS =
  "SAVE_PLACE_CATEGORY_ADD_SUCCESS";
export const GET_PLACE_CATEGORY_STATUS_UPDATE_REQUEST =
  "GET_PLACE_CATEGORY_STATUS_UPDATE_REQUEST";
export const SAVE_PLACE_CATEGORY_STATUS_UPDATE_SUCCESS =
  "SAVE_PLACE_CATEGORY_STATUS_UPDATE_SUCCESS";
export const GET_PLACE_CATEGORY_DELETE_REQUEST =
  "GET_PLACE_CATEGORY_DELETE_REQUEST";
export const SAVE_PLACE_CATEGORY_DELETE_SUCCESS =
  "SAVE_PLACE_CATEGORY_DELETE_SUCCESS";
export const GET_PLACE_TABLE_LIST = "GET_PLACE_TABLE_LIST";
export const SAVE_PLACES_LIST = "SAVE_PLACES_LIST";
export const GET_PLACE_DETAILS = "GET_PLACE_DETAILS";
export const SAVE_PLACE_DETAILS = "SAVE_PLACE_DETAILS";
export const UPDATE_PLACE_DETAILS = "UPDATE_PLACE_DETAILS";
export const GET_PLACE_LIST = "GET_PLACE_LIST";
export const SAVE_PLACE_LIST = "SAVE_PLACE_LIST";
export const GET_PLACE_BY_ID = "GET_PLACE_BY_ID";
export const SAVE_PLACE_BY_ID = "SAVE_PLACE_BY_ID";
export const CLEAR_PLACE_DATA_REQUEST = "CLEAR_PLACE_DATA_REQUEST";
export const CLEAR_PLACE_DATA_SUCCESS = "CLEAR_PLACE_DATA_SUCCESS";
export const GET_PLACE_TAG_CATEGORY_LIST = "GET_PLACE_TAG_CATEGORY_LIST";
export const SAVE_PLACE_TAG_CATEGORY_LIST = "SAVE_PLACE_TAG_CATEGORY_LIST";
export const SAVE_PLACE_TAG_CATEGORY_BY_ID = "SAVE_PLACE_TAG_CATEGORY_BY_ID";
export const GET_PLACE_TAG_CATEGORY_BY_ID = "GET_PLACE_TAG_CATEGORY_BY_ID";
export const GET_PLACE_TAG_CATEGORY_UPDATE_REQUEST =
  "GET_PLACE_TAG_CATEGORY_UPDATE_REQUEST";
export const SAVE_PLACE_TAG_CATEGORY_UPDATE_SUCCESS =
  "SAVE_PLACE_TAG_CATEGORY_UPDATE_SUCCESS";
export const GET_PLACE_TAG_CATEGORY_ADD_REQUEST =
  "GET_PLACE_TAG_CATEGORY_ADD_REQUEST";
export const SAVE_PLACE_TAG_CATEGORY_ADD_SUCCESS =
  "SAVE_PLACE_TAG_CATEGORY_ADD_SUCCESS";
export const GET_PLACE_TAG_CATEGORY_STATUS_UPDATE_REQUEST =
  "GET_PLACE_TAG_CATEGORY_STATUS_UPDATE_REQUEST";
export const SAVE_PLACE_TAG_CATEGORY_STATUS_UPDATE_SUCCESS =
  "SAVE_PLACE_TAG_CATEGORY_STATUS_UPDATE_SUCCESS";
export const GET_PLACE_TAG_CATEGORY_DELETE_REQUEST =
  "GET_PLACE_TAG_CATEGORY_DELETE_REQUEST";
export const SAVE_PLACE_TAG_CATEGORY_DELETE_SUCCESS =
  "SAVE_PLACE_TAG_CATEGORY_DELETE_SUCCESS";
export const GET_MIA_BESTFIT_UPDATE_REQUEST = "GET_MIA_BESTFIT_UPDATE_REQUEST";
export const SAVE_MIA_BESTFIT_UPDATE_RESPONSE =
  "SAVE_MIA_BESTFIT_UPDATE_RESPONSE";
export const GET_LABEL_LANGUAGE_LIST = "GET_LABEL_LANGUAGE_LIST";
export const SAVE_LABEL_LANGUAGE_LIST = "SAVE_LABEL_LANGUAGE_LIST";
export const GET_LABEL_LANGUAGE_BY_ID = "GET_LABEL_LANGUAGE_BY_ID";
export const SAVE_LABEL_LANGUAGE_BY_ID = "SAVE_LABEL_LANGUAGE_BY_ID";
export const GET_LABEL_LANGUAGE_ADD_REQUEST = "GET_LABEL_LANGUAGE_ADD_REQUEST";
export const SAVE_LABEL_LANGUAGE_ADD_SUCCESS =
  "SAVE_LABEL_LANGUAGE_ADD_SUCCESSS";
export const GET_LABEL_LANGUAGE_UPDATE_REQUEST =
  "GET_LABEL_LANGUAGE_UPDATE_REQUEST";
export const SAVE_LABEL_LANGUAGE_UPDATE_SUCCESS =
  "SAVE_LABEL_LANGUAGE_UPDATE_SUCCESSS";
export const GET_LABEL_LANGUAGE_DELETE_REQUEST =
  "GET_LABEL_LANGUAGE_DELETE_REQUEST";
export const SAVE_LABEL_LANGUAGE_DELETE_SUCCESS =
  "SAVE_LABEL_LANGUAGE_DELETE_SUCCESSS";
export const GET_LABEL_LANGUAGE_STATUS_UPDATE_REQUEST =
  "GET_LABEL_LANGUAGE_STATUS_UPDATE_REQUEST";
export const SAVE_LABEL_LANGUAGE_STATUS_UPDATE_SUCCESS =
  "SAVE_LABEL_LANGUAGE_STATUS_UPDATE_SUCCESS";
export const GET_DELETED_USERS_LIST = "GET_DELETED_USERS_LIST";
export const SAVE_DELETED_USERS_LIST = "SAVE_DELETED_USERS_LIST";
export const GET_MIA_INTEREST_UPDATE_REQUEST =
  "GET_MIA_INTEREST_UPDATE_REQUEST";
export const SAVE_MIA_INTEREST_UPDATE_RESPONSE =
  "SAVE_MIA_INTEREST_UPDATE_RESPONSE";
export const SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE_REQUEST =
  "SAVE_MIA_INTEREST_STATUS_UPDATE_RESPONSE_REQUEST";
export const GET_ADMIN_USER_FORGOT_PASSWORD_REQUEST =
  "GET_ADMIN_USER_FORGOT_PASSWORD_REQUEST";
export const GET_ADMIN_USER_RESET_PASSWORD_REQUEST =
  "GET_ADMIN_USER_RESET_PASSWORD_REQUEST";
export const GET_APP_USER_RESET_PASSWORD_REQUEST =
  "GET_APP_USER_RESET_PASSWORD_REQUEST";
export const GET_POSTS_LISTS = "GET_POSTS_LISTS";
export const SAVE_POSTS_LISTS = "SAVE_POSTS_LISTS";
export const GET_POSTS_BY_ID = "GET_POSTS_BY_ID";
export const SAVE_POSTS_BY_ID = "SAVE_POSTS_BY_ID";
export const UPDATE_POSTS_DETAILS = "UPDATE_POSTS_DETAILS";
export const SAVE_UPDATE_POSTS_DETAILS = "SAVE_UPDATE_POSTS_DETAILS";
export const CLEAR_POSTS_DETAILS = "CLEAR_POSTS_DETAILS";
export const GET_TAG_CATEGORY = "GET_TAG_CATEGORY";
export const SAVE_TAG_CATEGORY = "SAVE_TAG_CATEGORY";
export const GET_HOOD_LIST_BY_USER_ID = "GET_HOOD_LIST_BY_USER_ID";
export const SAVE_HOOD_LIST_BY_USER_ID = "SAVE_HOOD_LIST_BY_USER_ID";
export const GET_PLACE_LIST_BY_USER_ID = "GET_PLACE_LIST_BY_USER_ID";
export const SAVE_PLACE_LIST_BY_USER_ID = "SAVE_PLACE_LIST_BY_USER_ID";
export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_POSTS_BY_ID = "DELETE_POSTS_BY_ID";
export const DELETE_POSTS_COMMENT_BY_ID = "DELETE_POSTS_COMMENT_BY_ID";
export const SAVE_LANGUAGE_SUCCESS = "SAVE_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_ADD_REQUEST = "GET_LANGUAGE_ADD_REQUEST";
export const SAVE_LANGUAGE = "SAVE_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_STATUS_UPDATE_REQUEST =
  "GET_LANGUAGE_STATUS_UPDATE_REQUEST";
export const UPDATE_LANGUAGE_STATUS_SUCCESS = "UPDATE_LANGUAGE_STATUS_SUCCESS";
export const GET_USER_FOLLOWERS_BY_ID = "GET_USER_FOLLOWERS_BY_ID";
export const SAVE_USER_FOLLOWER_LIST = "SAVE_USER_FOLLOWER_LIST";
export const CLEAR_USER_FOLLOWER_LIST = "CLEAR_USER_FOLLOWER_LIST";
