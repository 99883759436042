import React, { useEffect, useState } from 'react'
import axios from 'axios'
// const domain = "http://localhost:3000"
const domain = "https://mia-api-dev.rlogical.com"

const PrivacyPolicy = () => {
  const [termCondition, setTermCondition] = useState({__html: ""});
  const getTermAndCondition = async () => {
    try {
      const { data = "" } = await axios.get(
        `${domain}/privacy-policy?language=en`, {
          headers: {
            'Content-Type': 'text/html'
          }
        });
      setTermCondition(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      await getTermAndCondition();
    })();
  }, []);
  console.log(termCondition)
  return <div dangerouslySetInnerHTML={{ __html: termCondition }}></div>;
}

export default PrivacyPolicy