import React, { useState, useEffect, Fragment } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// redux implementation
// import { Provider } from "react-redux";
// import Store from "./redux/Store";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

// Material Dashboard 2 React themes
import theme from "././assets/theme";
// import themeRTL from "././assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "././assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "./routes/routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "./context";

// Images
import brandWhite from "././assets/images/mia.svg";
import brandDark from "././assets/images/logo-ct-dark.png";

import AuthenticatedRoutes from "./routes/authenticatedRoutes";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const newRoutes = [];
  const userRole = "SUPER_ADMIN";

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // if (route.collapse) {
      //   return getRoutes(route.collapse);
      // }
      console.log(route);
      if (route.role && route.role.includes(userRole)) {
        // console.log("if called")
        newRoutes.push(route);
        if (route.route) {
          return (
            <React.Fragment key={route.key}>
              {!route.authenticated && (
                <Route
                  exact
                  path={route.route}
                  element={route.component}
                  key={route.key}
                />
              )}
              {
                // route.role.includes(userRole) &&
                <Route
                  path="/"
                  element={<AuthenticatedRoutes allowedRole={userRole} />}
                >
                  <Route
                    exact
                    path={route.route}
                    element={route.component}
                    key={route.key}
                  />
                </Route>
              }
            </React.Fragment>
          );
        }
      } else {
        return (
          <Fragment>
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key}
            />
          </Fragment>
        );
      }

      return null;
    });

  return (
    // <Provider store={Store}>
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />

      {localStorage.getItem("token") !== null && layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
         
            routes={newRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}

      <Toaster position="top-center" />
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="*" element={<Navigate to="/admin/signin" />} /> */}
      </Routes>
    </ThemeProvider>
    // </Provider>
  );
};

export default App;
