import { call, put, takeLatest } from "redux-saga/effects";
import {
  savePlaceCategoryAddSuccess,
  savePlaceCategoryById,
  savePlaceCategoryDeleteSuccess,
  savePlaceCategoryList,
  savePlaceCategoryStatusUpdateSuccess,
  savePlaceCategoryUpdateSuccess,
  savePlaceTagCategoryAddSuccess,
  savePlaceTagCategoryById,
  savePlaceTagCategoryDeleteSuccess,
  savePlaceTagCategoryList,
  savePlaceTagCategoryStatusUpdateSuccess,
  savePlaceTagCategoryUpdateSuccess,
  savePlaceList,
  savePlaceDetails,
  savePlaceCategoryListForDropdown,
  savePlaceCategoryByIdForDropdown
} from "../actions/places";
import {
  GET_PLACE_CATEGORY_BY_ID,
  GET_PLACE_CATEGORY_LIST,
  GET_PLACE_CATEGORY_LIST_FOR_DROPDOWN,
  SAVE_PLACE_CATEGORY_ADD_SUCCESS,
  GET_PLACE_CATEGORY_UPDATE_REQUEST,
  // GET_PLACE_TAG_SUBCATEGORY_LIST,
  GET_PLACE_CATEGORY_STATUS_UPDATE_REQUEST,
  SAVE_PLACE_CATEGORY_BY_ID,
  SAVE_PLACE_CATEGORY_LIST,
  SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN,
  // SAVE_PLACE_TAG_SUBCATEGORY_LIST,
  GET_PLACE_CATEGORY_ADD_REQUEST,
  SAVE_PLACE_CATEGORY_UPDATE_SUCCESS,
  SAVE_PLACE_CATEGORY_STATUS_UPDATE_SUCCESS,
  GET_PLACE_CATEGORY_DELETE_REQUEST,
  SAVE_PLACE_CATEGORY_DELETE_SUCCESS,
  SAVE_PLACE_TAG_CATEGORY_ADD_SUCCESS,
  GET_PLACE_TAG_CATEGORY_ADD_REQUEST,
  GET_PLACE_TAG_CATEGORY_BY_ID,
  SAVE_PLACE_TAG_CATEGORY_BY_ID,
  GET_PLACE_TAG_CATEGORY_LIST,
  SAVE_PLACE_TAG_CATEGORY_LIST,
  SAVE_PLACE_TAG_CATEGORY_STATUS_UPDATE_SUCCESS,
  GET_PLACE_TAG_CATEGORY_STATUS_UPDATE_REQUEST,
  GET_PLACE_TAG_CATEGORY_UPDATE_REQUEST,
  SAVE_PLACE_TAG_CATEGORY_UPDATE_SUCCESS,
  GET_PLACE_TAG_CATEGORY_DELETE_REQUEST,
  SAVE_PLACE_TAG_CATEGORY_DELETE_SUCCESS,
  GET_PLACE_TABLE_LIST,
  SAVE_PLACES_LIST,
  GET_PLACE_DETAILS,
  SAVE_PLACE_DETAILS,
  UPDATE_PLACE_DETAILS,
  GET_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN,
  SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN
} from "../types/action-type";
import { get as _get } from "lodash";
import mias from "../api";
import toast from "react-hot-toast";

function* getPlaceCategoryList() {
  try {
    const response = yield call(mias.apis.places.getPlaceCategoryList);
    yield put(savePlaceCategoryList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getPlaceCategoryList saga error ::", error);
  }
}
function* getPlaceCategoryListForDropdown() {
  try {
    const response = yield call(mias.apis.places.getPlaceCategoryListForDropdown);
    yield put(savePlaceCategoryListForDropdown(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getPlaceCategoryList saga error ::", error);
  }
}

function* getPlaceTagCategoryList() {
  try {
    const response = yield call(mias.apis.places.getPlaceTagCategoryList);
    console.log("response", response);
    // const subCategoryList = [];
    // response.data.data.forEach((data) => {
    //   data.subCategory.forEach((sub) => {
    //     subCategoryList.push({
    //       categoryId: data._id,
    //       status: sub.status,
    //       label: sub.label,
    //       _id: sub._id,
    //       categoryName: data.label[0].name,
    //     });
    //   });
    // });
    console.log("subCategoryList", response);
    yield put(savePlaceTagCategoryList(response.data.data));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getPlaceCategoryTagList saga error ::", error);
  }
}

function* getPlaceCategoryById({ id }) {
  try {
    const response = yield call(mias.apis.places.getPlaceCategoryById, id);
    yield put(savePlaceCategoryById(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getPlaceCategoryById saga error ::", error);
  }
}
function* getPlaceCategoryByIdForDropdown({ id }) {
  try {
    const response = yield call(mias.apis.places.getPlaceCategoryByIdForDropdown, id);
    yield put(savePlaceCategoryByIdForDropdown(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getPlaceCategoryById dropdown saga error ::", error);
  }
}

function* getPlaceTagCategoryById({ categoryId, subCategoryId }) {
  try {
    const response = yield call(
      mias.apis.places.getPlaceTagCategoryById,
      categoryId,
      subCategoryId
    );
    // response.data.data.tagCategory = {
    //   tagCategory_id: _get(response, "data.data.categoryId", ""),
    //   categoryName: _get(response, "data.data.categoryName", ""),
    // };
    console.log("check saga place ::", response);
    yield put(savePlaceTagCategoryById(_get(response, "data.data", {})));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getPlaceTagCategoryById saga error ::", error);
  }
}

function* updatePlaceCategory(data) {
  try {
    yield call(mias.apis.places.updatePlaceCategory, data.data);
    yield put(savePlaceCategoryUpdateSuccess());
    const response = yield call(mias.apis.places.getPlaceCategoryList);
    yield put(savePlaceCategoryList(_get(response, "data.data", [])));
    toast.success("Place Category Updated!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("updatePlaceCategory saga error ::", error);
  }
}

function* updatePlaceTagCategory(data) {
  try {
    yield call(mias.apis.places.updatePlaceTagCategory, data.data);
    yield put(savePlaceTagCategoryUpdateSuccess());
    const response = yield call(mias.apis.places.getPlaceTagCategoryList);
    yield put(savePlaceTagCategoryList(_get(response, "data.data", [])));
    toast.success("Place Category Tag Updated!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("updatePlaceTagCategory saga error ::", error);
  }
}

function* getPlacesTableList({pageIndex, pageSize, searchKey,primaryCode}) {
  try {
    const response  = yield call(mias.apis.places.getAllPlaceList, pageIndex, pageSize, searchKey,primaryCode);
    response.data.data.pageIndex = pageIndex;
    response.data.data.pageSize = pageSize;
    if (response.data.data.totalRecord === 0) {
       toast("No Records Found");
     }
   yield put(savePlaceList(_get(response, "data.data", [])));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("getPlacesTableList saga error ::", error);
  }
}

function* createPlaceCategory(data) {
  try {
    yield call(mias.apis.places.createPlaceCategory, data.data);
    yield put(savePlaceCategoryAddSuccess());
    const response = yield call(mias.apis.places.getPlaceCategoryList);
    yield put(savePlaceCategoryList(_get(response, "data.data", [])));
    toast.success("Place Category Created!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("createPlaceCategory saga error ::", error);
  }
}

function* createPlaceTagCategory(data) {
  try {
    yield call(mias.apis.places.createPlaceTagCategory, data.data);
    yield put(savePlaceTagCategoryAddSuccess());
    const response = yield call(mias.apis.places.getPlaceTagCategoryList);
    yield put(savePlaceTagCategoryList(_get(response, "data.data", [])));
    toast.success("Place Tag Category Created!");
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("createPlaceTagCategory saga error ::", error);
  }
}

function* updatePlaceCategoryStatus(data) {
  try {
    const apiResp = yield call(
      mias.apis.places.updatePlaceCategoryStatus,
      data.data
    );
    yield put(savePlaceCategoryStatusUpdateSuccess());
    const response = yield call(mias.apis.places.getPlaceCategoryList);
    yield put(savePlaceCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("updatePlaceCategoryStatus saga error ::", error);
  }
}

function* updatePlaceTagCategoryStatus(data) {
  try {
    const apiResp = yield call(
      mias.apis.places.updatePlaceTagCategoryStatus,
      data.data
    );
    yield put(savePlaceTagCategoryStatusUpdateSuccess());
    const response = yield call(mias.apis.places.getPlaceTagCategoryList);
    yield put(savePlaceTagCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("updatePlaceTagCategoryStatus saga error ::", error);
  }
}

function* deletePlaceCategory(data) {
  try {
    const apiResp = yield call(mias.apis.places.deletePlaceCategory, data.data);
    yield put(savePlaceCategoryDeleteSuccess());
    const response = yield call(mias.apis.places.getPlaceCategoryList);
    yield put(savePlaceCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("deletePlaceCategory saga error ::", error);
  }
}

function* deletePlaceTagCategory(data) {
  try {
    const apiResp = yield call(
      mias.apis.places.deletePlaceTagCategory,
      data.data
    );
    yield put(savePlaceTagCategoryDeleteSuccess());
    const response = yield call(mias.apis.places.getPlaceTagCategoryList);
    yield put(savePlaceTagCategoryList(_get(response, "data.data", [])));
    toast.success(_get(apiResp, "data.message"));
  } catch (error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    );
    console.log("deletePlaceTagCategory saga error ::", error);
  }
}

function* getPlaceDetailsById({ placeId }) {
  try {
    const response  = yield call(mias.apis.places.getPlaceDetails, placeId);
    yield put(savePlaceDetails(_get(response, "data.data", [])));
    toast.success(_get(response, "data.message"));

  } catch(error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    ); 
  }

}

function* updateThePlaceDetails ({ data, navigate }) {
  try {
    const response  = yield call(mias.apis.places.updatePlaceDetails, data);
    toast.success(_get(response, "data.message"));
    navigate('/places')
  
  } catch(error) {
    toast.error(
      _get(error, "response.data.message", _get(error, "message", ""))
    ); 
  }
}

export default function* Places() {
  yield takeLatest(GET_PLACE_CATEGORY_LIST, getPlaceCategoryList);
  yield takeLatest(SAVE_PLACE_CATEGORY_LIST, savePlaceCategoryList);
  yield takeLatest(GET_PLACE_CATEGORY_LIST_FOR_DROPDOWN, getPlaceCategoryListForDropdown);
  yield takeLatest(SAVE_PLACE_CATEGORY_LIST_FOR_DROPDOWN, savePlaceCategoryListForDropdown);
  
  yield takeLatest(GET_PLACE_TAG_CATEGORY_LIST, getPlaceTagCategoryList);
  yield takeLatest(SAVE_PLACE_TAG_CATEGORY_LIST, savePlaceTagCategoryList);
  yield takeLatest(GET_PLACE_CATEGORY_BY_ID, getPlaceCategoryById);
  yield takeLatest(SAVE_PLACE_CATEGORY_BY_ID, savePlaceCategoryById);
  yield takeLatest(GET_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN, getPlaceCategoryByIdForDropdown);
  yield takeLatest(SAVE_PLACE_CATEGORY_BY_ID_FOR_DROPDOWN, savePlaceCategoryByIdForDropdown);
  yield takeLatest(GET_PLACE_TAG_CATEGORY_BY_ID, getPlaceTagCategoryById);
  yield takeLatest(SAVE_PLACE_TAG_CATEGORY_BY_ID, savePlaceTagCategoryById);
  yield takeLatest(GET_PLACE_CATEGORY_UPDATE_REQUEST, updatePlaceCategory);
  yield takeLatest(
    SAVE_PLACE_CATEGORY_UPDATE_SUCCESS,
    savePlaceCategoryUpdateSuccess
  );
  yield takeLatest(
    GET_PLACE_TAG_CATEGORY_UPDATE_REQUEST,
    updatePlaceTagCategory
  );
  yield takeLatest(
    SAVE_PLACE_TAG_CATEGORY_UPDATE_SUCCESS,
    savePlaceTagCategoryUpdateSuccess
  );
  yield takeLatest(GET_PLACE_CATEGORY_ADD_REQUEST, createPlaceCategory);
  yield takeLatest(
    SAVE_PLACE_CATEGORY_ADD_SUCCESS,
    savePlaceCategoryAddSuccess
  );
  yield takeLatest(
    SAVE_PLACE_TAG_CATEGORY_ADD_SUCCESS,
    savePlaceCategoryAddSuccess
  );
  yield takeLatest(GET_PLACE_TAG_CATEGORY_ADD_REQUEST, createPlaceTagCategory);
  yield takeLatest(
    GET_PLACE_CATEGORY_STATUS_UPDATE_REQUEST,
    updatePlaceCategoryStatus
  );
  yield takeLatest(
    SAVE_PLACE_CATEGORY_STATUS_UPDATE_SUCCESS,
    savePlaceCategoryStatusUpdateSuccess
  );
  yield takeLatest(
    GET_PLACE_TAG_CATEGORY_STATUS_UPDATE_REQUEST,
    updatePlaceTagCategoryStatus
  );
  yield takeLatest(
    SAVE_PLACE_TAG_CATEGORY_STATUS_UPDATE_SUCCESS,
    savePlaceTagCategoryStatusUpdateSuccess
  );
  yield takeLatest(GET_PLACE_CATEGORY_DELETE_REQUEST, deletePlaceCategory);
  yield takeLatest(
    SAVE_PLACE_CATEGORY_DELETE_SUCCESS,
    savePlaceCategoryDeleteSuccess
  );
  yield takeLatest(
    GET_PLACE_TAG_CATEGORY_DELETE_REQUEST,
    deletePlaceTagCategory
  );
  yield takeLatest(
    SAVE_PLACE_TAG_CATEGORY_DELETE_SUCCESS,
    savePlaceTagCategoryDeleteSuccess
  );
  yield takeLatest(GET_PLACE_TABLE_LIST, getPlacesTableList);
  yield takeLatest(SAVE_PLACES_LIST, savePlaceList)
  yield takeLatest(GET_PLACE_DETAILS, getPlaceDetailsById);
  yield takeLatest(SAVE_PLACE_DETAILS, savePlaceDetails);
  yield takeLatest(UPDATE_PLACE_DETAILS, updateThePlaceDetails)
}
