import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import { get as _get } from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import { map as _map } from "lodash";
import MDBox from "../../components/MDBox";
import { Dialog, Icon, Tooltip } from "@mui/material";
import MDAvatar from "../../components/MDAvatar";
import MDButton from "../../components/MDButton";
import moment from "moment";
import { Grid, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearPostList, getPostListByHoodId } from "../../actions/hoods";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
// import { TextField } from "@material-ui/core";
// import { Label } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


const Post = ({ post }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [openLikes, setOpenLikes] = useState(false);
  const [id, setId] = useState(0);
  const [commentsId, setCommentsId] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLikes = () => {
    setOpenLikes(false);
  };
  useEffect(() => {
    if (params.id) {
      const obj = {
        hoodId: params.id
      }
      dispatch(getPostListByHoodId(obj))
    }

    return () => {
      dispatch(clearPostList())
    }
  }, [dispatch])
  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "hoods.postList", []),
    };
  })
  const handleChange = (item) => {
    setOpenLikes(true)
    setId(item)
  }
  const handleCommentsChange = (item,count) => {
    setOpen(true)
    setCommentsId(item)
  }

  const initialValues = storeProps.data

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                mb={0}
                variant="contained"
                bgColor='rgba(0, 0, 0, 0.0)'
                borderRadius="lg"
              >
                <MDTypography variant="h5" color="black">
                  View Hood Post Details
                </MDTypography>
              </MDBox>
              
  
              {initialValues.length > 0 ?
                _map(initialValues, (item, inx) => {
                  return (
                    
                    <MDBox key={inx} p={1.5} m={2} mt={0} sx={{border: '1px solid #eee', borderRadius: '8px', minHeight: 'calc(100% - 16px)'}}>
                      <MDBox display="flex"  >

                          <MDBox pt={0} sx={({ breakpoints }) => ({width: "150px", [breakpoints.up("lg")]: {width: "150px" }, })} fontSize="0px" >
                            
                            {item.images.length > 0 ?
                              _map(item.images, (image, index) => {
                                return (
                                  <div key={index}>
                                    <img
                                      src={image.url }
                                      alt="postImage"
                                      
                                      // sx={({ breakpoints }) => ({height: "150px", width: "150px", maxWidth: "100%", m: "auto", objectFit: "cover", borderRadius: "8px", border: "1px solid #f0f0f0", [breakpoints.up("lg")]: {height: "100px", width: "100px"} })}
                                      style={{ height: "150px", width: "150px", maxWidth: "100%", m: "auto", objectFit: "cover", borderRadius: "8px", border: "1px solid #f0f0f0" }}
                                    />
                                  </div>
                                );
                              })

                              :

                              <div>
                                <img
                                  src="https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png"
                                  alt="postImage"
                                  style={{ height: "150px", width: "150px", maxWidth: "100%", m: "auto", objectFit: "cover", borderRadius: "8px", border: "1px solid #f0f0f0" }}
                                />
                              </div>
                            }
                            {/* <Carousel
                              showThumbs={false}
                              showStatus={false}
                              infiniteLoop={true}
                              autoPlay={true}
                              interval={2000}
                            >
                              {_map(item.images, (image, index) => {
                                return (
                                  <div key={index}>
                                    <img
                                      src={image.url}
                                      alt="postImage"
                                      style={{ height: "150px", width: "150px", maxWidth: "100%", m: "auto", objectFit: "cover", borderRadius: "8px", border: "1px solid #f0f0f0" }}
                                    />
                                  </div>
                                );
                              })}
                            </Carousel> */}
                          </MDBox>
                      

                          <MDBox pl={2} sx={({ breakpoints }) => ({width: "calc(100% - 150px)", [breakpoints.up("lg")]: {width: "calc(100% - 150px)" }, })}>
                            <MDBox pt={0}>
                              <MDTypography color="black" fontSize="16px" fontWeight="semibold" mb={1}>
                                  {item.title}
                              </MDTypography>
                            </MDBox>
                            
                            
                            <MDBox display="flex" >
                              <MDBox display="flex">
                                <MDTypography variant="text" fontSize="14px" fontWeight="light" mr={1} sx={{color: (theme) => theme.palette.grey[600]}}>
                                  <Icon sx={{ cursor: "pointer", color: (theme) => theme.palette.grey[600], marginRight:'5px' }}>person</Icon>
                                  {item.userObj.firstName} {item.userObj.lastName}
                                </MDTypography>
                                <MDTypography variant="text" fontSize="14px" fontWeight="light" mr={1} sx={{color: (theme) => theme.palette.grey[600]}}>
                                  <Icon sx={{ cursor: "pointer", color: (theme) => theme.palette.grey[600], marginRight:'5px' }}>calendar_month</Icon>
                                  {moment(item.createdAt).format("DD MMM YYYY")}
                                </MDTypography>
                              </MDBox>
                              
                            </MDBox>

                            <MDBox lineHeight="1.1">
                              <MDTypography variant="text" fontSize="14px" lineHeight="1" sx={{color: (theme) => theme.palette.grey[500]}}>
                                {item.post}
                              </MDTypography>
                            </MDBox>
                            
                            <MDBox display="flex" mt={1.5}>
                                <Tooltip title="View Likes" placement="bottom">
                                  <MDBox
                                    display="inline-flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    pr={0}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleChange(item.id,item.totalLike)}
                                  >
                                    {item.totalLike > 0 && (
                                        
                                      <><Icon style={{ fontSize: "12px", marginRight:'5px' }} >thumb_up</Icon>
                                        <MDTypography variant="text" fontSize="14px" fontWeight="light" mr={1.5} sx={{color: (theme) => theme.palette.grey[600]}}>
                                            {item.totalLike}
                                          </MDTypography></>
                                    )}
                                  </MDBox>
                                </Tooltip>
                                <Tooltip title="View Comments" placement="bottom">
                                  <MDBox
                                    display="inline-flex"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleCommentsChange(item.id,item.totalComment)}
                                  >
                                    {item.totalComment > 0 && (
                                        
                                      <><Icon style={{ fontSize: "12px", marginRight:'5px' }}>comment</Icon>
                                      <MDTypography variant="text" fontSize="14px" fontWeight="light" mr={0.5} sx={{color: (theme) => theme.palette.grey[600]}}>{item.totalComment}</MDTypography></> 
                                    )}
                                  </MDBox>
                                </Tooltip>
                              </MDBox>
                          </MDBox>
                      </MDBox>
                    </MDBox>
                    
                  );
                })
             : <MDBox display="flex" justifyContent="center" alignItems="center" height="100%" width="100%" >
                  <MDTypography variant="text" fontSize="14px" fontWeight="light" mr={1} sx={{color: (theme) => theme.palette.grey[600]}}>
                    No Posts Found
                  </MDTypography>
                </MDBox>
              }
              
            </Card>
         
      </MDBox>
      {open === true && commentsId !==null ?
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>
              View Comments
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[700],
                  backgroundColor: (theme) => theme.palette.grey[200],
                }}
              >
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
          <DialogContent sx={{ maxHeight: "400px"}}>
            
            {initialValues.length > 0 &&
              _map(initialValues, (item, index) => {
                return (
                  <MDBox key={index} >
                    {item.id !== commentsId ? null :
                      <div>
                        {_map(item.postComments, (item, index) => {
                          return (
                            <MDBox key={index} p={1} mb={1.5} sx={({ palette: { grey },  borders: { borderWidth } }) => ({border: `${borderWidth[1]} solid ${grey[200]}`, borderRadius: '8px'})}>
                              <MDBox display="flex" alignItems="flex-start">
                                {_map(item.userCommentName, (item, index) => {
                                  return (
                                    <MDAvatar src={item.profilePicture!==""?item.profilePicture:"http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"} sx={{ height: "auto", width: "auto", maxWidth: "50px",  objectFit: "cover", borderRadius: "6px", marginRight: "10px" }} />
                                  )})}
                                <MDBox >
                                  <MDTypography variant="h6" color="black">
                                    {_map(item.userCommentName, (item, index) => {

                                      return (
                                        
                                        <div key={index}>
                                            {item.firstName} {item.lastName}
                                        </div>
                                      )
                                    })

                                    }
                                  </MDTypography>
                                  <MDTypography variant="body2" color="black">
                                    {item.comment}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          )
                        })}
                      </div>
                    }
                      </MDBox>
                  )
                }
                
                )}
  
        </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose} variant="outlined" color="error">Cancel</MDButton>
          </DialogActions>
        </Dialog>
        : null}

      {openLikes === true && id !== null ?
        <Dialog open={openLikes} onClose={handleCloseLikes} fullWidth maxWidth="sm">
          <DialogTitle>
              View Likes
              <IconButton
                aria-label="close"
                onClick={handleCloseLikes}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[700],
                  backgroundColor: (theme) => theme.palette.grey[200],
                }}
              >
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
          <DialogContent sx={{ maxHeight: "400px"}}>
            {initialValues.length > 0 &&
              _map(initialValues, (item, index) => {
                return (

                  <MDBox key={index} >
                    {id !== item.id ?
                      <div>

                      </div>
                      : <div>{_map(item.userLikedPost, (item, index) => {
                        return (
                          <MDBox key={index} >
                            
                              <MDBox p={1} mb={1.5} sx={({ palette: { grey },  borders: { borderWidth } }) => ({border: `${borderWidth[1]} solid ${grey[200]}`, borderRadius: '8px'})}>
                                {_map(item.likeUserName, (item, index) => {

                                  return (
                                    <MDBox key={index} p={0} display="flex" alignItems="center" >
                                        <MDAvatar src={item.profilePicture!==""?item.profilePicture:"http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"} sx={{ height: "auto", width: "auto", maxWidth: "50px",  objectFit: "cover", borderRadius: "6px", marginRight: "10px" }} />
                                        <MDTypography variant="h6" color="black" key={index}>
                                          {item.firstName} {item.lastName}
                                        </MDTypography>
                                    </MDBox>
                                  )
                                })

                                }
                              </MDBox>
                            
                          </MDBox>
                        )
                      }

                      )} </div>}

                  </MDBox>

                )

              })
            }
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleCloseLikes} variant="outlined" color="error">Cancel</MDButton>
          </DialogActions>
        </Dialog>
        : null}
    </DashboardLayout>
  );
};

export default Post;
