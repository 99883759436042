// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDAvatar from "../../components/MDAvatar";
import { map as _map } from "lodash";
// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable/serverTable";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Divider, Autocomplete, TextField, Chip  } from "@mui/material";
import MDBadge from "../../components/MDBadge";
import { get as _get } from "lodash";
import Icon from "@mui/material/Icon";
import moment from "moment/moment";
import IconButton from '@mui/material/IconButton';
import pxToRem from "../../../src/assets/theme/functions/pxToRem";

// Data
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHoodCategoryListForDropdown, getHoodsList } from "../../actions/hoods";
import CloseIcon from '@mui/icons-material/Close';
const HoodsList = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "hoods.hoodsList.records", []),
      totalRecords: _get(state, "hoods.hoodsList.totalRecord", 0),
      pageIndex: _get(state, "hoods.hoodsList.pageIndex", 0),
      pageSize: _get(state, "hoods.hoodsList.pageSize", 10),
      hoodCategoryList: _get(state, "hoods.hoodCategoryListForDropdown.records", []),
    };
  });
  const tableData={
    columns: [
        {
          Header: "name",
          accessor: "name",
          disableSortBy: true,
          Cell: (row) => (
            <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
              {_get(row, "row.original.name")}
            </MDTypography>
          ),
          align: "left",
        },
        {
          Header: "Hood Creator",
          accessor: "hoodCreator",
          disableSortBy: true,
          Cell: (row) => (
            <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
              {_get(row, "row.original.createrUserDetail.firstName")} {_get(row, "row.original.createrUserDetail.lastName")}
            </MDTypography>
          ),
          align: "left",
        },
        {
          Header: "Members",
          accessor:(a => a.totalUser),
          sortType: 'basic',
          // sortByFn: (a, b) => b.totalUsers - a.totalUsers,
          Cell: (row) => (
            <>
              <Tooltip title="View Members" placement="bottom">
              <MDTypography variant="caption" color="orange" fontWeight="medium" fontSize="15px"
                sx={{ cursor: "pointer" }}
                onClick={() => handleUserClick(row.row.original._id)}
                >
                {_get(row, "row.original.totalUser")}
              </MDTypography>
              </Tooltip>
          
            </>
          ),
          align: "center",
        },
        {
          Header: "posts",
          accessor: (a => a.totalPost),
          disableSortBy: true,
          sortType:'basic',
          Cell: (row) => (
            <Tooltip title="View Posts" placement="bottom">
              <MDTypography variant="caption" color="orange" fontWeight="medium" fontSize="15px"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(`/hoods/${_get(row, "row.original._id")}/post`)}
                >
                {_get(row, "row.original.totalPost")}
              </MDTypography>
            </Tooltip>
          ),
          align: "center",
        },
        {
          Header: "createdAt",
          accessor:(a => a.createdAt),
          sortType: 'basic',
          Cell: (row) => (
            <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">

            {moment(_get(row, "row.original.createdAt").substring(0, 10)).format("DD/MM/YYYY")}
            </MDTypography>
          ),
          align: "center",
        },
        {
          Header: "category",
          accessor: "categoryName",
          disableSortBy: true,
          Cell: (row) => (
            <MDTypography variant="caption" color="text" fontWeight="regular" fontSize="14px">
              {_get(row, "row.original.categoryName")}
            </MDTypography>
          ),
          align: "center",
      
        },
        {
          Header: "isActive",
          accessor: (a => a.isActive),
          //sortType:'basic',
          disableSortBy: true,
          Cell: (row) => (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={_get(row, "row.original.isActive") ? "Yes" : "No"}
                color={_get(row, "row.original.isActive") ? "success" : "error"}
                variant="contained"
                size="sm"
                
                />
            </MDBox>
          ),
          align: "center",
        },
        {
          Header: "is private",
          accessor: (a => a.isPrivate),
          disableSortBy: true,
          sortType:'basic',
          Cell: (row) => (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={_get(row, "row.original.isPrivate") ? "Yes" : "No"}
                color={_get(row, "row.original.isPrivate") ? "success" : "error"}
                variant="contained"
                size="sm"
                />
            </MDBox>
          ),
          align: "center",
        },
        {
          Header: "is featured",
          accessor: (a => a.features),
          disableSortBy: true,
          sortType:'basic',
          Cell: (row) => (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={_get(row, "row.original.features", false) ? "Yes" : "No"}
                color={_get(row, "row.original.features", false) ? "success" : "error"}
                variant="contained"
                size="sm"
              />
            </MDBox>
          ),
          align: "center",
        },
        {
          Header: "action",
          accessor: "action",
          disableSortBy: true,
          Cell: (row) => (
            <Tooltip title="Edit" placement="bottom" arrow={true}>
              <IconButton sx={{ 
                color: "#F05A22", 
                backgroundColor: "#fde4db", 
                borderRadius: "6px" ,
                width: pxToRem(35),
                height: pxToRem(35),
                '&:hover' :{
                  backgroundColor: "#fbcfbf"
                }
              }}>
              <Icon
              fontSize="small"
              onClick={() =>
                navigate(`/hoods/${_get(row, "row.original._id")}/edit`)
              }
              sx={{ cursor: "pointer" }}
              >
              edit_icon
            </Icon>
            </IconButton>
          </Tooltip>
          ),
          align: "center",
          width: "5%",
        },
      ],
      rows: _get(storeProps, "data", []),
      totalRecords: _get(storeProps, "totalRecords", 0),
      pageIndex: _get(storeProps, "pageIndex", 0),
      pageSize: _get(storeProps, "pageSize", 30),
      
    }
    const { columns, rows, totalRecords, pageIndex, pageSize } = tableData;
  
    const handleUserClick = (row) => {
    setShowDialog(true);
    setUserId(row);
   
  }
  useEffect(() => {
    dispatch(getHoodCategoryListForDropdown());
  },[]);
  const dispatch=useDispatch();
  const [categoryCodeState, setCategoryCodeState] = useState("")
  const handleChange = (event,values) => {
    const data=values.map((item)=>item._id);
    const categoryCode=data.toString();
    setCategoryCodeState(categoryCode);
    const searchKey="";
    dispatch(getHoodsList(pageIndex, pageSize,searchKey,categoryCode));

  }
  // const handleClickFilters = (e) => {
  //   console.log("filter",e.target.value);
  //   const searchKey="";
  //   let totalUsers=0;
  //   let createdAt=0;
  //   if(e.target.value==="totalUsers"){
  //     // pass totalUsers=1 in query params to get hoods with sorted members
  //     totalUsers=1;
  
  //     dispatch(getHoodsList(pageIndex, pageSize,searchKey,totalUsers));
  //   }
  //   else if(e.target.value==="createdAt"){
  //       totalUsers=2;
  //      createdAt=1;
  //      console.log("createdAt");
  //       dispatch(getHoodsList(pageIndex, pageSize,searchKey,totalUsers,createdAt));
  //   }
  //   setShowFilter(false);
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />
  
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                 mx={0}
                 mt={0}
                 py={2}
                  px={2}
                 variant="contained"
                 bgColor="transperent"
                  borderRadius="lg"
                  // coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  zIndex="1"
                  width="calc(100% - 260px)"
              >
                <MDTypography variant="h5" color="black">
                  Hoods List
                </MDTypography>
         {/*        <MDBox sx={{mt:-0.25, paddingRight:'15.25rem'}}> */}
                  <Autocomplete
                  multiple
                  name="categoryCode"
                  options={storeProps?.hoodCategoryList}
                  //value={storeProps?.hoodCategoryList}
                  sx={{ width: 200 }}
                  key={storeProps?.hoodCategoryList._id}
                  getOptionLabel={(option) => option.label}
                  onChange={handleChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return(
                        <Chip variant="outlined" name="categoryCode" key={index}  value={option._id} label={option.label} {...getTagProps({ index })} />
                      )
                    })
                  } 
                  renderInput={(params) => <TextField name="categoryCode" {...params}   id={params._id} value={params._id} label="Filter By Category" />}
                >
                </Autocomplete>
                </MDBox>
        {/*       </MDBox> */}
              <MDBox pt={3} mt={-12.5}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  // entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch
                  totalRecords={totalRecords}
                  categoryCode={categoryCodeState}
                  pageIndex1={pageIndex}
                  pageSize1={pageSize}
                  tableFor="hoodsList"
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>


        {showDialog && userId !== null? 
        <Dialog open={showDialog} onClose={()=>setShowDialog(false)} fullWidth maxWidth="sm">
          <DialogTitle>View Members 
              <IconButton
                aria-label="close"
                onClick={()=>setShowDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[700],
                  backgroundColor: (theme) => theme.palette.grey[200],
                }}
              >
                <CloseIcon />
              </IconButton>
              
          </DialogTitle>
          <Divider sx={{ marginTop: "0", marginBottom: "0px" }} />
          <DialogContent>
            <DialogContentText sx={{ maxHeight: "400px"}}>
                {storeProps.data.length > 0 ? _map(storeProps.data, (member, idex) => {
                  return (
                    <MDBox key={idex} p={0} >
                      {userId === member._id &&
                        _map(member.viewUsersName, (item, index) => (
                          <MDBox key={index} p={1} mb={1.5} sx={({ palette: { grey }, borders: { borderWidth } }) => ({ border: `${borderWidth[1]} solid ${grey[200]}`, borderRadius: '8px' })}>
                            {item.firstName || item.lastName || item.profilePicture ?
                            
                                <MDBox key={index} p={0} display="flex" alignItems="center" >
                                  <MDAvatar src={item.profilePicture !== "" ? item.profilePicture : "http://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"} sx={{ height: "auto", width: "auto", maxWidth: "50px", objectFit: "cover", borderRadius: "6px", marginRight: "10px" }} />
                                  <MDTypography variant="text" sx={{ color: (theme) => theme.palette.grey[600] }}>
                                    {item.isAdmin ? `${item.firstName} ${item.lastName} (Admin)` : `${item.firstName} ${item.lastName}`}   
                                  </MDTypography>
                                </MDBox>:
                                <MDBox>
                                  <MDTypography variant="text" sx={{ color: (theme) => theme.palette.grey[600] }}>
                                    User Doesn't exist anymore
                                  </MDTypography>
                                </MDBox>
                                }
                          </MDBox>
                        ))
                      }
                    </MDBox>
                )})
             :<MDBox p={2} display="flex" justifyContent="center" alignItems="center">
                <MDTypography variant="text" sx={{ color: (theme) => theme.palette.grey[600] }}>
                  No Members Found
                </MDTypography>
              </MDBox>}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={()=>setShowDialog(false)} variant="outlined" color="error">Cancel</MDButton>
          </DialogActions>
          </Dialog>
          :null}
      </MDBox>
    </DashboardLayout>
  );
};

export default HoodsList;
