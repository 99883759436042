import { 
    GET_COUNTS_FOR_DASHBOARD,
    SAVE_COUNTS_FOR_DASHBOARD, 
} from "../types/action-type";

export const getCountsFordashboard = () => ({
  type: GET_COUNTS_FOR_DASHBOARD,
});

export const saveCountsFordashboard = (data) => ({
  type: SAVE_COUNTS_FOR_DASHBOARD,
  data,
});
