
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
// import Footer from "../../examples/Footer";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";

import { Chart, LineController, LineElement, PointElement, LinearScale, Title,CategoryScale, BarElement } from 'chart.js';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountsFordashboard } from "../../actions/dashboard";
import { get as _get} from 'lodash';
import { useNavigate } from "react-router-dom";

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement);

function Dashboard() {
  const navigate=useNavigate()
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountsFordashboard());
  }, [dispatch])

  const storeProps = useSelector((state) => {
    return {
      adminUserCount: _get(state,"dashboard.counts.adminUserCount", 0),
      appUserCount: _get(state,"dashboard.counts.appUserCount", 0),
      hoodsCount: _get(state,"dashboard.counts.hoodsCount", 0),
      placesCount:_get(state,"dashboard.counts.placesCount",0),
      postsCount: _get(state,"dashboard.counts.postsCount", 0),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} >
        <Grid container spacing={4}>
          <Grid item xs={12} md={7} lg={3}>

            <MDBox mb={1} >
              <ComplexStatisticsCard
                color="black"
                // bgcolor="purple"
                icon="people_alt_icon"
                title="Admin Users"
                count={_get(storeProps, "adminUserCount")}
                to="/admins"
                // percentage={{
                //   color: "success",
                //   amount: "+55%",
                //   label: "than lask week",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={7} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                icon="how_to_reg_icon"
                title="App Users"
                count={_get(storeProps, "appUserCount")}
                color="purple"
                to={"/users"}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={7} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                // color="error"
                color="purple"
                icon="groups_icon"
                title="Hoods"
                count={_get(storeProps, "hoodsCount")}
                to={"/hoods"}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={7} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                // color="dark"
                color="purple"
                icon="place"
                title="Places"
                count={_get(storeProps, "placesCount")}
                to={"/places"}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={7} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                color="purple"
                icon="list_icon"
                title="Posts"
                count={_get(storeProps, "postsCount")}
                to={"/posts"}
                />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
