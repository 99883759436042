import Grid from "@mui/material/Grid";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import TabPanel from "./../tabPanel";
import { useState } from "react";

import HoodCategories from "./hoodCategories";
import HoodRules from "./hoodRules";
import TagCategories from "./tagCategories";
import Tags from "./tags";

import { AppBar, Divider, Icon, Tab, Tabs } from "@mui/material";
import Card from "@mui/material/Card";

import colors from "../../../assets/theme/base/colors";
const { grey, white, orange } = colors;

const HoodSettings = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  return (
    <DashboardLayout>
    <DashboardNavbar />
    

    <MDBox pt={3}>
        <Card>  
          <MDBox borderBottom="1px solid #dee2e6">
            <AppBar position="static" > 
              <Tabs
                variant="standard"
                orientation={"horizontal"}
                value={tabValue}
                onChange={handleSetTabValue}
                textColor="primary"
                // indicatorColor="secondary"
              >
                <Tab
                  label="Categories"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      dns
                    </Icon>
                  }
                />
                <Tab
                  label="Rules"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      view_list
                    </Icon>
                  }
                />
                <Tab
                  label="Tag Categories"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      label
                    </Icon>
                  }
                />
                  <Tab
                  label="Tags"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      style
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </MDBox>
          
          <TabPanel value={tabValue} index={0}>
            <HoodCategories />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <HoodRules />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <TagCategories />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Tags />
          </TabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default HoodSettings;
