import Card from "@mui/material/Card";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import { Field, Form, Formik } from "formik";
import { renderTextField } from "../../../inputFields/formFields";

import BasicLayout from "../../../layouts/authentication/components/BasicLayout";

import * as Yup from "yup";
// Images
import bgImage from "../../../assets/images/bg-image.jpg";
import { resetPasswordApp } from "../../../actions/adminUser";

function ResetPasswordApp() {
    const { token } = useParams();
    const initialValues = {
        password: "",
        confirmPassword: "",
        token,
    };
    const resetPasswordValidationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, "Min 6 Characters Required!")
            .required("Required"),
        confirmPassword: Yup.string()
            .min(6, "Min 6 Characters Required!")
            .required("Required")
            .oneOf([Yup.ref('password')], 'Passwords does not match'),
    });
    // const  [successMsg, setSuccessMsg] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const resetPassword = async (values, resetForm) => {
        // setSuccessMsg(true)
        dispatch(resetPasswordApp(values, navigate))
        resetForm()
    };
    return (
        <BasicLayout image={bgImage}>
            <Card sx={{ boxShadow: "none !important" }}>
                <MDBox p={3} textAlign="center">
                    <MDTypography variant="h4" fontWeight="semibold" color="black" textAlign="center" mb={1} >
                        Reset Password
                    </MDTypography>
                </MDBox>
                <Formik
                    initialValues={initialValues}
                    validationSchema={resetPasswordValidationSchema}
                    onSubmit={async (values, { resetForm }) => resetPassword(values, resetForm)}
                >
                    {({ errors, handleSubmit, isSubmitting, touched }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <MDBox px={3} pb={3}>
                                <Field
                                    name="password"
                                    component={renderTextField}
                                    fullWidth
                                    autoFocus
                                    label="Password"
                                    type="password"
                                />
                                <Field
                                    name="confirmPassword"
                                    component={renderTextField}
                                    fullWidth
                                    autoFocus
                                    label="Confirm Password"
                                    type="password"
                                />
                                <MDBox mt={4}>
                                    <MDButton
                                        variant="contained"
                                        color="btnTheme"
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        sx={{ fontSize: "16px", letterSpacing: "0.3px", fontWeight: "600" }}
                                    >
                                        Submit
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                            {/* {successMsg && <MDTypography variant="h6" fontWeight="semibold" color="success" textAlign="center" mb={1}
                            >
                                Password Reset Successfully
                            </MDTypography>} */}
                        </Form>
                    )}
                </Formik>
            </Card>
        </BasicLayout>
    );
}

export default ResetPasswordApp;
