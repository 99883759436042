import { SAVE_ROLES_LIST } from "../types/action-type";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export default function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ROLES_LIST:
      return {
        ...state,
        loading: true,
        list: action.data,
      };
    default:
      return state;
  }
}
