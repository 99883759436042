
// Material Dashboard 2 React base styles
import typography from "../../base/typography";
import borders from "../../base/borders";
import colors from "../../base/colors";

import rgba from "../../functions/rgba";

// Material Dashboard 2 React helper functions
import pxToRem from "../../functions/pxToRem";

const { size, fontWeightRegular } = typography;
const { borderRadius, borderWidth } = borders;
const { dark, orange, white, gray } = colors;

const tab = {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      // flex: "1 1 auto",
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "60px !important",
      fontSize: size.lg,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "1.5",
      paddingLeft: pxToRem(40),
      paddingRight: pxToRem(40),
      borderRadius: borderRadius.lg,
      color: rgba(`${dark.main}`,1),
      opacity: "1 !important",
      
      "& .Mui-selected":{
          colors: `${dark.main} !important`,
      },

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};

export default tab;
