import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";

import CloseIcon from '@mui/icons-material/Close';

import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
  Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get as _get,
  find as _find,
  map as _map,
  isEmpty as _isEmpty,
  omit as _omit,
  size as _size,
  remove as _remove,
} from "lodash";
import MDTypography from "../../../components/MDTypography";
import {
  getPlaceTagCategoryAddRequest,
  getPlaceTagCategoryById,
  getPlaceTagCategoryDeleteRequest,
  getPlaceTagCategoryList,
  getPlaceTagCategoryStatusUpdateRequest,
  getPlaceTagCategoryUpdateRequest,
} from "../../../actions/places";
import { Field, FieldArray, Form, Formik } from "formik";
import MDButton from "../../../components/MDButton";
import { getLanguageList } from "../../../actions/language";
import * as Yup from "yup";
import { showError } from "../../../utils";
import { renderAutoComplete } from "../../../inputFields/formFields";


import IconButton from '@mui/material/IconButton';
import pxToRem from "../../../../src/assets/theme/functions/pxToRem";

const Places = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [errorText, setErrorText] = useState("");
  const [serviceIndex, setServiceIndex] = useState([]);

  useEffect(() => {
    dispatch(getPlaceTagCategoryList());
  }, [dispatch]);

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "places.placeSubCategoryList", []),
      label: _get(state, "places.placeTagCategoryData.label", []),
      subCategoryId: _get(state, "places.placeSubCategoryData._id", ""),
      subCategoryStatus: _get(
        state,
        "places.placeSubCategoryData.status",
        false
      ),
      categoryId: _get(state, "places.placeSubCategoryData.categoryId", ""),
      languageList: _get(state, "language.list", []),
      placeCategory: _get(state, "places.placeCategoryList", []),
      tagCategoryList: _get(state, "places.placeSubCategoryList", []),
      tagCategory: _get(state, "places.placeTagCategoryData", {}),
    };
  });

  const initialValues = {
    code: "",
    label: [
      {
        local: "",
        name: "",
        languageName: "",
      },
    ],
    tagCategory: {
      tagCategory_id: "",
      categoryName: "",
    },
  };

  const getTitleName = (label) =>
    _map(label, (lab) => ({ name: lab.name }))[0].name;

  const handleChangeStatus = (value, id, subCategoryId) => {
    const statusData = { categoryId: id, subCategoryId, status: value };
    dispatch(getPlaceTagCategoryStatusUpdateRequest(statusData));
  };

  const tableData = {
    columns: [
      {
        Header: "title",
        accessor: "label",
        Cell: (row) => (
          <Tooltip title={getTitleName(_get(row, "row.original.label"))}>
            <MDTypography
              variant="caption" color="text" fontWeight="regular" fontSize="14px"
            >
              {getTitleName(_get(row, "row.original.label"))}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "Tag Category Name",
        accessor: "Category Name",
        Cell: (row) => (
          <Tooltip title={_get(row, "row.original.categoryName")}>
            <MDTypography
              variant="caption" color="text" fontWeight="regular" fontSize="14px"
            >
              {_get(row, "row.original.categoryName")}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: (row) => (
          <Switch
            checked={_get(row, "row.original.status")}
            onChange={(e, value) =>
              handleChangeStatus(
                value,
                _get(row, "row.original.categoryId"),
                _get(row, "row.original._id")
              )
            }
          />
        ),
        align: "center",
        width: "5%",
      },
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <MDBox width="100px" margin="auto">
            <IconButton sx={{ 
              color: "#54AF3A", 
              backgroundColor: "#E8FFE2", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              '&:hover' :{
                backgroundColor: "#e6f3e2"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() =>
                  handleClickOpen(
                    _get(row, "row.original.categoryId"),
                    _get(row, "row.original._id")
                  )
                }
                sx={{ cursor: "pointer" }}
              >
                edit_icon
              </Icon>
            </IconButton>
            <IconButton sx={{ 
              color: "#F05A22", 
              backgroundColor: "#fde4db", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              cursor: "pointer", ml: 2,
              '&:hover' :{
                backgroundColor: "#fbcfbf"
              }
            }}>
            <Icon
              fontSize="small"
              onClick={() => {
                setOpenConfirmation(true);
                setCategoryId(_get(row, "row.original.categoryId"));
                setSubCategoryId(_get(row, "row.original._id"));
              }}
              sx={{ cursor: "pointer" }}
            >
              delete
            </Icon>
            </IconButton>
          </MDBox>
        ),
        align: "center",
        width: "10%",
      },
    ],

    rows: _get(storeProps, "data", []),
  };
  const { columns, rows } = tableData;

  const handleClickOpen = (categoryId, subCategoryId) => {
    console.log(categoryId, subCategoryId);
    setOpen(true);
    dispatch(getPlaceTagCategoryById(categoryId, subCategoryId));
  };

  // const handleEditSubcategory = (data) => {
  //   const lang = storeProps.languageList;
  //   const myResponse = { ...data };
  //   myResponse.label.map((obj) => {
  //     obj.languageName =
  //       lang.filter((obj) => {
  //         return obj.code === "en";
  //       })[0]?.label || "";
  //     return obj;
  //   });
  //   console.log(myResponse);
  //   setTagCategorysub(myResponse)
  //   return myResponse;
  // };

  const handleClose = () => {
    setOpen(false);
    setIsNew(false);
  };

  const updateTags = (values) => {
    const labelArr = _map(_get(values, "label"), (label) =>
      _omit(label, ["languageName"])
    );
    const hoodTagData = {
      label: labelArr,
      categoryId: values.tagCategory.categoryId || values.tagCategory.tagCategory_id,
      subCategoryId: values.tagCategory._id,
    };
    if (isNew) {
      dispatch(getPlaceTagCategoryAddRequest(hoodTagData));
    } else {
      hoodTagData.id = _get(storeProps, "tagId");
      dispatch(getPlaceTagCategoryUpdateRequest(hoodTagData));
    }

    handleClose();
  };

  const addTag = () => {
    dispatch(getLanguageList());
    dispatch(getPlaceTagCategoryList());
    setIsNew(true);
  };

  const schema = Yup.object().shape({
    label: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required("Required"),
          local: Yup.string().required("Required"),
        })
        .required("Required")
    ),
    tagCategory: Yup.object().required("Required"),
  });

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setCategoryId("");
    setSubCategoryId("");
  };

  const deleteHoodTag = () => {
    dispatch(
      getPlaceTagCategoryDeleteRequest({
        categoryId: categoryId,
        subCategoryId: subCategoryId,
      })
    );
    handleConfirmationClose();
  };

  const duplicateCheck = (fieldValue, index, formValues) => {
    const label = _get(formValues, "label", []);
    let languageFound;
    if (!_isEmpty(label)) {
      languageFound = _find(
        label,
        (lab) => _get(lab, "local") === _get(fieldValue, "local")
      );
    }
    if (languageFound) {
      setErrorText("Language Already Present...");
      setServiceIndex(
        serviceIndex.indexOf(index) === -1
          ? [...serviceIndex, index]
          : serviceIndex
      );
    } else {
      _remove(serviceIndex, (r) => r === index);
      setErrorText(_size(serviceIndex) === 0 ? "" : errorText);
    }
  };

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="flex-end" textAlign="center" p={2}>
        
            
            <MDButton variant="outlined" color="orange" onClick={() => addTag()}>
              {" "}
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                add_icon
              </Icon>{" "}
              Add
            </MDButton>
          
      </MDBox>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={true}
        noEndBorder
        //   canSearch
        forSettings
      />
      <Dialog
        open={open || isNew}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, pr: 8 }}>
          {isNew ? "Add Tag" : "Edit Tags"}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 10,
                color: (theme) => theme.palette.grey[700],
                backgroundColor: (theme) => theme.palette.grey[200],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <Divider sx={{ marginTop: 0 }} />
        <DialogContent sx={{ marginTop: 0 }}>
          <Formik
            initialValues={isNew ? initialValues : storeProps}
            validationSchema={schema}
            enableReinitialize
            onSubmit={async (values) => updateTags(values)}
            render={({
              values,
              errors,
              touched,
              handleReset,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3} p={0}>
                    <Grid item xs={12} md={12}>
                      <Field
                        disabled={!isNew}
                        name="tagCategory"
                        value="Help"
                        component={renderAutoComplete}
                        fullWidth
                        label="Tag Category"
                        getOptionLabel={(option) => option.categoryName}
                        // getOptionLabel={(option) => {
                        //   console.log("optionsss", option)
                        //  return option.categoryName
                        // }}
                        displayLabel="categoryName"
                        menuoptionlist={_map(
                          _get(storeProps, "placeCategory", []),
                          (tcObj) => {
                            const obj = {
                              tagCategory_id: tcObj._id,
                              categoryName: tcObj.label[0].name,
                            };
                            console.log(obj);
                            return obj;
                          }
                        )}
                      />
                      {/* <Divider sx={{ mb: -2 }} /> */}
                    </Grid>
                  </Grid>
                  <FieldArray
                    name="label"
                    render={({ insert, remove, push }) => (
                      <>
                        <Stack direction="column" spacing={2} py={3} >
                        {values.label.map((label, index) => (
                          <>
                            <MDBox width="100%">
                                {values.label.length <storeProps.languageList.length && (
                                  <MDBox textAlign="right">
                                    <MDButton
                                      p={3}
                                      size="small"
                                      variant="text"
                                      color="info"
                                      sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                      onClick={() =>
                                        push({
                                          local: "",
                                          name: "",
                                          languageName: "",
                                        })
                                      }
                                    >
                                     Add Language
                                    </MDButton>
                                  </MDBox>
                                )}
                                {values.label.length > 1 && (
                                  <MDBox textAlign="right">
                                    <MDButton
                                      variant="text"
                                      color="error"
                                      onClick={() => {
                                        remove(index);
                                        setErrorText("");
                                        setServiceIndex([]);
                                      }}
                                      sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                    >
                                      Remove
                                    </MDButton>
                                  </MDBox>
                                )}
                                <Field
                                  name={`label.${index}`}
                                  label="Language"
                                  component={renderAutoComplete}
                                  menuoptionlist={_map(
                                    _get(storeProps, "languageList"),
                                    (langListObj) => {
                                      const obj = {
                                        local: langListObj.code,
                                        languageName: langListObj.label,
                                      };
                                      return obj;
                                    }
                                  )}
                                  disabled={(!isNew && !_isEmpty(label.languageName))}
                                  fullWidth
                                  displayLabel="languageName"
                                  onChange={(val) =>
                                    duplicateCheck(val, index, values)
                                  }
                                  disableClearable
                                />
                                {errors?.label?.[index]?.local &&
                                  // touched?.label?.[index]?.local &&
                                  showError(errors.label[index].local)}
                                {_find(serviceIndex, (e) => e === index) >= 0 &&
                                  errorText && (
                                    <Alert sx={{ mt: 1 }} severity="error">
                                      {errorText}
                                    </Alert>
                                  )}
                                <Field name={`label.${index}.name`}>
                                  {({ field }) => (
                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Name"
                                      InputProps={{
                                          rows: 3
                                      }}
                                      placeholder="Name"
                                      style={{ width: "100%", marginTop: 20 }}
                                      onChange={field.onChange(field.name)}
                                      value={field.value}
                                    />
                                  )}
                                </Field>
                                {errors?.label?.[index]?.name &&
                                  // touched?.label?.[index]?.name &&
                                  showError(errors.label[index].name)}
                                
                            </MDBox>
                            {/* <Divider sx={{ mt: -1 }} /> */}
                          </>
                        ))}
                        </Stack>
                        <MDBox
                          pt={3}
                          display="flex"
                          justifyContent={
                             values.label.length < 2
                              ? "flex-end"
                              : "flex-end"
                          }
                        >
                          
                          <MDBox>
                            {values.label.length !== 0 && (
                              <MDButton
                                variant="outlined"
                                color="success"
                                type="submit"
                                disabled={errorText}
                                sx={{ marginRight: "15px" }}
                              >
                                Save
                              </MDButton>
                            )}
                            <MDButton
                              variant="outlined"
                              color="error"
                              onClick={handleClose}
                            >
                              Cancel
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openConfirmation} onClose={handleConfirmationClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign="center" sx={{ m: 0, px: 2, pt: 4 }}>
          {"Are you sure you want to delete placeSubcategory  Tag?"}
        </DialogTitle>
        <DialogActions textAlign="center" display="flex" sx={{ justifyContent:"center", pb:4 }}>
          <MDButton variant="outlined" color="success" onClick={deleteHoodTag} sx={{mr:1, minWidth:"125px"}}>
            Agree
          </MDButton>
          <MDButton
            variant="outlined"
            color="error"
            onClick={handleConfirmationClose}
            sx={{minWidth:"125px"}}
          >
            Disagree
          </MDButton>
          
        </DialogActions>
      </Dialog>
    </MDBox>
  );
};

export default Places;
