// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

import MDButton from "../../../components/MDButton";
import { Link, useNavigate } from "react-router-dom";

// Images
import bgImage from "../../../assets/images/bg-image.jpg";
import BasicLayout from "../components/BasicLayout";
import { Formik, Form } from "formik";
import { Field } from "formik";
import { renderTextField } from "../../../inputFields/formFields";
import { Card } from "@mui/material";
import { useDispatch } from "react-redux";
import { forgotPasswordAdmin } from "../../../actions/adminUser";
function ForgotPassword() {
    const initialValues = {
        email: "",
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const forgotPassword = async (value) => {
        dispatch(forgotPasswordAdmin(value, navigate))
    };
    return (
        <BasicLayout image={bgImage}>
            <Card sx={{ boxShadow: "none !important" }}>
                <MDTypography variant="h4" fontWeight="semibold" color="black" textAlign="center" p={3} >
                    Forgot Password
                </MDTypography>
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values) => forgotPassword(values)}
                >
                    {({ errors, handleSubmit, isSubmitting, touched }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <MDBox px={3} pb={3}>
                                <Field
                                    name="email"
                                    component={renderTextField}
                                    fullWidth
                                    autoFocus
                                    label="Email"
                                />

                                <MDBox mt={4}>
                                    <MDButton
                                        variant="contained"
                                        color="btnTheme"
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        sx={{ fontSize: "16px", letterSpacing: "0.3px", fontWeight: "600" }}
                                    >
                                        Reset
                                    </MDButton>
                                </MDBox>
                                <MDBox mt={1} textAlign="center">
                                    <MDTypography variant="button" color="text">
                                        <MDTypography
                                            component={Link}
                                            to="/admin/signin"
                                            variant="button"
                                            color="btnTheme"
                                            fontWeight="medium"
                                            textGradient

                                        >
                                            Have a account ? Sign in
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Form>
                    )}
                </Formik>
            </Card>
        </BasicLayout>
    )

}
export default ForgotPassword;