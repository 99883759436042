import { GET_POSTS_LISTS,DELETE_POSTS_COMMENT_BY_ID, DELETE_POSTS_BY_ID,SAVE_POSTS_LISTS,GET_POSTS_BY_ID, GET_TAG_CATEGORY,SAVE_POSTS_BY_ID,UPDATE_POSTS_DETAILS, CLEAR_POSTS_DETAILS, SAVE_TAG_CATEGORY } from '../types/action-type';

export const getPostsList = (pageIndex, pageSize, searchKey) => ({
   
    type: GET_POSTS_LISTS,
    pageIndex,
    pageSize,
    searchKey,
});
export const savePostsList = (data) => ({
    type: SAVE_POSTS_LISTS,
    data,
});
export const getPostsById = (data, callback) => ({
    type: GET_POSTS_BY_ID,
    data, callback
});
export const savePostsById = (data) => ({
    type:SAVE_POSTS_BY_ID,
    data,
});
export const updatePosts=(updateData,navigate)=>({
        type:UPDATE_POSTS_DETAILS,
        updateData,navigate
})
export const clearPostsDataSuccess = () => ({
    type: CLEAR_POSTS_DETAILS,
  });

export const getTagCategoryList=()=>({
    type:GET_TAG_CATEGORY
})
export const saveTagCategoryList=(data)=>({
    type:SAVE_TAG_CATEGORY,
    data
})

export const deletePostsById = (id) => ({
  type: DELETE_POSTS_BY_ID,
  id,
});
export const deletePostsCommentById = (id,postId) => ({
    type: DELETE_POSTS_COMMENT_BY_ID,
    id,
    postId
});