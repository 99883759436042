import { call, put, takeLatest } from "redux-saga/effects";
import mias from "../api";
import { get as _get } from "lodash";
import {
  CLEAR_COUNTRY_REQUEST,
  CLEAR_COUNTRY_SUCCESS,
  GET_COUNTRY_BY_ID,
  GET_COUNTRY_FORM_DATA,
  GET_COUNTRY_LIST,
  SAVE_COUNTRY_BY_ID,
  SAVE_COUNTRY_FORM_DATA,
  SAVE_COUNTRY_LIST,
} from "../types/action-type";
import {
  clearCountrySuccess,
  saveCountryById,
  saveCountryFormData,
  saveCountryList,
} from "../actions/country";
import toast from "react-hot-toast";

function* getCountryList({pageIndex, pageSize, searchKey}) {
  try {
    let response = yield call(mias.apis.country.getCountryList, pageIndex, pageSize, searchKey);
    response.data.data.pageIndex = pageIndex;
    response.data.data.pageSize = pageSize;
    yield put(saveCountryList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get country list saga error ::", err);
  }
}

function* getCountryForm(data) {
  try {
    yield call(mias.apis.country.saveCountry, data.data);
    yield put(saveCountryFormData());
    data.navigate("/country");
    toast.success("Country Added!");
  } catch (error) {
    console.log("save country data error ::", error);
    toast.error(_get(error, "response.data.message", ""));
  }
}

function* getCountryById({ id }) {
  try {
    const response = yield call(mias.apis.country.getCountryById, id);
    yield put(saveCountryById(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get country saga error ::", err);
  }
}

function* clearCountry() {
  try {
    yield put(clearCountrySuccess());
  } catch (error) {
    console.log("clear Country error :", error);
  }
}

export default function* country() {
  yield takeLatest(GET_COUNTRY_LIST, getCountryList);
  yield takeLatest(SAVE_COUNTRY_LIST, saveCountryList);
  yield takeLatest(GET_COUNTRY_FORM_DATA, getCountryForm);
  yield takeLatest(SAVE_COUNTRY_FORM_DATA, saveCountryFormData);
  yield takeLatest(GET_COUNTRY_BY_ID, getCountryById);
  yield takeLatest(SAVE_COUNTRY_BY_ID, saveCountryById);
  yield takeLatest(CLEAR_COUNTRY_REQUEST, clearCountry);
  yield takeLatest(CLEAR_COUNTRY_SUCCESS, clearCountrySuccess);
}
