import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { get as _get } from "lodash";
import Icon from "@mui/material/Icon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import pxToRem from "../../../../src/assets/theme/functions/pxToRem";
import { Tooltip } from "@mui/material";

export default function Data() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storeProps = useSelector((state) => {
    return {
      data: _get(state, "auth.list.records", []),
      totalRecords: _get(state, "auth.list.totalRecord", 0),
      pageIndex: _get(state, "auth.list.pageIndex", 0),
      pageSize: _get(state, "auth.list.pageSize", 10),
    };
  });

  const AdminDetails = ({ firstName, lastName }) => {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox lineHeight={1}>
          <MDTypography
            display="block"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color="text"
          >
            {firstName + " " + lastName}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };

  return {
    columns: [
      {
        Header: "name",
        accessor: (row) =>
          _get(row, "firstName") +
          _get(row, "lastName"),
        Cell: (row) => (
          <AdminDetails
            firstName={_get(row, "row.original.firstName")}
            lastName={_get(row, "row.original.lastName")}

          />
        ),
        align: "left",
      },
      {
        Header: "email",
        accessor: "email",
        Cell: (row) => (
          <MDTypography variant="caption" color="text" fontWeight="regular"  fontSize="14px">
            {_get(row, "row.original.email")}
          </MDTypography>
        ),
        align: "left"
      },
     
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <MDBox width="100px">
        <Tooltip title="Edit" placement="bottom" arrow={true}>
            <IconButton sx={{ 
              color: "#54AF3A", 
              backgroundColor: "#E8FFE2", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              '&:hover' :{
                backgroundColor: "#e6f3e2"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() =>
                  navigate(`/admin/${_get(row, "row.original._id")}/edit`)
                }
                sx={{ cursor: "pointer" }}
              >
                edit_icon
              </Icon>
            </IconButton>
            </Tooltip>
            <Tooltip title="view" placement="bottom" arrow={true}>
            <IconButton sx={{ 
              color: "#F05A22", 
              backgroundColor: "#fde4db", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              cursor: "pointer", ml: 2,
              '&:hover' :{
                backgroundColor: "#fbcfbf"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() =>
                  navigate(`/admin/${_get(row, "row.original._id")}/view`)
                }
              >
                remove_red_eye_icon
              </Icon>
            </IconButton>
            </Tooltip>
          </MDBox>
        ),
        align: "center",
        width: "10%",
        
        
      },
    ],
    rows: _get(storeProps, "data", []),
    totalRecords: _get(storeProps, "totalRecords", 0),
    pageIndex: _get(storeProps, "pageIndex", 0),
    pageSize: _get(storeProps, "pageSize", 30),
  };
}
