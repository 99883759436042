
// Material Dashboard 2 React base styles
import colors from "../base/colors";
import borders from "../base/borders";
import boxShadows from "../base/boxShadows";

// Material Dashboard 2 React helper functions
import pxToRem from "../functions/pxToRem";
import boxShadow from "../functions/boxShadow";

const { grey, white, black, primary } = colors;
const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = boxShadows;

const slider = {
  styleOverrides: {
    root: {
      width: "100%",

      "& .MuiSlider-active, & .Mui-focusVisible": {
        boxShadow: "none !important",
      },

      "& .MuiSlider-valueLabel": {
        color: black.main,
        background: white.main,
        border: `${borderWidth[1]} solid ${grey[400]}`,

        "&:before":{
          border: `${borderWidth[1]} solid ${grey[400]}`,
          borderWidth: "0 1px 1px 0",
        },
      },
    },

    rail: {
      height: pxToRem(7),
      background: grey[300],
      borderRadius: borderRadius.lg,
      opacity: 1,
    },

    track: {
    //  background: white.main,
      height: pxToRem(7),
      position: "relative",
      border: "none",
      borderRadius: borderRadius.lg,
      zIndex: 1,
    },

    thumb: {
      width: pxToRem(26),
      height: pxToRem(16),
      backgroundColor: white.main,
      zIndex: 10,
      boxShadow: sliderBoxShadow.thumb,
      border: `${borderWidth[1]} solid ${primary.main}`,
      transition: "all 200ms linear",
      borderRadius: "66px",
      "&:hover": {
        boxShadow: "none",
      },

      "&:active": {
        transform: "translate(-50%, -50%) scale(1.4)",
      },

      "&.Mui-active": { boxShadow: boxShadow([0, 0], [0, 14], primary.main, 0.16) },
    },
  },
};

export default slider;
