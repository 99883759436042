import { SAVE_CMS, SAVE_CMS_LIST, CLEAR_CMS_SUCCESS } from "../types/action-type";
const initialState = {
  list: [],
  loading: false,
  error: null,
  cmsData: {},
};
export default function cmsListReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CMS_LIST:
      return {
        ...state,
        loading: true,
        list: action.data,
      };
    case SAVE_CMS:
      return {
        ...state,
        loading: true,
        cmsData: action.data,
        label: action.data.label,
      };
    case CLEAR_CMS_SUCCESS:
      return {
        state: initialState,
      };

    default:
      return state;
  }
}
