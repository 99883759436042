// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { Box } from "@mui/system";
import { Field, FieldArray, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCms, getCms, saveCmsForm } from "../../actions/cmsList";
import { get as _get } from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MDButton from "../../components/MDButton";
import { renderTextField } from "../../inputFields/formFields";

const CmsEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    dispatch(getCms(params.id));
  }, [dispatch, params.id]);

  const storeProps = useSelector((state) => {
    return {
      label: _get(state, "cms.label", []),
      language: _get(state, "cms.cmsData.code", ""),
      status: _get(state, "cms.cmsData.status", ""),
    };
  });

  useEffect(() => {
    return () => {
      dispatch(clearCms());
    };
  }, [dispatch]);

  const saveCms = (value) => {
    const cmsData = {
      label: value.label,
      code: storeProps.language,
      status: storeProps.status,
      id: params.id,
    };
    dispatch(saveCmsForm(cmsData, navigate));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                variant="contained"
                bgColor='rgba(0, 0, 0, 0.0)'
                borderRadius="lg"
                // coloredShadow="info"
              >
                <MDTypography variant="h5" color="black">
                  Edit CMS
                </MDTypography>
              </MDBox>
              {storeProps.label.length > 0 ? (
                <Formik
                  initialValues={storeProps}
                  // validationSchema={}
                  // onSubmit={}
                  enableReinitialize
                  onSubmit={async (values) => saveCms(values)}
                  render={({
                    values,
                    errors,
                    touched,
                    handleReset,
                    handleSubmit,
                  }) => {
                    return (
                      <Form noValidate onSubmit={handleSubmit}>
                        <FieldArray
                          name="label"
                          render={({ insert, remove, push }) =>
                            values.label.map((label, index) => (
                              <Grid container spacing={3} p={2} key={index}>
                                <Grid item xs={12} md={6}>
                                  <Field
                                    name={`label.${index}.local`}
                                    component={renderTextField}
                                    label="Language"
                                    fullWidth
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Field
                                    name={`label.${index}.name`}
                                    component={renderTextField}
                                    label="Name"
                                    fullWidth
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <Field name={`label.${index}.description`}>
                                    {({ field }) => (
                                      <ReactQuill
                                        value={field.value}
                                        onChange={field.onChange(field.name)}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                            ))
                          }
                        />
                        <Box p={2} textAlign="right">
                          <MDButton
                            variant="outlined"
                            color="success"
                            type="submit"
                            sx={{ marginRight: "15px" }}
                          >
                            Save
                          </MDButton>
                          <MDButton
                            variant="outlined"
                            color="error"
                            onClick={() => navigate("/cms")}
                          >
                            Cancel
                          </MDButton>
                        </Box>
                      </Form>
                    );
                  }}
                />
              ) : (
                <MDBox textAlign={"center"} p={10}>
                  Loading...
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default CmsEdit;
