import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get as _get,
  map as _map,
  omit as _omit,
  isEmpty as _isEmpty,
} from "lodash";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import DataTable from "../../../examples/Tables/DataTable/serverTable";
import IconButton from "@mui/material/IconButton";
import {
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Icon,
  Typography,
  Box,
  Button,
  DialogContentText,
  Divider,
} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import { Form, Formik } from "formik";
import MDButton from "../../../components/MDButton";
import * as Yup from "yup";
import {
  deletLanguage,
  getLangaugeStatusUpdateRequest,
  getLanguageList,
  saveLanguage,
  updateLanguage,
} from "../../../actions/language";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";

const AddLanguage = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [newLabel, setNewLabel] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [initialValues, setInitialValues] = useState({
    label: "",
    code: "",
    status: true,
  });

  // Fetch language list on component mount
  useEffect(() => {
    dispatch(getLanguageList());
  }, [dispatch]);

  // Getting the necessary state from Redux
  const storeProps = useSelector((state) => ({
    data: _get(state, "language.list", []),
  }));

  // Validation schema using Yup
  const schema = Yup.object().shape({
    label: Yup.string().required("Language name is required"),
    code: Yup.string().required("Code is required"),
  });

  // Open dialog to add/edit language
  const handleClickOpen = (key = "") => {
    const languageToEdit = storeProps.data.find((item) => item.label === key);
    if (languageToEdit) {
      setInitialValues(languageToEdit);
    }
    setOpen(true);
    setIsNew(!key);
    setKey(key);
  };
  const handleClose = () => {
    setOpen(false);
    setIsNew(false);
    setNewLabel([]);
  };

  const handleSubmit = (values) => {
    if (isNew) {
      dispatch(saveLanguage(values));
    } else {
      const updatedLanguage = { ...values };
      dispatch(updateLanguage(updatedLanguage));
    }
    setOpen(false); // Dispatch the action with form values
    setIsNew(false);
    setInitialValues({
      label: "",
      code: "",
      status: true,
    });
  };
  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setDeleteId("");
  };
  const handleDeleteLanguage = () => {
    dispatch(deletLanguage({ _id: deleteId }));
    handleConfirmationClose();
  };
  const handleChangeStatus = (value, key) => {
    const statusData = { _id: key, status: value };
    dispatch(getLangaugeStatusUpdateRequest(statusData));
  };
  // Table data for displaying languages
  const tableData = {
    columns: [
      {
        Header: "Title",
        accessor: "label",
        Cell: (row) => (
          <Tooltip title={_get(row, "row.original.label")}>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="regular"
              fontSize="14px"
            >
              {_get(row, "row.original.label")}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: (row) => (
          <Tooltip title={_get(row, "row.original.code")}>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="regular"
              fontSize="14px"
            >
              {_get(row, "row.original.code")}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: (row) => (
          <Switch
            checked={_get(row, "row.original.status")}
            onChange={(e, value) => {
              handleChangeStatus(value, _get(row, "row.original._id"));
            }}
          />
        ),
        align: "center",
        width: "5%",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (row) => (
          <MDBox width="100px" margin="auto">
            <IconButton
              sx={{
                color: "#54AF3A",
                backgroundColor: "#E8FFE2",
                borderRadius: "6px",
                width: pxToRem(35),
                height: pxToRem(35),
                "&:hover": {
                  backgroundColor: "#e6f3e2",
                },
              }}
              onClick={() => handleClickOpen(_get(row, "row.original.label"))}
            >
              <Icon fontSize="small" sx={{ cursor: "pointer" }}>
                edit_icon
              </Icon>
            </IconButton>
            <IconButton
              sx={{
                color: "#F05A22",
                backgroundColor: "#fde4db",
                borderRadius: "6px",
                width: pxToRem(35),
                height: pxToRem(35),
                ml: 2,
                "&:hover": {
                  backgroundColor: "#fbcfbf",
                },
              }}
              onClick={() => {
                setOpenConfirmation(true);
                setDeleteId(_get(row, "row.original._id"));
              }}
            >
              <Icon fontSize="small" sx={{ cursor: "pointer" }}>
                delete
              </Icon>
            </IconButton>
          </MDBox>
        ),
        align: "center",
        width: "10%",
      },
    ],
    rows: Array.isArray(storeProps.data) ? storeProps.data : [],
  };

  const { columns, rows } = tableData;

  return (
    <MDBox p={2}>
      <Card>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <MDTypography variant="h5" fontWeight="medium" color="black">
            Languages
          </MDTypography>
          <MDButton
            variant="outlined"
            color="orange"
            onClick={() => handleClickOpen()}
          >
            <Icon fontSize="small">add_icon</Icon> &nbsp; Add Language
          </MDButton>
        </MDBox>

        <MDBox pt={2} px={2}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            showTotalEntries={false}
            noEndBorder
            tableFor="languageList"
          />
        </MDBox>

        {/* Add/Edit Dialog */}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ m: 0, pr: 8 }}>
            {isNew ? "Add Language" : "Edit Language"}
            {handleClose ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[700],
                  backgroundColor: (theme) => theme.palette.grey[200],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, errors }) => {
                return (
                  <Form>
                    <TextField
                      label="Title"
                      name="label"
                      fullWidth
                      value={values.label}
                      onChange={handleChange}
                      margin="normal"
                    />
                    <TextField
                      label="Code"
                      name="code"
                      fullWidth
                      value={values.code}
                      onChange={handleChange}
                      margin="normal"
                    />

                    <MDBox mt={2}>
                      <MDButton
                        variant="outlined"
                        color="success"
                        type="submit"
                        sx={{ marginRight: "15px" }}
                      >
                        Save
                      </MDButton>
                      <MDButton
                        variant="outlined"
                        color="error"
                        onClick={handleClose}
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation */}
        <Dialog
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Delete Language</DialogTitle>
          <Divider sx={{ marginTop: 0, marginBottom: 0 }} />
          <DialogContent>
            <DialogContentText sx={{ maxHeight: "400px" }}>
              Are you sure you want to delete this language?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleConfirmationClose}
              variant="outlined"
              color="error"
            >
              Cancel
            </MDButton>
            <MDButton
              onClick={handleDeleteLanguage}
              variant="outlined"
              color="error"
            >
              Delete
            </MDButton>
          </DialogActions>
        </Dialog>
        {/* <Dialog
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
        >
          <DialogTitle>
            Are you sure you want to delete this language?
          </DialogTitle>
          <DialogActions>
            <MDButton color="error" onClick={handleDeleteLanguage}>
              Delete
            </MDButton>
            <MDButton onClick={() => setOpenConfirmation(false)}>
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog> */}
      </Card>
    </MDBox>
    // ) : (
    //   <MDBox
    //     p={4}
    //     display="flex"
    //     justifyContent="center"
    //     alignItems="center"
    //     minHeight="200px"
    //     // sx={{ width: "90rem" }}
    //   >
    //     {/* <Card sx={{ p: 4, textAlign: "center" }}> */}
    //     {/* <MDTypography variant="h6" color="textSecondary"> */}
    //     No records found
    //     {/* </MDTypography> */}
    //     {/* </Card> */}
    //   </MDBox>
  );
};

export default AddLanguage;
