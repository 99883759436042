import {
  DELETE_LANGUAGE,
  DELETE_LANGUAGE_SUCCESS,
  GET_LANGUAGE_LIST,
  GET_LANGUAGE_STATUS_UPDATE_REQUEST,
  SAVE_LANGUAGE,
  SAVE_LANGUAGE_LIST,
  SAVE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_STATUS_SUCCESS,
  UPDATE_LANGUAGE_SUCCESS,
} from "../types/action-type";

export const getLanguageList = () => ({
  type: GET_LANGUAGE_LIST,
});

export const saveLanguageList = (data) => ({
  type: SAVE_LANGUAGE_LIST,
  data,
});
export const saveLanguageSuccess = () => ({
  type: SAVE_LANGUAGE_SUCCESS,
});
export const saveLanguage = (data) => ({
  type: SAVE_LANGUAGE,
  data,
});
export const updateLanguage = (data) => ({
  type: UPDATE_LANGUAGE,
  data,
});
export const updateLanguageSuccess = () => ({
  type: UPDATE_LANGUAGE_SUCCESS,
});
export const updateLanguageStatusSuccess = () => ({
  type: UPDATE_LANGUAGE_STATUS_SUCCESS,
});
export const deleteLanguageSuccess = () => ({
  type: DELETE_LANGUAGE_SUCCESS,
});
export const deletLanguage = (id) => ({
  type: DELETE_LANGUAGE,
  id,
});
export const getLangaugeStatusUpdateRequest = (data) => ({
  type: GET_LANGUAGE_STATUS_UPDATE_REQUEST,
  data,
});
